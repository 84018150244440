import { TokenSymbol } from '../config/contracts/Token/TokenSymbol'
import { AirdropCampaign, AirdropType } from '../interfaces/Airdrop'
import { MAINNET_AIRDROP_CAMPAIGNS } from './airdrops/official/mainnet'
import { METIS_MAINNET_AIRDROPS } from './airdrops/thirdParty/metis_mainnet'

// duration in second for a new block. may need to depend on the network.
export const BLOCK_TIME = 5000
export const MIN_WITHDRAWABLE_PERCENTAGE = '0.001'
export const MAX_LPTOKEN_SUPPLY_DIFFERENCE = '1'

// DS Maths
export const WAD_DECIMALS = 18

// Official airdrops
export const OFFICIAL_AIRDROP_PER_PAGE_SIZE = 4

export const OFFICIAL_AIRDROP_CAMPAIGNS = MAINNET_AIRDROP_CAMPAIGNS

export const THIRD_PARTY_AIRDROP_CAMPAIGNS = [...METIS_MAINNET_AIRDROPS]

export const ALL_AIRDROP_CAMPAIGNS = [
  // ...FUJI_AIRDROP_CAMPAIGNS,
  ...MAINNET_AIRDROP_CAMPAIGNS,
  // ...METIS_TESTNET_AIRDROPS,
  ...METIS_MAINNET_AIRDROPS,
]

export const AIRDROP_CAMPAIGNS: {
  [id in AirdropType]: AirdropCampaign[]
} = {
  [AirdropType.OFFICIAL]: OFFICIAL_AIRDROP_CAMPAIGNS,
  [AirdropType.THIRD_PARTY]: THIRD_PARTY_AIRDROP_CAMPAIGNS,
}
// USP compensation
export const PRE_ATTACK_BLOCK = 26343613
export const PRE_ATTACK_DATE = new Date('2023-02-16T19:16:53.000Z')
export const POST_ATTACK_BLOCK = 26344650
export const POST_ATTACK_DATE = new Date('2023-02-16T19:51:08.000Z')
export const RECOVERED_FUNDS_BREAKDOWN: Record<
  number,
  { groupLabel: string; data: { token: string; amount: string }[] }[]
> = {
  // the key is the index of the compensation
  0: [
    {
      groupLabel: "Funds recovered from the exploiter's contract",
      data: [
        {
          token: TokenSymbol.USDC,
          amount: '2403762.189097',
        },
      ],
    },
    {
      groupLabel: 'Funds remaining in our main pool at pool paused',
      data: [
        { token: TokenSymbol.BUSD, amount: '242614.027040581299409592' },
        { token: TokenSymbol.USDT, amount: '935672.114143' },
        { token: TokenSymbol.USDC, amount: '1331412.930662' },
        { token: TokenSymbol.DAIe, amount: '260538.525310209771534267' },
        { token: TokenSymbol.USDCe, amount: '1050684.728748' },
        { token: TokenSymbol.USDTe, amount: '787569.012152' },
      ],
    },
  ],
  1: [
    {
      groupLabel: 'Funds collecting from fee sharing',
      data: [
        { token: TokenSymbol.USDTe, amount: '151194.03' },
        { token: TokenSymbol.DAIe, amount: '537744.27' },
        { token: TokenSymbol.USDT, amount: '108449.15' },
        { token: TokenSymbol.BUSD, amount: '134047.57' },
        { token: TokenSymbol.USDC, amount: '183039.58' },
        { token: TokenSymbol.BTCb, amount: '2.86566937' },
        { token: TokenSymbol.sAVAX, amount: '12481.81' },
        { token: TokenSymbol.yyAVAX, amount: '267.3422756' },
      ],
    },
  ],
  2: [
    {
      groupLabel: 'Funds recovered from AAVE',
      data: [
        { token: TokenSymbol.USDTe, amount: '79815.266923' },
        { token: TokenSymbol.USDCe, amount: '69756.91961' },
        { token: TokenSymbol.DAIe, amount: '26540.197760' },
        { token: TokenSymbol.USDC, amount: '83175.344156' },
        { token: TokenSymbol.USDT, amount: '96810.192138' },
        { token: TokenSymbol.BUSD, amount: '24496.779552' },
      ],
    },
  ],
}
export const MERKLE_INFO_DB_VERSION = '1.34'
export const MERKLE_INFO_URI = 'https://api.platypus.finance/merkle_info'
