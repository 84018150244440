import { Box, Theme, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

interface ContainerProps {
  theme: Theme
  $hasAccount: boolean
}
export const Container = styled(Box)<ContainerProps>`
  ${({ $hasAccount, theme }: ContainerProps) => css`
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 999px;
    user-select: none;
    border: ${$hasAccount
      ? 'unset'
      : `1px solid ${theme.palette.primary[500]}`};
    &:hover {
      opacity: 0.8;
    }
  `}
`

export const NetworkName = styled(Typography)`
  margin-right: 12px;
`
