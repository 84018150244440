export enum ActionAfterAttack {
  LP_TRANSFER = 'LP_TRANSFER',
  USP_TOKEN_TRANSFER = 'USP_TOKEN_TRANSFER',
  POOL_WITHDRAW = 'POOL_WITHDRAW',
  USP_BORROW = 'USP_BORROW',
  USP_REPAY = 'USP_REPAY',
  POOL_EMERGENCY_WITHDRAW = 'POOL_EMERGENCY_WITHDRAW',
  POOL_SWAP = 'POOL_SWAP',
}
export type CompensationType = {
  total: number
  breakdown: { [key: string]: number }
}

type GenericTransactionAction = {
  action: ActionAfterAttack
  tx_id: string
  block_number: number
  adjustment: number
  log_index: number
}

interface LpTransfer extends GenericTransactionAction {
  action: ActionAfterAttack.LP_TRANSFER
  from: string
  to: string
  token: string
  amount: number
}
export interface UspTokenTransfer extends GenericTransactionAction {
  action: ActionAfterAttack.USP_TOKEN_TRANSFER
  from: string
  to: string
  amount: number
}

interface PoolWithdraw extends GenericTransactionAction {
  action: ActionAfterAttack.POOL_WITHDRAW
  sender: string
  token: string
  liquidity: number
  amount: number
}
interface UspBorrowAndRepay extends GenericTransactionAction {
  action: ActionAfterAttack.USP_BORROW | ActionAfterAttack.USP_REPAY
  sender: string
  collateral: string
  amount: number
}
interface PoolEmergencyWithdraw extends GenericTransactionAction {
  action: ActionAfterAttack.POOL_EMERGENCY_WITHDRAW
  sender: string
  amount: number
  token: string
}
interface PoolSwap extends GenericTransactionAction {
  action: ActionAfterAttack.POOL_SWAP
  sender: string
  from_token: string
  to_token: string
  from_amount: number
  to_amount: number
}

type TransactionAfterExploit =
  | LpTransfer
  | UspTokenTransfer
  | PoolWithdraw
  | UspBorrowAndRepay
  | PoolEmergencyWithdraw
  | PoolSwap

export type UserData = {
  market: { recoveredFund: number[]; totalLiability: number }
  adjustedBalance?: number
  share?: number
  preAttack?: {
    totalInUsd: number
    totalLpAmount: { [key: string]: number }
    uspBalance?: number
    uspDebt?: number
  }
  afterAttack?: {
    totalAdjustedAmount: number
    /** @todo should be TransactionAfterExploit[] not TransactionAfterExploit[][]. Need to adjust the lambda reposnse later */
    transactionAfterExploit: TransactionAfterExploit[][]
  }
  compensation?: CompensationType[]
}
