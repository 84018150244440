import { Box } from '@mui/material'
import React, { ReactElement } from 'react'
import {
  InputChildrenWrapper,
  InputContainer,
  InputField,
  InputLabelContainer,
  InputUnitLabel,
  Label,
} from './Input.elements'
export interface InputProps extends LabelProps {
  children?: React.ReactNode
  placeholder: string
  onChange?: (value: string) => void
  value: string
  flexDirection?: 'column' | 'row' | 'row-reverse'
  textalign?: string
  bgColor?: string
  disabled?: boolean
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined
  enableLabelTopPosition?: boolean
  inputUnitLabel?: string
  readOnly?: 'readOnly'
  style?: React.CSSProperties | undefined
}

function Input({
  className,
  children,
  placeholder,
  onChange,
  value,
  flexDirection = 'row',
  textalign = 'left',
  bgColor = 'primary.400',
  leadingLabel,
  trailingLabel,
  disabled,
  inputMode,
  inputUnitLabel,
  readOnly,
  style,
}: InputProps): ReactElement {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value)
  }
  return (
    <InputContainer
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="center"
      bgcolor={bgColor}
      className={className}
      style={style}
    >
      <Box
        display="flex"
        flexDirection={flexDirection}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems={inputUnitLabel && 'flex-end'}
          flex={1}
        >
          <InputField
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            textalign={textalign}
            disableUnderline
            disabled={disabled}
            inputProps={{
              inputMode: inputMode,
              readOnly: readOnly,
            }}
            inputunitlabel={inputUnitLabel}
          />
          {inputUnitLabel && (
            <InputUnitLabel variant="caption">{inputUnitLabel}</InputUnitLabel>
          )}
        </Box>
        {children && (
          <InputChildrenWrapper className="input__children-wrapper">
            {children}
          </InputChildrenWrapper>
        )}
      </Box>
      {(leadingLabel || trailingLabel) && (
        <InputLabel
          className="input__label"
          leadingLabel={leadingLabel}
          trailingLabel={trailingLabel}
        />
      )}
    </InputContainer>
  )
}

export default Input

export interface LabelProps {
  leadingLabel?: string | React.ReactElement
  trailingLabel?: string | React.ReactElement
  className?: string
}
export function InputLabel({
  className,
  leadingLabel,
  trailingLabel,
}: LabelProps): ReactElement {
  return (
    <InputLabelContainer
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={className}
    >
      {leadingLabel && <Label variant="caption">{leadingLabel}</Label>}
      {trailingLabel && (
        <Label variant="caption" className="input-label__trailing">
          {trailingLabel}
        </Label>
      )}
    </InputLabelContainer>
  )
}
