import { ConnectArgs, FetchSignerResult, Signer } from '@wagmi/core'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  Chain,
  Connector,
  useAccount,
  useConnect,
  useNetwork,
  useSigner,
  useSwitchNetwork,
} from 'wagmi'
import {
  ChainId,
  ExtendedChain,
  NETWORKS,
  SUPPORTED_CHAINS,
} from '../config/networks'
import { BUILD_TYPE, HexString } from '../interfaces/common'
import { useSnackbar } from './SnackbarContext'

type ContextType = {
  network: ExtendedChain | null
  isSupported: boolean
  connect: (args?: Partial<ConnectArgs> | undefined) => void
  account: null | HexString
  setMockAccount: React.Dispatch<React.SetStateAction<HexString | null>>
  switchNetwork: (chainId: ChainId) => Promise<Chain | undefined>
  signer: FetchSignerResult<Signer> | undefined
  connector: Connector | undefined
}
const Web3Context = createContext<ContextType>({} as unknown as ContextType)
Web3Context.displayName = 'Web3Context'

interface Props {
  children: React.ReactNode
}

export const useWeb3 = () => {
  return useContext(Web3Context)
}

function Web3Provider({ children }: Props): ReactElement {
  // view as another account for development purpose
  const [mockAccount, setMockAccount] = useState<HexString | null>(null)
  const { address: account, connector } = useAccount()
  const { chain } = useNetwork()
  const { showMessage } = useSnackbar()
  const { switchNetworkAsync } = useSwitchNetwork()
  const { connect } = useConnect({
    onError: (error) => showMessage(error.message, 'warning'),
    onSuccess: (reuslt) => {
      showMessage(`Successfully connected to ${reuslt.connector?.name}`)
    },
  })
  const { data: signer } = useSigner()
  const switchNetwork = useCallback(
    async (chainId: ChainId) => {
      const result = await (switchNetworkAsync &&
        switchNetworkAsync(NETWORKS[chainId].id))
      return result
    },
    [switchNetworkAsync],
  )

  const { network, isSupported } = useMemo(() => {
    const network = chain
      ? Object.values(NETWORKS).filter((network) => network.id === chain?.id)[0]
      : null
    return {
      network: network,
      isSupported: network
        ? SUPPORTED_CHAINS.includes(network.id as ChainId)
        : false,
    }
  }, [chain])
  const isNotProd = process.env.REACT_APP_ENVIRONMENT !== BUILD_TYPE.PROD
  return (
    <Web3Context.Provider
      value={{
        network,
        isSupported,
        connect,
        account: (isNotProd && mockAccount) || account || null,
        setMockAccount,
        switchNetwork,
        signer,
        connector,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider
