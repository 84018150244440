import { Box } from '@mui/material'
import styled from 'styled-components'

export const Instruction = styled(Box)`
  margin-bottom: 30px;
  span {
    line-height: 0.8;
    font-weight: 300;
  }
`
