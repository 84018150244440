import { TypographyProps } from '@mui/material'
import TabBar from '../../components/TabBar/TabBar'
import { AirdropType } from '../../interfaces/Airdrop'

interface CalculatorTabBarProps {
  currentTabId: AirdropType
  onTabChange: (tabId: AirdropType) => void
}

export function AirdropTabBar({
  currentTabId,
  onTabChange,
}: CalculatorTabBarProps) {
  const labelProps: TypographyProps = { variant: 'subtitle2' }

  const handleClickTab = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    airdropType: AirdropType,
  ) => {
    e.preventDefault()
    onTabChange(airdropType)
  }

  return (
    <TabBar numOfTabs={2} width="230px" height="40px" margin="24px auto">
      <TabBar.Tab
        onClick={(e) => handleClickTab(e, AirdropType.OFFICIAL)}
        label={AirdropType.OFFICIAL}
        activeLink={currentTabId === AirdropType.OFFICIAL}
        labelProps={labelProps}
      />
      <TabBar.Tab
        onClick={(e) => handleClickTab(e, AirdropType.THIRD_PARTY)}
        label={AirdropType.THIRD_PARTY}
        activeLink={currentTabId === AirdropType.THIRD_PARTY}
        labelProps={labelProps}
      />
    </TabBar>
  )
}
