import { alpha, useTheme } from '@mui/material'
import { ReactElement } from 'react'
import ReactGA from 'react-ga'
import Modal from '../../../components/Modal/Modal'
import { WalletId, WALLETS } from '../../../config/wallets'
import { useWeb3 } from '../../../contexts/Web3Context'
import {
  WalletItemContainer,
  WalletLabel,
} from './ConnectToWalletModalContainer.elements'

interface Props {
  isOpen: boolean
}

function ConnectToWalletModalContainer({ isOpen }: Props): ReactElement {
  const { connect } = useWeb3()
  /** @todo comment out later when needed */
  // const [readTermsAndConditions, setReadTermsAndConditions] = useState(false)
  // const toggleTermsAndConditions = () => {
  //   setReadTermsAndConditions(!readTermsAndConditions)
  // }

  const handleClickWallet = async (selectedWalletId: WalletId) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: selectedWalletId,
    })
    try {
      await connect(WALLETS[selectedWalletId])
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      /** @todo allow an user to close the modal */
      onClose={() => {
        console.log(
          'not allowed to manually close the ConnectToWalletModal for now',
        )
      }}
      topBarLabel={<>Connect Wallet</>}
      width="600px"
      disableCloseBtn
    >
      {Object.entries(WALLETS).map(([walletIdStr, wallet], i) => {
        const walletId = walletIdStr as WalletId
        return (
          <WalletItem
            key={`${i}_wallet`}
            onClick={() => handleClickWallet(walletId)}
            label={walletId}
            iconPath={wallet.imgSrc}
          />
        )
      })}
    </Modal>
  )
}

export default ConnectToWalletModalContainer
interface WalletItemProps {
  iconPath?: string
  iconWidth?: number
  label: WalletId
  onClick: (wallet: WalletId) => void
  active?: boolean
}
const WalletItem = ({
  iconPath,
  iconWidth = 28,
  label,
  onClick,
}: WalletItemProps) => {
  const theme = useTheme()
  const handleClickWallet = () => {
    onClick(label)
  }

  return (
    <WalletItemContainer
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={alpha(theme.palette.primary[500], 0.2)}
      onClick={handleClickWallet}
    >
      <WalletLabel variant="body1">{label}</WalletLabel>
      {iconPath && <img src={iconPath} alt={label} width={iconWidth} />}
    </WalletItemContainer>
  )
}

/** @todo comment out later when needed */
// interface WalletTermsAndConditionsProps {
//   onClick: () => void
//   active: boolean
// }
// const WalletTermsAndConditions = ({
//   active,
//   onClick,
// }: WalletTermsAndConditionsProps) => {
//   const handleClickReadButton = () => {
//     onClick()
//   }
//   return (
//     <Box>
//       <Typography variant="subtitle2">Terms & Conditions:</Typography>
//       <Box
//         display="flex"
//         flexDirection="row"
//         alignItems="center"
//         m="10px 0 20px"
//       >
//         <ReadButton
//           bgcolor={active ? 'primary.100' : 'primary.400'}
//           onClick={handleClickReadButton}
//         />
//         <Typography variant="subtitle2">
//           I understand the terms and agree to the <b>Platypus terms</b>
//         </Typography>
//       </Box>
//     </Box>
//   )
// }
