import { MetaMaskConnector } from '@wagmi/connectors/dist/metaMask'
import { Connector } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import CoinbaseWalletLogo from '../assets/icons/coinbase-wallet-logo.png'
import CoreWalletLogo from '../assets/icons/corewallet-logo.png'
import MetaMaskLogo from '../assets/icons/metamask-fox.svg'
import TrustWalletLogo from '../assets/icons/trust-wallet-icon.svg'
import WalletConnectLogo from '../assets/icons/walletconnect-logo.svg'
import XdefiLogo from '../assets/icons/xdefi-logo.png'
import { NETWORKS } from './networks'
export enum WalletId {
  COREWALLET = 'Core',
  METAMASK = 'MetaMask',
  WALLETCONNECT = 'WalletConnect',
  WALLETLINK = 'Coinbase Wallet',
  XDEFIWALLET = 'XDEFI Wallet',
  TRUSTWALLET = 'Trust Wallet',
}

type Props = {
  connector: Connector
  imgSrc: string
  name?: string
}
class Wallet {
  readonly connector: Connector
  readonly imgSrc: string
  readonly name: string
  constructor({ connector, imgSrc, name }: Props) {
    this.connector = connector
    this.imgSrc = imgSrc
    this.name = name || connector.name
  }
}
export const WALLETS: { [id in WalletId]: Wallet } = {
  [WalletId.METAMASK]: new Wallet({
    connector: new MetaMaskConnector({
      chains: Object.values(NETWORKS),
    }),
    imgSrc: MetaMaskLogo,
  }),
  [WalletId.WALLETCONNECT]: new Wallet({
    connector: new WalletConnectConnector({
      chains: Object.values(NETWORKS),
      options: {
        qrcode: true,
      },
    }),
    imgSrc: WalletConnectLogo,
  }),
  [WalletId.COREWALLET]: new Wallet({
    connector: new InjectedConnector({
      chains: Object.values(NETWORKS),
      options: {
        name: WalletId.COREWALLET,
        getProvider: () =>
          typeof window !== 'undefined' ? window.avalanche : undefined,
      },
    }),
    imgSrc: CoreWalletLogo,
  }),
  [WalletId.WALLETLINK]: new Wallet({
    connector: new CoinbaseWalletConnector({
      chains: Object.values(NETWORKS),
      options: {
        appName: 'Platypus Airdrop',
      },
    }),
    imgSrc: CoinbaseWalletLogo,
  }),
  [WalletId.XDEFIWALLET]: new Wallet({
    connector: new InjectedConnector({
      chains: Object.values(NETWORKS),
      options: {
        name: WalletId.XDEFIWALLET,
        getProvider: () =>
          typeof window !== 'undefined' ? window.xfi?.ethereum : undefined,
      },
    }),
    imgSrc: XdefiLogo,
  }),
  [WalletId.TRUSTWALLET]: new Wallet({
    connector: new InjectedConnector({
      chains: Object.values(NETWORKS),
      options: {
        name: WalletId.TRUSTWALLET,
        getProvider: () =>
          typeof window !== 'undefined' ? window.trustwallet : undefined,
      },
    }),
    imgSrc: TrustWalletLogo,
  }),
}
