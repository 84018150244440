import { isParsableString } from '@hailstonelabs/big-number-utils'
import * as Sentry from '@sentry/react'
import React, { createContext, ReactElement, useContext, useState } from 'react'
import { SUPPORTED_TOKENS, TOKENS } from '../config/contracts/Token'
import { TokenSymbol } from '../config/contracts/Token/TokenSymbol'
import { usePoller } from '../hooks/usePoller'

type BalanceType = {
  [id in TokenSymbol]: string
}

interface IBalanceContext {
  tokenPrice: BalanceType
}

const EMPTY_AMOUNT = Object.values(TOKENS).reduce((acc, token) => {
  return { ...acc, [token.symbol]: '0.0' }
}, {}) as BalanceType

const initialBalances = {
  tokenPrice: EMPTY_AMOUNT,
}

export const BalanceContext = createContext<IBalanceContext>(initialBalances)

export const useBalance = (): IBalanceContext => {
  return useContext(BalanceContext)
}

interface Props {
  children: React.ReactNode
}

interface CoinGeckoReponse {
  [tokenSymbol: string]: {
    usd: string
  }
}

const COIN_GECKO_API = 'https://api.coingecko.com/api/v3/simple/price'

export const BalanceProvider = ({ children }: Props): ReactElement => {
  const [tokenPrice, setTokenPrice] = useState<BalanceType>(EMPTY_AMOUNT)
  usePoller(() => {
    const fetchPrice = async () => {
      const newTokenPrice = { ...EMPTY_AMOUNT }

      try {
        const tokenIds = SUPPORTED_TOKENS.map(
          (symbol) => TOKENS[symbol].geckoId,
        )

        const response = await fetch(
          `${COIN_GECKO_API}?ids=${encodeURIComponent(
            tokenIds.join(','),
          )}&vs_currencies=usd`,
        )

        const body = (await response.json()) as CoinGeckoReponse

        for (const sym of SUPPORTED_TOKENS) {
          const token = TOKENS[sym]
          const geckoId = token.geckoId

          const price = String(body[geckoId]?.usd)

          if (isParsableString(price, token.decimals, true)) {
            newTokenPrice[sym] = price
          } else {
            newTokenPrice[sym] = '0'
          }
        }

        setTokenPrice(newTokenPrice)
      } catch (err) {
        Sentry.setContext('event', {
          name: 'fetch_coingeico',
        })
        Sentry.captureException(err)
        console.error(err)
      }
    }
    void fetchPrice()
    // update every 10 seconds
  }, 10000)

  return (
    <BalanceContext.Provider
      value={{
        tokenPrice,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}
