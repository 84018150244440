import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box } from '@mui/material'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../../config/contracts/Token/TokenSymbol'
import { ChainId } from '../../../config/networks'
import { useAirdropData } from '../../../contexts/AirdropContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import { Colorized, StyledDivider } from '../../../gloabalStyles'
import useClaim from '../../../hooks/useClaim'
import { AirdropType } from '../../../interfaces/Airdrop'
import { localeDateFormat } from '../../../utils/common'
import {
  CardContainer,
  CardWrapper,
  DividerContainer,
  NotEligibleBox,
} from './AirdropThirdPartyCard.elements'

interface AirdropThirdPartyCardProps {
  index: number
  contractAddress: `0x${string}`
  startDate: Date
  snapshotDate: Date
  tokenSymbol: TokenSymbol
  balances: { [account: string]: string } | undefined
  chainId: ChainId
}

const AirdropThirdPartyCard = ({
  contractAddress,
  startDate,
  snapshotDate,
  tokenSymbol,
  chainId: airdropChainId,
}: AirdropThirdPartyCardProps) => {
  const { network, switchNetwork } = useWeb3()
  const airdropType = AirdropType.THIRD_PARTY
  const isUnlocked = new Date() > startDate
  const { newAirdropData: data } = useAirdropData()
  const {
    isClaimed,
    claimableUntil,
    isEligible,
    airdropUsdValue,
    airdropAmount: airdropAmountStr,
    isEnded,
  } = data[airdropType][contractAddress] || {
    isClaimed: false,
    claimableUntil: 0,
    isEligible: false,
    airdropUsdValue: '0.0',
    airdropAmount: '0',
    isEnded: false,
    isEligibleCampaignEndedAndNotClaimed: false,
  }
  const { handleClaimClick } = useClaim(
    tokenSymbol,
    contractAddress,
    airdropChainId,
    AirdropType.THIRD_PARTY,
  )

  const claimBtnText = isClaimed ? 'Claimed' : 'Claim'

  const handleSwitchNetwork = (chainId: ChainId) => {
    switchNetwork(chainId)
  }

  return (
    <CardWrapper>
      <StyledDivider $colored margin="12px 0">
        {isUnlocked ? (
          <AppTypography variant="caption2" transparent>
            <DividerContainer>
              <CheckCircleOutlineIcon /> Unlocked
            </DividerContainer>
          </AppTypography>
        ) : (
          <AppTypography variant="caption2">
            {localeDateFormat(startDate)}
          </AppTypography>
        )}
      </StyledDivider>
      <CardContainer>
        <AppTypography variant="caption2" transparent>
          Snapshot: {localeDateFormat(snapshotDate)}
        </AppTypography>
        <>
          {isEligible ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
              >
                <TokenIcon
                  tokenSymbol={tokenSymbol}
                  size={40}
                  margin="0 10px 0 0"
                />
                <Box display="flex" flexDirection="column">
                  <AppTypography variant="h6">
                    {getCommifiedFormat(airdropAmountStr)} {tokenSymbol}
                  </AppTypography>
                  <AppTypography variant="caption2">
                    <Colorized variant="blue">
                      (=$ {getCommifiedFormat(airdropUsdValue)})
                    </Colorized>
                  </AppTypography>
                </Box>
              </Box>

              {network?.id === airdropChainId ? (
                <AppButton
                  onClick={() => handleClaimClick()}
                  data-testid="claim-button"
                  disabled={isClaimed}
                  fullWidth
                >
                  {claimBtnText}
                </AppButton>
              ) : (
                <>
                  {isEligible && (
                    <AppButton
                      onClick={() => handleSwitchNetwork(airdropChainId)}
                      data-testid="switch-network-button"
                      fullWidth
                    >
                      Switch Network
                    </AppButton>
                  )}
                </>
              )}
              {isEnded && (
                <AppTypography variant="caption2" transparent>
                  End: {localeDateFormat(claimableUntil)}
                </AppTypography>
              )}
            </>
          ) : (
            <NotEligibleBox>
              <AppTypography variant="caption2">
                Your account is not eligible for this airdrop
              </AppTypography>
            </NotEligibleBox>
          )}
        </>
      </CardContainer>
    </CardWrapper>
  )
}

export default AirdropThirdPartyCard
