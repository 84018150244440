import { TokenSymbol } from '../config/contracts/Token/TokenSymbol'
import { ChainId } from '../config/networks'
import { ThirdPartyAirdropId } from '../config/thirdPartyAirdrops'
import { MerkleDistributorInfoClaim } from '../utils/merkleDistributor/parse-balance-map'
export interface AirdropCampaign {
  name?: string
  address: `0x${string}`
  description?: string
  balances: { [account: string]: string } | undefined
  startDate: Date
  tokenSymbol: TokenSymbol
  chainId?: ChainId
  thirdPartyAirdropId?: ThirdPartyAirdropId
  snapshotDate?: Date
  isMerkleDistributorV1?: boolean
  /** @todo refactor: combine isUspCompensation with uspCompensationId*/
  isUspCompensation?: boolean
  uspCompensationId?: number
}

export enum AirdropType {
  OFFICIAL = 'Official',
  THIRD_PARTY = 'Third Party',
}
export type IUsersMerkleInfos = {
  [userAddress in string]: IMerkleInfos
}
export type IMerkleInfos = {
  [campaignAddress in string]: MerkleDistributorInfoClaim | undefined
}
