import { Theme, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import Modal from '../../components/Modal/Modal'
export const StyledModal = styled(Modal)`
  ${({ theme }: { theme: Theme }) => css`
    .modal__body {
      border: 1px solid ${theme.palette.error.main};
    }
  `}
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
}))`
  ${({ theme }: { theme: Theme }) => css`
    text-align: center;
    color: ${theme.palette.error.main};
  `}
`

export const Message = styled(Typography).attrs(() => ({
  variant: 'body1',
}))`
  text-align: center;
  margin: 16px 0;
`
