import { PaletteOptions } from '@mui/material/styles'

export const colors = {
  primary: {
    100: '#8282a2',
    200: '#333968',
    300: '#00194c', // primary.light
    400: '#0b1334',
    500: '#0050FF', // primary.main
    600: '#003EC8',
    700: '#062D82',
    800: '#122C7B',
    900: '#0A1941', // ui-bg
    A200: '#00A7FF',
    A300: '#122C7B', // main-header
  },
  secondary: {
    400: '#00F152',
    main: '#2458F6',
    A400: 'linear-gradient(90deg, #00F152 0%, #0076E9 100%)',
  },
}

const palette: PaletteOptions = {
  mode: 'dark',
  common: { white: '#fff', black: '#021223' },
  success: {
    main: '#00C443',
  },
  error: {
    main: '#FF3838',
    dark: '#B71C1C',
  },

  primary: colors.primary,
  secondary: colors.secondary,
  accents: {
    yellow: '#FCE83A',
  },

  background: { default: '#021223', paper: '#333968' },
}

export default palette
