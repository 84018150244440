import { AppBar, Box, Theme } from '@mui/material'
import styled, { css } from 'styled-components'

export const CompanyLogo = styled(Box)`
  ${({ theme }: { theme: Theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      position: absolute;
      /* padding of the parent container */
      top: 8px;
      left: 16px;
    }
  `}
`
export const NavbarItem = styled(Box)`
  margin: 0 15px;
`
export const Trailing = styled(Box)`
  ${({ theme }: { theme: Theme }) => css`
    margin-left: auto;
    ${NavbarItem} {
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      margin-left: 0;
    }
  `}
`
interface ContainerProps {
  theme: Theme
  $bgColor?: string
}
export const Container = styled(AppBar)<ContainerProps>`
  ${({ theme, $bgColor }: ContainerProps) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: ${$bgColor || 'unset'};
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(12, 88, 255, 0.5) 0%,
        #0076e9 48.29%,
        rgba(12, 88, 255, 0.5) 97.25%
      );
    }
    .MuiToolbar-root {
      width: 100%;
      padding: 16px 20px;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      .MuiToolbar-root {
        min-height: 56px;
        padding: 8px 16px;
        justify-content: center;
      }
    }
  `}
`
