import {
  getCommifiedFormat,
  getDpFormat,
} from '@hailstonelabs/big-number-utils/dist/src'
import { Box } from '@mui/material'
import { getTokenIconPath } from '.'
import AppTypography from '../../components/AppTypography/AppTypography'
import InfoBox from '../../components/InfoBox/InfoBox'
import Table from '../../components/Table/Table'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { ChainId, NETWORKS } from '../../config/networks'
import { POST_ATTACK_BLOCK, POST_ATTACK_DATE } from '../../constants'
import { useWeb3 } from '../../contexts/Web3Context'
import { ExternalLink } from '../../gloabalStyles'
import { ActionAfterAttack, UserData } from '../../interfaces/uspCompensation'
import { localeDateFormat } from '../../utils/common'
import { getShortenedAddress } from '../../utils/getShortenedAddress'

export default function AfterAttackTable({
  data,
  isProcessing,
}: {
  data: UserData['afterAttack']
  isProcessing: boolean
}) {
  const { account } = useWeb3()
  if (!data) return <></>

  const header = (
    <AppTypography variant="subtitle2" mb="4px">
      Post-attack Adjustments
      <InfoBox.ToolTip
        text={`Adjustments to "Pre-attack Net Values" resulting from actions taken after the attack.`}
      />
      <br />
      <AppTypography transparent component="span" variant="caption">
        Snapshot:{' '}
        <ExternalLink
          href={
            NETWORKS[ChainId.AVALANCHE]?.blockExplorers?.default.url +
            '/block/' +
            POST_ATTACK_BLOCK
          }
        >
          {localeDateFormat(POST_ATTACK_DATE)}
        </ExternalLink>
      </AppTypography>
    </AppTypography>
  )
  return (
    <>
      {isProcessing ? (
        <Box>
          {header}
          <AppTypography variant="caption">
            Your adjustments are currently being processed and are not yet
            available.
          </AppTypography>
        </Box>
      ) : (
        <Table
          scrollableTable
          maxWidth="1000px"
          style={{ fontSize: '14px' }}
          HeaderComponent={header}
          FooterComponent={
            data.totalAdjustedAmount !== undefined && (
              <AppTypography variant="body2" mt="4px">
                Total adjustment: $
                {getCommifiedFormat(data.totalAdjustedAmount.toString(), 2)}
              </AppTypography>
            )
          }
        >
          <Table.Head>
            <Table.Data>Txn Hash</Table.Data>
            <Table.Data>Action</Table.Data>
            <Table.Data>Description</Table.Data>
            <Table.Data minWidth="200px" textAlign="center">
              Adjustment
            </Table.Data>
          </Table.Head>
          <Table.Body>
            {/** @todo should be d not [d]. It is because of the type of transactionAfterExploit from the response. Need to adjust the lambda reposnse later  */}
            {data.transactionAfterExploit.length > 0 &&
              data.transactionAfterExploit.map(([d], index) => {
                let adjustmentTokenSymbol: string | null = null
                let description: React.ReactNode | null = null
                let action: string | null = null
                switch (d.action) {
                  case ActionAfterAttack.LP_TRANSFER:
                    const hasReceivedLp =
                      account?.toLowerCase() === d.to.toLowerCase()
                    action = hasReceivedLp
                      ? 'Receive main pool LP token from another address'
                      : 'Send main pool LP token to another address'
                    adjustmentTokenSymbol = d.token
                    description = `${
                      hasReceivedLp ? 'Received' : 'Sent'
                    } ${getCommifiedFormat(d.amount.toString())} ${d.token}-LP`
                    break

                  case ActionAfterAttack.USP_TOKEN_TRANSFER:
                    const hasReceivedUsp =
                      account?.toLowerCase() === d.to.toLowerCase()
                    action = hasReceivedUsp
                      ? 'Receive USP from another address'
                      : 'Send USP to another address'
                    adjustmentTokenSymbol = 'USP'
                    description = `${
                      hasReceivedUsp ? 'Received' : 'Sent'
                    } ${getCommifiedFormat(d.amount.toString())} USP`
                    break

                  case ActionAfterAttack.POOL_WITHDRAW:
                    const hasWithdrawnUsp = d.token === 'USP'
                    action = hasWithdrawnUsp
                      ? 'Withdraw USP from main pool'
                      : 'Withdraw stables from main pool'
                    adjustmentTokenSymbol = d.token
                    description = `Withdrew ${getCommifiedFormat(
                      d.liquidity.toString(),
                    )} ${d.token}-LP in ${getCommifiedFormat(
                      d.amount.toString(),
                    )} ${d.token}`
                    break

                  case ActionAfterAttack.USP_BORROW:
                    action = 'Borrow USP'
                    adjustmentTokenSymbol = 'USP'
                    description = `Borrowed ${getCommifiedFormat(
                      d.amount.toString(),
                    )} USP against ${d.collateral}-LP`
                    break
                  case ActionAfterAttack.USP_REPAY:
                    action = 'Repay USP'
                    adjustmentTokenSymbol = 'USP'
                    description = `Repaid ${getCommifiedFormat(
                      d.amount.toString(),
                    )} USP against ${d.collateral}-LP`
                    break
                  case ActionAfterAttack.POOL_EMERGENCY_WITHDRAW:
                    const hasWithdrawnNonUsp = d.token !== 'USP'
                    action = hasWithdrawnNonUsp
                      ? 'Withdraw stables from main pool'
                      : 'Withdraw USP from main pool'
                    adjustmentTokenSymbol = d.token
                    description = `Emergency withdrew ${getCommifiedFormat(
                      d.amount.toString(),
                    )} ${d.token}`
                    break
                  case ActionAfterAttack.POOL_SWAP:
                    const hasSwappedToUsp = d.to_token === 'USP'
                    action = hasSwappedToUsp
                      ? 'Swap stable to USP (arbitrage)'
                      : 'Swap USP to stable (panic sell)'
                    adjustmentTokenSymbol = hasSwappedToUsp
                      ? d.from_token
                      : d.to_token
                    description = `Swapped from ${getCommifiedFormat(
                      d.from_amount.toString(),
                    )} ${d.from_token} to ${getCommifiedFormat(
                      d.to_amount.toString(),
                    )} ${d.to_token}`
                    break
                  default:
                    break
                }
                if (!adjustmentTokenSymbol || !description || !action)
                  return <></>
                return (
                  <AfterAttackTableRow
                    key={d.tx_id + d.log_index}
                    action={action}
                    adjustment={d.adjustment}
                    txnHash={d.tx_id}
                    adjustmentTokenSymbol={adjustmentTokenSymbol}
                    description={description}
                  />
                )
              })}
          </Table.Body>
        </Table>
      )}
    </>
  )
}

type Props = {
  txnHash: string
  action: React.ReactNode
  description: React.ReactNode
  adjustment: number
  adjustmentTokenSymbol: string
  weight?: number
  weightedAdjustment?: number
}
function AfterAttackTableRow({
  txnHash,
  action,
  description,
  adjustment,
  adjustmentTokenSymbol,
  weight,
  weightedAdjustment,
}: Props) {
  const transactionExploreLink =
    NETWORKS[ChainId.AVALANCHE]?.blockExplorers?.default.url + '/tx/'
  return (
    <Table.Row>
      <Table.Data>
        <ExternalLink href={transactionExploreLink + txnHash}>
          {getShortenedAddress(txnHash)}
        </ExternalLink>
      </Table.Data>
      <Table.Data>{action}</Table.Data>
      <Table.Data>{description}</Table.Data>
      <Table.Data textAlign="center">
        <AppTypography centerContent fontSize="inherit" justifyContent="center">
          <TokenIcon
            iconPath={getTokenIconPath(adjustmentTokenSymbol)}
            margin="0 4px 0 0"
          />
          {getCommifiedFormat(adjustment.toString())} {adjustmentTokenSymbol}{' '}
        </AppTypography>
        <AppTypography transparent component="span" fontSize="inherit">
          (${getCommifiedFormat(adjustment.toString(), 2)})
        </AppTypography>
      </Table.Data>
      {weight && <Table.Data>{getDpFormat(weight.toString())}%</Table.Data>}
      {weightedAdjustment && (
        <Table.Data textAlign="center">
          <AppTypography
            centerContent
            fontSize="inherit"
            justifyContent="center"
          >
            <TokenIcon
              iconPath={getTokenIconPath(adjustmentTokenSymbol)}
              margin="0 4px 0 0"
            />
            {getCommifiedFormat(weightedAdjustment.toString())}{' '}
            {adjustmentTokenSymbol}{' '}
          </AppTypography>
          <AppTypography transparent component="span" fontSize="inherit">
            (${getCommifiedFormat(weightedAdjustment.toString(), 2)})
          </AppTypography>
        </Table.Data>
      )}
    </Table.Row>
  )
}
