import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { BoxProps, ButtonProps, SliderProps, Typography } from '@mui/material'
import clsx from 'clsx'
import React, { createContext, ReactElement, useContext } from 'react'
import DefaultIcon from '../../assets/icons/platypus-white.svg'
import { TokenSymbol } from '../../config/contracts/Token/TokenSymbol'
import { InputLabel, LabelProps } from '../Input/Input'
import {
  ActionButtonContainer,
  InputLabelInnerContainer,
  Label,
  SelectTokenLabel,
  StyledButton,
  StyledInnerContainer,
  StyledInput,
  StyledInputProps,
  StyledSelectTokenButton,
  StyledSlider,
  StyledTokenIcon,
  SwapDirectionIconContainer,
  TokenInputContainer,
} from './AppTokenInput.elements'

interface ContextType {
  value: string
  onChange?: (value: string) => void
  disabled?: boolean
}
const TokenInputContext = createContext<ContextType>({} as ContextType)

const useTokenInput = () => {
  return useContext(TokenInputContext)
}
interface Props {
  onChange?: (value: string) => void
  value: string
  disabled?: boolean
  className?: string
  children: React.ReactNode
}
function AppTokenInput({
  onChange,
  value,
  disabled,
  className,
  children,
}: Props): ReactElement {
  return (
    <TokenInputContext.Provider value={{ onChange, value, disabled }}>
      <TokenInputContainer
        display="flex"
        flexDirection="column"
        className={className}
      >
        {children}
      </TokenInputContainer>
    </TokenInputContext.Provider>
  )
}

export default AppTokenInput

interface IconWrapperProps {
  className?: string | undefined
  onClick?: () => void
  disabled?: boolean | undefined
  isMobile?: boolean | undefined
}
const SwapDirectionButton = ({
  className,
  onClick,
  disabled,
  isMobile,
}: IconWrapperProps): ReactElement => {
  return (
    <SwapDirectionIconContainer
      className={clsx(
        isMobile && 'swap-direction-button--mobile',
        className && className,
      )}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      disabled={disabled}
    >
      <KeyboardArrowDownRoundedIcon />
    </SwapDirectionIconContainer>
  )
}

const TokenInputLabel = ({
  className,
  leadingLabel,
  trailingLabel,
}: LabelProps): ReactElement => {
  return (
    <InputLabel
      className={className}
      leadingLabel={leadingLabel}
      trailingLabel={trailingLabel}
    />
  )
}

interface ActionButtonProps extends ButtonProps {
  className?: string | undefined
  onClick?: () => void | undefined
  children?: React.ReactNode | undefined
  spacer?: boolean | undefined
}

const ActionButton = ({
  className,
  onClick,
  children,
  disabled,
  spacer,
  ...otherProps
}: ActionButtonProps): ReactElement => {
  return (
    <ActionButtonContainer>
      {!spacer && (
        <StyledButton
          className={className}
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          {...otherProps}
          customVariant="neutral"
        >
          {children && <Typography variant="subtitle2">{children}</Typography>}
        </StyledButton>
      )}
    </ActionButtonContainer>
  )
}
interface SelectTokenButtonProps {
  className?: string | undefined
  token: TokenSymbol | null
  onSelectToken?: () => void | undefined
  disabled?: boolean | undefined
  iconPath?: string
}
const SelectTokenButton = ({
  className,
  token,
  onSelectToken,
  disabled,
  iconPath,
}: SelectTokenButtonProps) => {
  return (
    <StyledSelectTokenButton
      className={clsx(
        'app-token-input__select-token-button',
        className && className,
      )}
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      onClick={disabled ? undefined : onSelectToken}
      // using boolean for disabledhover (even changing to boolean type) will prompt a warning message
      disabledhover={onSelectToken ? undefined : 'true'}
      disabled={disabled}
    >
      {token ? (
        <StyledTokenIcon tokenSymbol={token} />
      ) : (
        <StyledTokenIcon iconPath={iconPath || DefaultIcon} />
      )}
      <SelectTokenLabel variant="body1">
        {token ? token : 'Select a Token'}
      </SelectTokenLabel>
      {onSelectToken && (
        <KeyboardArrowDownRoundedIcon className="select-token-button__arrow-down" />
      )}
    </StyledSelectTokenButton>
  )
}

interface InputFieldProps extends StyledInputProps {
  placeholder: string
  className?: string | undefined
  children?: React.ReactNode | undefined
  style?: React.CSSProperties | undefined
  readOnly?: 'readOnly' | undefined
  inputUnitLabel?: string | undefined
  textalign?: string
}
const InputField = ({
  style,
  className,
  children,
  placeholder,
  disableUnderline,
  readOnly,
  inputUnitLabel,
  disabled,
  textalign = 'right',
  noLeftPadding,
}: InputFieldProps): ReactElement => {
  const { value, onChange } = useTokenInput()
  return (
    <StyledInput
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      flexDirection="row-reverse"
      textalign={textalign}
      inputMode="decimal"
      bgColor=""
      disableUnderline={disableUnderline}
      inputUnitLabel={inputUnitLabel}
      readOnly={readOnly}
      disabled={disabled}
      style={style}
      noLeftPadding={noLeftPadding}
    >
      {children && children}
    </StyledInput>
  )
}

const InnerContainer = ({ children, ...otherProps }: BoxProps) => {
  return <StyledInnerContainer {...otherProps}>{children}</StyledInnerContainer>
}

interface AppSliderProps extends SliderProps {
  railWidth?: string
}
const Slider = ({ className, railWidth, ...otherProps }: AppSliderProps) => {
  const { value, onChange } = useTokenInput()

  const handleSliderChange = (value: number | number[]) => {
    const isNumber = typeof value === 'number'
    const newValue = isNumber ? String(value) : ''
    onChange && onChange(newValue)
  }
  return (
    <StyledSlider
      className={clsx('app-token-input__slider', className && className)}
      defaultValue={Number(value)}
      valueLabelDisplay="off"
      marks={[{ value: 0 }, { value: 10 }]}
      min={0}
      max={100}
      value={Number(value)}
      onChange={(_, value) => handleSliderChange(value)}
      $railWidth={railWidth}
      {...otherProps}
    />
  )
}
AppTokenInput.TokenInputLabel = React.memo(TokenInputLabel)
AppTokenInput.TokenInputLabelInnerContainer = React.memo(
  InputLabelInnerContainer,
)
AppTokenInput.TokenInputLabelItem = React.memo(Label)
AppTokenInput.ActionButton = React.memo(ActionButton)
AppTokenInput.SelectTokenButton = React.memo(SelectTokenButton)
AppTokenInput.InputField = React.memo(InputField)
AppTokenInput.SwapDirectionButton = React.memo(SwapDirectionButton)
AppTokenInput.InnerContainer = React.memo(InnerContainer)
AppTokenInput.Slider = React.memo(Slider)
