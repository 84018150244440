import { ReactElement } from 'react'
import AppButton from '../../components/buttons/AppButton/AppButton'
import { Message, StyledModal, Title } from './ErrorModalContainer.elements'
interface Props {
  isOpen: boolean
  title?: string
  message?: string
  onCloseErrorModal: () => void
}

function ErrorModalContainer({
  isOpen,
  title,
  message,
  onCloseErrorModal,
}: Props): ReactElement {
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onCloseErrorModal}
      disableCloseBtn
      disableTopBar
      width="500px"
    >
      <Title>{title ? title : `Unlucky! There's an error!`}</Title>
      <Message>{message}</Message>
      <AppButton
        margin="0"
        customVariant="negative"
        onClick={onCloseErrorModal}
      >
        Okay
      </AppButton>
    </StyledModal>
  )
}

export default ErrorModalContainer
