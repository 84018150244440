import { Box } from '@mui/material'
import AppTypography from '../../../components/AppTypography/AppTypography'
import NetworkIcon from '../../../components/icons/NetworkIcon/NetworkIcon'
import InfoBox from '../../../components/InfoBox/InfoBox'
import { ChainId, NETWORKS } from '../../../config/networks'
import { localeDateFormat } from '../../../utils/common'

interface AirdropOfficialCardInfoProps {
  startDate: Date
  endDate: number
  chainId: ChainId
}

export const AirdropOfficialCardInfo = ({
  startDate,
  endDate,
  chainId,
}: AirdropOfficialCardInfoProps) => {
  return (
    <InfoBox paddingBottom="0" width="100%">
      <InfoBox.Item mb="4px">
        <InfoBox.Item>
          <AppTypography variant="caption2" transparent>
            Network:
          </AppTypography>
        </InfoBox.Item>
        <Box display="flex" alignItems="center">
          <NetworkIcon size={16} chainId={chainId} margin="0 4px 0 0" />
          <AppTypography variant="caption2" transparent>
            {NETWORKS[chainId].name}
          </AppTypography>
        </Box>
      </InfoBox.Item>
      <InfoBox.Item mb="4px">
        <InfoBox.Item>
          <AppTypography variant="caption2" transparent>
            Start:
          </AppTypography>
        </InfoBox.Item>
        <AppTypography variant="caption2" transparent>
          {localeDateFormat(startDate)}
        </AppTypography>
      </InfoBox.Item>
    </InfoBox>
  )
}
