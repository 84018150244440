import { BigNumber, utils } from 'ethers'
import {
  merkleDistributorV1ABI,
  merkleDistributorV2ABI,
} from '../../../config/contracts/abis'
import { TOKENS } from '../../../config/contracts/Token'
import {
  OFFICIAL_AIRDROP_CAMPAIGNS,
  THIRD_PARTY_AIRDROP_CAMPAIGNS,
} from '../../../constants'
import { AirdropType } from '../../../interfaces/Airdrop'
import { ICallbacks, ICalls } from '../../../interfaces/Multicall'
import { MerkleDistributorInfoClaim } from '../../../utils/merkleDistributor/parse-balance-map'

export type IAirdropData = {
  [id in AirdropType]: {
    [id in string]: {
      isClaimed: boolean
      claimableUntil: number
      airdropAmount: string
      index: number | undefined
      airdropAmountHexStr: string | undefined
      proof: string[] | undefined
    }
  }
}

export const fetchAirdropData = (
  merkleInfos:
    | {
        [campaignAddress in string]: MerkleDistributorInfoClaim | undefined
      },
): {
  contractCalls: ICalls
  callbacks: ICallbacks
  states: IAirdropData
} => {
  const contractCalls: ICalls = []
  const callbacks: ICallbacks = []
  const states: IAirdropData = {
    [AirdropType.OFFICIAL]: {},
    [AirdropType.THIRD_PARTY]: {},
  }
  /** OFFICIAL_AIRDROP_CAMPAIGNS */
  OFFICIAL_AIRDROP_CAMPAIGNS.forEach((campaign) => {
    const merkleInfoClaim = merkleInfos[campaign.address.toLowerCase()]
    const index = merkleInfoClaim ? merkleInfoClaim.index : undefined
    const proof = merkleInfoClaim ? merkleInfoClaim.proof : undefined
    const airdropAmountHexStr = merkleInfoClaim?.amount
    contractCalls.push({
      address: campaign.address,
      abi: campaign.isMerkleDistributorV1
        ? merkleDistributorV1ABI
        : merkleDistributorV2ABI,
      functionName: campaign.isMerkleDistributorV1
        ? 'claimableUntill'
        : 'claimableUntil',
      chainId: campaign.chainId,
    })
    callbacks.push((value) => {
      states[AirdropType.OFFICIAL][campaign.address] = {
        ...states[AirdropType.OFFICIAL][campaign.address],
        claimableUntil: (value as BigNumber).toNumber(),
        index,
        airdropAmountHexStr,
        proof,
      }
    })
    if (index !== undefined && airdropAmountHexStr && campaign.tokenSymbol) {
      const tokenDecimals = TOKENS[campaign.tokenSymbol].decimals
      contractCalls.push({
        address: campaign.address,
        abi: merkleDistributorV1ABI,
        functionName: 'isClaimed',
        args: [index],
        chainId: campaign.chainId,
      })
      callbacks.push((value) => {
        states[AirdropType.OFFICIAL][campaign.address] = {
          ...states[AirdropType.OFFICIAL][campaign.address],
          isClaimed: value as boolean,
          airdropAmount: utils.formatUnits(
            BigNumber.from(airdropAmountHexStr),
            tokenDecimals,
          ),
        }
      })
    }
  })
  /** THIRD_PARTY_AIRDROP_CAMPAIGNS */
  THIRD_PARTY_AIRDROP_CAMPAIGNS.forEach((campaign) => {
    const merkleInfoClaim = merkleInfos[campaign.address.toLowerCase()]
    const index = merkleInfoClaim ? merkleInfoClaim.index : undefined
    const proof = merkleInfoClaim ? merkleInfoClaim.proof : undefined
    const airdropAmountHexStr = merkleInfoClaim?.amount
    contractCalls.push({
      address: campaign.address,
      abi: campaign.isMerkleDistributorV1
        ? merkleDistributorV1ABI
        : merkleDistributorV2ABI,
      functionName: campaign.isMerkleDistributorV1
        ? 'claimableUntill'
        : 'claimableUntil',
      chainId: campaign.chainId,
    })
    callbacks.push((value) => {
      states[AirdropType.THIRD_PARTY][campaign.address] = {
        ...states[AirdropType.THIRD_PARTY][campaign.address],
        claimableUntil: (value as BigNumber).toNumber(),
        index,
        airdropAmountHexStr,
        proof,
      }
    })
    if (index !== undefined && airdropAmountHexStr && campaign.tokenSymbol) {
      const tokenDecimals = TOKENS[campaign.tokenSymbol].decimals
      contractCalls.push({
        address: campaign.address,
        abi: merkleDistributorV2ABI,
        functionName: 'isClaimed',
        args: [index],
        chainId: campaign.chainId,
      })
      callbacks.push((value) => {
        states[AirdropType.THIRD_PARTY][campaign.address] = {
          ...states[AirdropType.THIRD_PARTY][campaign.address],
          isClaimed: value as boolean,
          airdropAmount: utils.formatUnits(
            BigNumber.from(airdropAmountHexStr),
            tokenDecimals,
          ),
        }
      })
    }
  })
  return {
    contractCalls,
    callbacks,
    states,
  }
}
