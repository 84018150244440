import { TokenSymbol } from '../../../../config/contracts/Token/TokenSymbol'
import { ChainId } from '../../../../config/networks'
import { ThirdPartyAirdropId } from '../../../../config/thirdPartyAirdrops'
import { AirdropCampaign } from '../../../../interfaces/Airdrop'
import METIS_MAIN_HUM_22_BALANCES from '../metis_mainnet/22_HUM.json'
import METIS_MAIN_HUM_23_BALANCES from '../metis_mainnet/23_HUM.json'
import METIS_MAIN_HUM_24_BALANCES from '../metis_mainnet/24_HUM.json'
import METIS_MAIN_HUM_25_BALANCES from '../metis_mainnet/25_HUM.json'
import METIS_MAIN_HUM_26_BALANCES from '../metis_mainnet/26_HUM.json'
import METIS_MAIN_HUM_27_BALANCES from '../metis_mainnet/27_HUM.json'
import METIS_MAIN_HUM_28_BALANCES from '../metis_mainnet/28_HUM.json'
import METIS_MAIN_HUM_29_BALANCES from '../metis_mainnet/29_HUM.json'
import METIS_MAIN_HUM_30_BALANCES from '../metis_mainnet/30_HUM.json'
import METIS_MAIN_HUM_31_BALANCES from '../metis_mainnet/31_HUM.json'
import METIS_MAIN_HUM_32_BALANCES from '../metis_mainnet/32_HUM.json'
import METIS_MAIN_HUM_33_BALANCES from '../metis_mainnet/33_HUM.json'
import METIS_MAIN_HUM_34_BALANCES from '../metis_mainnet/34_HUM.json'
import METIS_MAIN_HUM_1_BALANCES from '../metis_mainnet/metis_hum_20230206.json'
import METIS_MAIN_HUM_2_BALANCES from '../metis_mainnet/metis_hum_20230220.json'
import METIS_MAIN_HUM_3_BALANCES from '../metis_mainnet/metis_hum_20230306.json'
import METIS_MAIN_HUM_4_BALANCES from '../metis_mainnet/metis_hum_20230320.json'
import METIS_MAIN_HUM_5_BALANCES from '../metis_mainnet/metis_hum_20230403.json'
import METIS_MAIN_HUM_6_BALANCES from '../metis_mainnet/metis_hum_20230417.json'
import METIS_MAIN_HUM_7_BALANCES from '../metis_mainnet/metis_hum_20230501.json'
import METIS_MAIN_HUM_8_BALANCES from '../metis_mainnet/metis_hum_20230515.json'
import METIS_MAIN_HUM_9_BALANCES from '../metis_mainnet/metis_hum_20230529.json'
import METIS_MAIN_HUM_10_BALANCES from '../metis_mainnet/metis_hum_20230612.json'
import METIS_MAIN_HUM_11_BALANCES from '../metis_mainnet/metis_hum_20230626.json'
import METIS_MAIN_HUM_12_BALANCES from '../metis_mainnet/metis_hum_20230710.json'
import METIS_MAIN_HUM_13_BALANCES from '../metis_mainnet/metis_hum_20230724.json'
import METIS_MAIN_HUM_14_BALANCES from '../metis_mainnet/metis_hum_20230807.json'
import METIS_MAIN_HUM_15_BALANCES from '../metis_mainnet/metis_hum_20230821.json'
import METIS_MAIN_HUM_16_BALANCES from '../metis_mainnet/metis_hum_20230904.json'
import METIS_MAIN_HUM_17_BALANCES from '../metis_mainnet/metis_hum_20230918.json'
import METIS_MAIN_HUM_18_BALANCES from '../metis_mainnet/metis_hum_20231002.json'
import METIS_MAIN_HUM_19_BALANCES from '../metis_mainnet/metis_hum_20231016.json'
import METIS_MAIN_HUM_20_BALANCES from '../metis_mainnet/metis_hum_20231030.json'
import METIS_MAIN_HUM_21_BALANCES from '../metis_mainnet/metis_hum_20231113.json'

// Hummus
const HUMMUS_AIRDROP_1: AirdropCampaign = {
  address: '0x549Ae86BE926C73585cA2695143a5dd5335365Ac',
  balances: METIS_MAIN_HUM_1_BALANCES,
  snapshotDate: new Date('2023-02-06T09:00:00+00:00'),
  startDate: new Date('2023-02-13T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_2: AirdropCampaign = {
  address: '0x961311c1846977c8818111be4ED02d8370C5fFb8',
  balances: METIS_MAIN_HUM_2_BALANCES,
  snapshotDate: new Date('2023-02-20T09:00:00+00:00'),
  startDate: new Date('2023-02-27T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_3: AirdropCampaign = {
  address: '0xFCfDaD2685FE4d3E8cC72078ed93020f0F3a1396',
  balances: METIS_MAIN_HUM_3_BALANCES,
  snapshotDate: new Date('2023-03-06T09:00:00+00:00'),
  startDate: new Date('2023-03-13T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_4: AirdropCampaign = {
  address: '0xDBaa812aF5D8360E018DF57DAFE5c8dA137f3385',
  balances: METIS_MAIN_HUM_4_BALANCES,
  snapshotDate: new Date('2023-03-20T09:00:00+00:00'),
  startDate: new Date('2023-03-27T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_5: AirdropCampaign = {
  address: '0x96Bbaf2cf8876e7861766334B202ce6aEDE68180',
  balances: METIS_MAIN_HUM_5_BALANCES,
  snapshotDate: new Date('2023-04-03T09:00:00+00:00'),
  startDate: new Date('2023-04-10T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_6: AirdropCampaign = {
  address: '0xc126b9FdCcD7Da5E9DF400665Fa7E3fe38dA531E',
  balances: METIS_MAIN_HUM_6_BALANCES,
  snapshotDate: new Date('2023-04-17T09:00:00+00:00'),
  startDate: new Date('2023-04-24T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_7: AirdropCampaign = {
  address: '0x15320581570d779DFe1e4102f2A51E8c3f734a7A',
  balances: METIS_MAIN_HUM_7_BALANCES,
  snapshotDate: new Date('2023-05-01T09:00:00+00:00'),
  startDate: new Date('2023-05-08T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_8: AirdropCampaign = {
  address: '0xAF6D33AbC2Af1809817B036F39500a4e242E8e5A',
  balances: METIS_MAIN_HUM_8_BALANCES,
  snapshotDate: new Date('2023-05-15T09:00:00+00:00'),
  startDate: new Date('2023-05-22T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_9: AirdropCampaign = {
  address: '0x32aBFEa54d50A1AC2e2515f9F1028A5AF7cec339',
  balances: METIS_MAIN_HUM_9_BALANCES,
  snapshotDate: new Date('2023-05-29T09:00:00+00:00'),
  startDate: new Date('2023-06-05T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_10: AirdropCampaign = {
  address: '0x4863cf3afcaDFbcB6e2e1A2BE82A4D12be088816',
  balances: METIS_MAIN_HUM_10_BALANCES,
  snapshotDate: new Date('2023-06-12T09:00:00+00:00'),
  startDate: new Date('2023-06-19T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_11: AirdropCampaign = {
  address: '0x67F4848Cba9C067043163aA1D0FCAbFE14F962f9',
  balances: METIS_MAIN_HUM_11_BALANCES,
  snapshotDate: new Date('2023-06-26T09:00:00+00:00'),
  startDate: new Date('2023-07-03T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_12: AirdropCampaign = {
  address: '0xa5648195946C6d27f848b57AFE9C3e749eB92195',
  balances: METIS_MAIN_HUM_12_BALANCES,
  snapshotDate: new Date('2023-07-10T09:00:00+00:00'),
  startDate: new Date('2023-07-17T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_13: AirdropCampaign = {
  address: '0xAdC53FAba2137Edc6aa47D5Cc57d509b69701A30',
  balances: METIS_MAIN_HUM_13_BALANCES,
  snapshotDate: new Date('2023-07-24T09:00:00+00:00'),
  startDate: new Date('2023-07-31T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_14: AirdropCampaign = {
  address: '0xfCFED2A0941EC64E1cD660e373006a2735c09Bb4',
  balances: METIS_MAIN_HUM_14_BALANCES,
  snapshotDate: new Date('2023-08-07T09:00:00+00:00'),
  startDate: new Date('2023-08-14T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_15: AirdropCampaign = {
  address: '0xEa90a73B1725D6263bc819A3Bb472d2bf6CD5E54',
  balances: METIS_MAIN_HUM_15_BALANCES,
  snapshotDate: new Date('2023-08-21T09:00:00+00:00'),
  startDate: new Date('2023-08-28T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_16: AirdropCampaign = {
  address: '0xa0D22587b95f7a0eC91f7b3e0A024a153D5268Df',
  balances: METIS_MAIN_HUM_16_BALANCES,
  snapshotDate: new Date('2023-09-04T09:00:00+00:00'),
  startDate: new Date('2023-09-11T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_17: AirdropCampaign = {
  address: '0x13549D176a1fe8bCb3DE7B7895F2bc1464C1b12f',
  balances: METIS_MAIN_HUM_17_BALANCES,
  snapshotDate: new Date('2023-09-18T09:00:00+00:00'),
  startDate: new Date('2023-09-25T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

export const HUMMUS_AIRDROP_18: AirdropCampaign = {
  address: '0x0F9FE603fB226C3F6Fb66DB1b2F312bAa1ec7815',
  balances: METIS_MAIN_HUM_18_BALANCES,
  snapshotDate: new Date('2023-10-02T09:00:00+00:00'),
  startDate: new Date('2023-10-09T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_19: AirdropCampaign = {
  address: '0x369929665BB1cc0EA5c85D24E1372ca83328aA03',
  balances: METIS_MAIN_HUM_19_BALANCES,
  snapshotDate: new Date('2023-10-16T09:00:00+00:00'),
  startDate: new Date('2023-10-23T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_20: AirdropCampaign = {
  address: '0x4387CFE54ECD5eA549578de991aDB9AE7a5d93CF',
  balances: METIS_MAIN_HUM_20_BALANCES,
  snapshotDate: new Date('2023-10-30T09:00:00+00:00'),
  startDate: new Date('2023-11-06T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_21: AirdropCampaign = {
  address: '0x3AcAb731E0e4e981F547842AAEac8Fa7B9Ea6096',
  balances: METIS_MAIN_HUM_21_BALANCES,
  snapshotDate: new Date('2023-11-13T09:00:00+00:00'),
  startDate: new Date('2023-11-19T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_22: AirdropCampaign = {
  address: '0x597d79cC3Dd4567240cCAAD5940eD5D2A0bF2cBC',
  balances: METIS_MAIN_HUM_22_BALANCES,
  snapshotDate: new Date('2023-11-27T09:00:00+00:00'),
  startDate: new Date('2023-12-04T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}
const HUMMUS_AIRDROP_23: AirdropCampaign = {
  address: '0xe2cD307edeE986c479503f948957D1cebC65CCB8',
  balances: METIS_MAIN_HUM_23_BALANCES,
  snapshotDate: new Date('2023-12-11T09:00:00+00:00'),
  startDate: new Date('2023-12-18T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_24: AirdropCampaign = {
  address: '0x4361cfbEA89BB1d8e72C42D1694Df9C3e143Dafe',
  balances: METIS_MAIN_HUM_24_BALANCES,
  snapshotDate: new Date('2023-12-25T09:00:00+00:00'),
  startDate: new Date('2024-01-01T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_25: AirdropCampaign = {
  address: '0x4d55968e523E572A3aCdae6043112185E4C43cF6',
  balances: METIS_MAIN_HUM_25_BALANCES,
  snapshotDate: new Date('2024-01-08T09:00:00+00:00'),
  startDate: new Date('2024-01-15T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_26: AirdropCampaign = {
  address: '0xA301dEAae96FA8f732E4d666Ad17ebd734309950',
  balances: METIS_MAIN_HUM_26_BALANCES,
  snapshotDate: new Date('2024-01-22T09:00:00+00:00'),
  startDate: new Date('2024-01-29T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_27: AirdropCampaign = {
  address: '0xA6e3CE3cf72Ec00889Df4B4d8B152BeEaF975B84',
  balances: METIS_MAIN_HUM_27_BALANCES,
  snapshotDate: new Date('2024-02-05T09:00:00+00:00'),
  startDate: new Date('2024-02-12T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_28: AirdropCampaign = {
  address: '0x8F3A9BD28dfaB956D3F828B6d52184770225bAa0',
  balances: METIS_MAIN_HUM_28_BALANCES,
  snapshotDate: new Date('2024-02-19T09:00:00+00:00'),
  startDate: new Date('2024-02-26T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_29: AirdropCampaign = {
  address: '0x6d9a79BFE26bc8E797432d842fcAd4ca9282BCdE',
  balances: METIS_MAIN_HUM_29_BALANCES,
  snapshotDate: new Date('2024-03-04T09:00:00+00:00'),
  startDate: new Date('2024-03-11T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_30: AirdropCampaign = {
  address: '0xaa06f50555402462d6e5c933feE89C378B21526C',
  balances: METIS_MAIN_HUM_30_BALANCES,
  snapshotDate: new Date('2024-03-18T09:00:00+00:00'),
  startDate: new Date('2024-03-25T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_31: AirdropCampaign = {
  address: '0x03b645E2aAd610d9Fc815597364c600777b50425',
  balances: METIS_MAIN_HUM_31_BALANCES,
  snapshotDate: new Date('2024-04-01T09:00:00+00:00'),
  startDate: new Date('2024-04-08T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_32: AirdropCampaign = {
  address: '0x2dF17731936B0157363cbD7ad723cB89Aa7C8Bd4',
  balances: METIS_MAIN_HUM_32_BALANCES,
  snapshotDate: new Date('2024-04-15T09:00:00+00:00'),
  startDate: new Date('2024-04-22T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_33: AirdropCampaign = {
  address: '0x9D3cAfD7Bd85621ccC1265809ebE3D5C72185E9b',
  balances: METIS_MAIN_HUM_33_BALANCES,
  snapshotDate: new Date('2024-04-29T09:00:00+00:00'),
  startDate: new Date('2024-05-06T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

const HUMMUS_AIRDROP_34: AirdropCampaign = {
  address: '0x10ED160b7dE421e1B9208c59E5868348f382f715',
  balances: METIS_MAIN_HUM_34_BALANCES,
  snapshotDate: new Date('2024-05-13T09:00:00+00:00'),
  startDate: new Date('2024-05-20T09:00:00+00:00'),
  thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
  chainId: ChainId.METIS_ANDROMEDA_MAINNET,
  tokenSymbol: TokenSymbol.HUM,
}

// const HUMMUS_AIRDROP_35: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-05-27T09:00:00+00:00'),
//   startDate: new Date('2024-06-03T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_36: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-06-10T09:00:00+00:00'),
//   startDate: new Date('2024-06-17T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_37: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-06-24T09:00:00+00:00'),
//   startDate: new Date('2024-07-01T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_38: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-07-08T09:00:00+00:00'),
//   startDate: new Date('2024-07-15T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_39: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-07-22T09:00:00+00:00'),
//   startDate: new Date('2024-07-29T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_40: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-08-05T09:00:00+00:00'),
//   startDate: new Date('2024-08-12T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_41: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-08-19T09:00:00+00:00'),
//   startDate: new Date('2024-08-26T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_42: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-09-02T09:00:00+00:00'),
//   startDate: new Date('2024-09-09T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_43: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-09-16T09:00:00+00:00'),
//   startDate: new Date('2024-09-23T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_44: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-09-30T09:00:00+00:00'),
//   startDate: new Date('2024-10-07T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_45: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-10-14T09:00:00+00:00'),
//   startDate: new Date('2024-10-21T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }
// const HUMMUS_AIRDROP_46: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-10-28T09:00:00+00:00'),
//   startDate: new Date('2024-11-04T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_47: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-11-11T09:00:00+00:00'),
//   startDate: new Date('2024-11-18T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

// const HUMMUS_AIRDROP_48: AirdropCampaign = {
//   /**@todo replace real contract address&balances*/
//   address: '',
//   balances: ,
//   snapshotDate: new Date('2024-11-25T09:00:00+00:00'),
//   startDate: new Date('2024-12-02T09:00:00+00:00'),
//   thirdPartyAirdropId: ThirdPartyAirdropId.HUMMUS_MAINNET,
//   chainId: ChainId.METIS_ANDROMEDA_MAINNET,
//   tokenSymbol: TokenSymbol.HUM,
// }

export const METIS_MAINNET_AIRDROPS = [
  HUMMUS_AIRDROP_1,
  HUMMUS_AIRDROP_2,
  HUMMUS_AIRDROP_3,
  HUMMUS_AIRDROP_4,
  HUMMUS_AIRDROP_5,
  HUMMUS_AIRDROP_6,
  HUMMUS_AIRDROP_7,
  HUMMUS_AIRDROP_8,
  HUMMUS_AIRDROP_9,
  HUMMUS_AIRDROP_10,
  HUMMUS_AIRDROP_11,
  HUMMUS_AIRDROP_12,
  HUMMUS_AIRDROP_13,
  HUMMUS_AIRDROP_14,
  HUMMUS_AIRDROP_15,
  HUMMUS_AIRDROP_16,
  HUMMUS_AIRDROP_17,
  HUMMUS_AIRDROP_18,
  HUMMUS_AIRDROP_19,
  HUMMUS_AIRDROP_20,
  HUMMUS_AIRDROP_21,
  HUMMUS_AIRDROP_22,
  HUMMUS_AIRDROP_23,
  HUMMUS_AIRDROP_24,
  HUMMUS_AIRDROP_25,
  HUMMUS_AIRDROP_26,
  HUMMUS_AIRDROP_27,
  HUMMUS_AIRDROP_28,
  HUMMUS_AIRDROP_29,
  HUMMUS_AIRDROP_30,
  HUMMUS_AIRDROP_31,
  HUMMUS_AIRDROP_32,
  HUMMUS_AIRDROP_33,
  HUMMUS_AIRDROP_34,
  // HUMMUS_AIRDROP_35,
  // HUMMUS_AIRDROP_36,
  // HUMMUS_AIRDROP_37,
  // HUMMUS_AIRDROP_38,
  // HUMMUS_AIRDROP_39,
  // HUMMUS_AIRDROP_40,
  // HUMMUS_AIRDROP_41,
  // HUMMUS_AIRDROP_42,
  // HUMMUS_AIRDROP_43,
  // HUMMUS_AIRDROP_44,
  // HUMMUS_AIRDROP_45,
  // HUMMUS_AIRDROP_46,
  // HUMMUS_AIRDROP_47,
  // HUMMUS_AIRDROP_48,
]
