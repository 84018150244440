import { lessThanZeroPointZeroOne } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import React from 'react'
import { NonSelectedSpan, ToolTipContainer } from './InfoBox.element'
interface Props {
  leftSymbol?: string
  rightSymbol?: string
  displayZeroValue: string
  displayValue: string
  decimal: number
  lessThan: boolean
  actualValue: BigNumber
  iconsize?: string
  placement?:
    | 'left'
    | 'right'
    | 'bottom'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
  enableHighlight?: boolean
}
const ToolTipNumberBox = ({
  leftSymbol,
  rightSymbol,
  displayZeroValue,
  actualValue,
  displayValue,
  decimal,
  lessThan,
  placement = 'left',
  iconsize = '15px',
  enableHighlight,
}: Props): React.ReactElement => {
  type DisplayType = 'normal' | 'positive' | 'negative'
  let displayContent: {
    value: string
    type: DisplayType
  } = { value: displayValue, type: 'normal' }
  // Rule of lessThan
  // We need to show "< 0.01" or 2 dp. and when hover over it, it should show a tooltip with the exact value '0.0005'

  // Rule of enableHighlight
  /*  red | white | white | green
   * (%)-0.01     0      +0.01
   * Example 1, if 100 USDT.e can be swapped to 99.95 DAI.e, then we should display the price impact as -0.05%.
   *
   * Example 2, if 100 USDT.e can be swapped to 100.05 DAI.e, then we should display the price impact as +0.05%.
   * If >= +0.01%, then show price impact (2 d.p.) in green,
   * if 0 =< price impact < +0.01%, then show "< +0.01%" in white,
   * if -0.01% < price impact =< 0, then show "> -0.01%" in white,
   * if price impact <= -0.01%, then show price impact (2 d.p.) in red,
   *
   */

  if (lessThan) {
    const isLessThanZeroPointZeroOne = lessThanZeroPointZeroOne(
      actualValue.abs(),
      decimal,
    )
    if (enableHighlight) {
      const isNegative = actualValue.isNegative()
      if (isLessThanZeroPointZeroOne) {
        displayContent = {
          value: isNegative ? '> -0.01' : '< +0.01',
          type: 'normal',
        }
      } else {
        displayContent = {
          value: isNegative
            ? `${displayValue.toString()}`
            : `+${displayValue.toString()}`,
          type: isNegative ? 'negative' : 'positive',
        }
      }
    } else {
      displayContent = {
        value: isLessThanZeroPointZeroOne ? '< 0.01' : displayValue,
        type: 'normal',
      }
    }
  }
  // if actualValue == 0 -> displayContent displayZeroValue
  if (actualValue.eq(BigNumber.from('0'))) {
    displayContent = { value: displayZeroValue, type: 'normal' }
  }
  const toolTipValue = utils.formatUnits(actualValue, decimal)

  const color: { [key in DisplayType]: string } = {
    negative: '#F44336',
    positive: '#32FF44',
    normal: 'unset',
  }
  return (
    <ToolTipContainer
      enterTouchDelay={0}
      arrow
      placement={placement}
      title={
        leftSymbol
          ? `${leftSymbol} ${toolTipValue}`
          : rightSymbol
          ? `${toolTipValue} ${rightSymbol}`
          : toolTipValue
      }
      iconsize={iconsize}
    >
      <NonSelectedSpan
        style={{
          color: color[displayContent.type],
        }}
      >
        {leftSymbol
          ? `${leftSymbol} ${displayContent.value}`
          : rightSymbol
          ? `${displayContent.value} ${rightSymbol}`
          : displayContent.value}
      </NonSelectedSpan>
    </ToolTipContainer>
  )
}

export default ToolTipNumberBox
