import {
  AirdropModalData,
  ModalAction,
  ModalActionType,
  ModalId,
} from '../../interfaces/Modal'

export const openModal = (
  modalId: ModalId,
  data?: {
    transactionHashes?: string[]
    airdropData?: AirdropModalData[]
  },
): ModalAction => {
  return {
    type: ModalActionType.OPEN_MODAL,
    payload: {
      currentModalId: modalId,
      transactionHashes: data?.transactionHashes,
      airdropData: data?.airdropData,
    },
  }
}

export const closeModal = (): ModalAction => {
  return {
    type: ModalActionType.CLOSE_MODAL,
    payload: {
      currentModalId: ModalId.UNSET,
      transactionHashes: undefined,
      airdropData: undefined,
    },
  }
}
