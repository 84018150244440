import { alpha, Box, Theme } from '@mui/material'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { ScrollableBox } from '../../gloabalStyles'

export interface DataProps {
  textAlign?: 'left' | 'right' | 'center' | 'initial' | 'inherit'
  width?: string
  minWidth?: string
  maxWidth?: string
}
export const Data = styled.td<DataProps>`
  ${({ textAlign, theme, width, minWidth, maxWidth }) => css`
    background: ${alpha(theme.palette.primary.main, 0.2)};
    text-align: ${textAlign};
    ${width &&
    css`
      width: ${width};
    `}
    ${minWidth &&
    css`
      min-width: ${minWidth};
    `}
    ${maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

    padding: 8px 16px;
  `}
`

export const Row = styled.tr``
export const Body = styled.tbody``

const tableBorderRadius = ({
  theme,
}: {
  theme: Theme
}): FlattenSimpleInterpolation => css`
  border-radius: ${theme.borderRadius.sm};
`

export const Head = styled.thead`
  ${({ theme }) => css`
    ${Data} {
      border-bottom: 1px solid ${alpha(theme.palette.common.white, 0.6)};
    }
  `}
`

export interface ContainerProps {
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  stickyLastColumn?: boolean
  stickyHead?: boolean
}
export const Container = styled.table<ContainerProps>`
  ${({
    theme,
    width,
    maxWidth,
    height,
    maxHeight,
    stickyLastColumn,
    stickyHead,
  }) => css`
    width: ${width ? width : '100%'};
    max-width: ${maxWidth ? maxWidth : 'unset'};
    height: ${height ? height : 'unset'};
    max-height: ${maxHeight ? maxHeight : 'unset'};
    border-collapse: seperate;
    ${tableBorderRadius}
    border-spacing: 1px;

    ${stickyHead &&
    css`
      ${Head} {
        ${Data} {
          ${stickyHead &&
          css`
            position: sticky;
            top: 0;
            background: ${theme.palette.primary[800]};
            z-index: 10;
          `}
          ${stickyLastColumn &&
          css`
            &:last-of-type {
              position: sticky;
              top: 0;
              background: ${theme.palette.primary[800]};
              z-index: 12;
            }
          `}
        }
      }
    `}
    ${stickyLastColumn &&
    css`
      ${Data} {
        &:last-of-type {
          position: sticky;
          right: 0;
          background: ${theme.palette.primary[800]};
          z-index: 10;
        }
      }
    `}
    ${Row} {
      &.Table__row--active,
      &:focus-within {
        ${Data} {
          background: ${theme.palette.primary.main};
        }
      }
    }
  `}
`
export const StyledScrollableBox = styled(ScrollableBox)`
  ${tableBorderRadius}
`

export const Wrapper = styled(Box)`
  width: 100%;
`
