import { ChainId } from '../networks'

export class Contract<T> {
  readonly addresses: { [id in ChainId]?: `0x${string}` }
  readonly abi: T
  constructor({
    addresses,
    abi,
  }: {
    addresses: { [id in ChainId]?: `0x${string}` }
    abi: T
  }) {
    this.addresses = addresses
    this.abi = abi
  }
  get(chainId: ChainId) {
    const address = this.addresses[chainId]
    if (address) {
      return {
        address: address,
        abi: this.abi,
      }
    } else {
      throw new Error(`Please add ${chainId} address!`)
    }
  }
}
