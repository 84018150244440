import { BigNumber } from 'ethers'
import { readContracts } from 'wagmi'
type IMulticallHelperSCReturn = null | boolean | BigNumber
export type ICallbacks = ((value: IMulticallHelperSCReturn) => void)[]
type Writeable<T> = { -readonly [P in keyof T]: T[P] }
export type ICalls = Writeable<Parameters<typeof readContracts>[0]['contracts']>
export function executeCallBacks(
  values: unknown[],
  callbacks: ICallbacks,
): void {
  if (!values) return
  if (values.length === 0) return
  if (values.length !== callbacks.length) {
    console.error('Callbacks and values length is not equal')
    return
  }
  for (let i = 0; i < callbacks.length; i++) {
    const value = values[i]
    const callback = callbacks[i]
    if (value !== null) {
      callback(value as IMulticallHelperSCReturn)
    }
  }
}
