import { getCommifiedFormat } from '@hailstonelabs/big-number-utils/dist/src'
import { getTokenIconPath } from '.'
import AppTypography from '../../components/AppTypography/AppTypography'
import Table from '../../components/Table/Table'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { ChainId, NETWORKS } from '../../config/networks'
import { PRE_ATTACK_BLOCK, PRE_ATTACK_DATE } from '../../constants'
import { ExternalLink } from '../../gloabalStyles'
import { UserData } from '../../interfaces/uspCompensation'
import { localeDateFormat } from '../../utils/common'

export default function PreAttackTable({
  data,
}: {
  data: UserData['preAttack']
}) {
  if (!data) return <></>
  return (
    <Table
      style={{ fontSize: '14px' }}
      maxWidth="500px"
      HeaderComponent={
        <AppTypography variant="subtitle2" mb="4px">
          Pre-attack Net Value
          <br />
          <AppTypography transparent component="span" variant="caption">
            Snapshot:{' '}
            <ExternalLink
              href={
                NETWORKS[ChainId.AVALANCHE]?.blockExplorers?.default.url +
                '/block/' +
                PRE_ATTACK_BLOCK
              }
            >
              {localeDateFormat(PRE_ATTACK_DATE)}
            </ExternalLink>
          </AppTypography>
        </AppTypography>
      }
      FooterComponent={
        data.totalInUsd && (
          <AppTypography variant="body2" mt="4px">
            Total: ${getCommifiedFormat(data.totalInUsd.toString(), 2)}
          </AppTypography>
        )
      }
      scrollableTable
    >
      <Table.Body>
        {Object.entries(data.totalLpAmount).map(([tokenSymbol, value]) => (
          <Table.Row key={tokenSymbol}>
            <Table.Data>
              <AppTypography centerContent fontSize="inherit">
                <TokenIcon
                  margin="0 4px 0 0"
                  iconPath={getTokenIconPath(tokenSymbol)}
                />
                {tokenSymbol} deposited
              </AppTypography>
            </Table.Data>
            <Table.Data>
              <AppTypography centerContent fontSize="inherit">
                {getCommifiedFormat(value.toString(), 2)}&nbsp;
                <AppTypography transparent component="span" fontSize="inherit">
                  (${getCommifiedFormat(value.toString(), 2)})
                </AppTypography>
              </AppTypography>
            </Table.Data>
          </Table.Row>
        ))}
        {data.uspBalance !== undefined && (
          <Table.Row>
            <Table.Data>
              <AppTypography centerContent fontSize="inherit">
                <TokenIcon
                  margin="0 4px 0 0"
                  iconPath={getTokenIconPath('usp')}
                />
                USP balance
              </AppTypography>
            </Table.Data>
            <Table.Data>
              <AppTypography centerContent fontSize="inherit">
                {getCommifiedFormat(data.uspBalance.toString(), 2)}
                &nbsp;
                <AppTypography transparent component="span" fontSize="inherit">
                  ($
                  {getCommifiedFormat(data.uspBalance.toString(), 2)})
                </AppTypography>
              </AppTypography>
            </Table.Data>
          </Table.Row>
        )}
        {data.uspDebt !== undefined && (
          <Table.Row>
            <Table.Data>
              <AppTypography centerContent fontSize="inherit">
                <TokenIcon
                  margin="0 4px 0 0"
                  iconPath={getTokenIconPath('usp')}
                />
                USP debt
              </AppTypography>
            </Table.Data>
            <Table.Data>
              <AppTypography centerContent fontSize="inherit">
                {getCommifiedFormat(data.uspDebt.toString(), 2)}
                &nbsp;
                <AppTypography transparent component="span" fontSize="inherit">
                  ($
                  {getCommifiedFormat(data.uspDebt.toString(), 2)})
                </AppTypography>
              </AppTypography>
            </Table.Data>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}
