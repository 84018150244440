import { Box, Slider, Theme, Typography } from '@mui/material'
import styled, { css } from 'styled-components'
import AppButton from '../buttons/AppButton/AppButton'
import Input from '../Input/Input'
import TokenIcon from '../TokenIcon/TokenIcon'
export interface StyledInputProps {
  disabled?: boolean
  disableUnderline?: boolean
  noLeftPadding?: boolean
}
export const StyledInput = styled(Input)<StyledInputProps>`
  ${({ disabled, disableUnderline, noLeftPadding }: StyledInputProps) => css`
    margin: 5px 10px 5px 0;
    min-height: 0;
    border-radius: 10px;
    ${!disableUnderline &&
    !disabled &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 85px;
        height: 1px;
        background-color: #fff;
        bottom: -3px;
        right: 0;
      }
    `}
    .MuiInput-input {
      font-size: 20px;
    }
    .input__children-wrapper {
      margin: 0;
      padding-left: 0;
    }
    ${noLeftPadding &&
    css`
      .MuiInput-input {
        padding-left: 0;
      }
    `}
  `}
`

const swapDirectionIconCSS = css`
  display: flex !important;
  &:hover {
    opacity: 1;
  }
`
interface SwapDirectionIconProps {
  theme: Theme
  disabled?: boolean
}

export const SwapDirectionIconContainer = styled(Box)<SwapDirectionIconProps>`
  ${({ theme, disabled }: SwapDirectionIconProps) => css`
    margin: 0 auto;
    cursor: pointer;
    position: absolute;
    bottom: -38px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 999px;
    border: 2px solid ${theme.palette.primary[900]};
    background: ${theme.palette.primary[800]};
    width: 52px;
    height: 52px;
    z-index: 1;
    &:hover {
      opacity: 0.8;
    }
    svg {
      font-size: 44px;
    }
    &.swap-direction-button--mobile {
      display: none;
    }

    ${disabled &&
    css`
      ${swapDirectionIconCSS}
      background: none;
      border: none;
      pointer-events: none;
      bottom: -13px;
      svg {
        font-size: 40px;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px ) {
      display: none;

      &.swap-direction-button--mobile {
        ${swapDirectionIconCSS}
        position: relative;
        left: 0;
        bottom: 0;
        transform: unset;
        border: 0;
        width: 32px;
        height: 32px;
        svg {
          font-size: 24px;
        }
      }
    }
  `}
`

export const ActionButtonContainer = styled(Box)`
  display: flex;
  min-width: 65px;
  justify-content: center;
  align-items: center;
  padding: 5px;
`
export const StyledButton = styled(AppButton)`
  text-transform: uppercase;
  margin: 0;
  max-height: 36px;
  padding: 4px 12px;
  * {
    font-weight: 700;
  }
`

export const SelectTokenLabel = styled(Typography)`
  white-space: nowrap;
`

export const StyledSelectTokenButton = styled(Box)<{
  disabledhover?: string
  disabled?: boolean
}>`
  cursor: ${(props) => (props.disabledhover ? 'initial' : 'pointer')};
  border-radius: 5px;
  padding: 4px 8px;
  min-width: 180px;
  border: 1px solid #ffffff;
  .select-token-button__arrow-down {
    font-size: 1.5rem;
    margin: 0 0 0 auto;
  }

  ${(props) =>
    props.disabled &&
    css`
      border: 0;
      cursor: unset;
      .select-token-button__arrow-down {
        display: none;
      }
    `}
`

export const StyledTokenIcon = styled(TokenIcon)`
  margin-right: 10px;
`
export const TokenButtonsWrapper = styled(Box)`
  border-radius: 5px;
`
export const TokenInputContainer = styled(Box)`
  ${({ theme }: { theme: Theme }) => css`
    position: relative;
    border-radius: 10px;
    padding: 6px 0;
    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      padding: 4px 0;
    }
  `}
`
interface StyledInnerContainerProps {
  theme: Theme
}
export const StyledInnerContainer = styled(Box)<StyledInnerContainerProps>`
  ${({ theme }: StyledInnerContainerProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 10px;
    height: 60px;
    padding: 12px 16px;
    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      padding: 12px;
    }
  `}
`

interface StyledSliderProps {
  theme: Theme
  $railWidth?: string
}
export const StyledSlider = styled(Slider)<StyledSliderProps>`
  ${({ $railWidth }: StyledSliderProps) => css`
    margin: 16px 0;
    width: 98%;
    align-self: center;
    /* act as background color of the rail */
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      background: #0000006e;
      right: 0;
      top: 50%;
      height: 4px;
      border-radius: 999px;
    }
    .MuiSlider-rail {
      width: ${$railWidth ? $railWidth : '100%'};
    }
    &.Mui-disabled {
      opacity: 0.3;
      .MuiSlider-mark {
        background-color: none;
      }
    }
  `}
`
export const InputLabelInnerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  ${({ theme }: { theme: Theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      flex-direction: column;
    }
  `}
`

export const Label = styled.span`
  ${({ theme }: { theme: Theme }) => css`
    margin-right: 4px;
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      margin: 0;
    }
  `}
`
