import { Box, Theme } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)`
  bottom: 20px;
  left: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  z-index: 100;
  &:hover {
    opacity: 0.8;
  }
`

export const StyledLink = styled.a`
  ${({ theme }: { theme: Theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      display: none;
    }
  `}
`
