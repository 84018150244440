import { Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { ReactElement } from 'react'
import WalletIcon from '../../../assets/icons/wallet-icon.svg'
import { networkIconPaths } from '../../../assets/networks/index'
import NetworkIcon from '../../../components/icons/NetworkIcon/NetworkIcon'
import { ChainId } from '../../../config/networks'
import { useModal } from '../../../contexts/ModalContext'
import { useSnackbar } from '../../../contexts/SnackbarContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import { ModalId } from '../../../interfaces/Modal'
import ConnectToWalletModalContainer from '../ConnectToWalletModalContainer/ConnectToWalletModalContainer'
import {
  Container,
  NetworkName,
} from './ConnectToWalletButtonContainer.elements'

function ConnectToWalletButtonContainer(): ReactElement {
  const {
    modalDispatch,
    modalState,
    actions: { openModal },
  } = useModal()
  const { account, network } = useWeb3()
  const { showMessage } = useSnackbar()
  const handleWalletClick = async () => {
    /** @todo if an user has already connected to the wallet, we should show account info. */
    if (!account) {
      modalDispatch(openModal(ModalId.CONNECT_TO_WALLET))
      return
    }
    try {
      await navigator.clipboard.writeText(account || '')
      showMessage('Address copied to clipboard')
    } catch (err) {
      Sentry.setContext('event', {
        name: 'copy_address_to_clipboard',
        account: account,
      })
      Sentry.captureException(err)
      showMessage('Address unable to be copied', 'warning')
    }
  }

  return (
    <>
      <Container
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        bgcolor="primary.400"
        onClick={handleWalletClick}
        $hasAccount={!!account}
      >
        {account ? (
          <>
            {network?.name && (
              <>
                <NetworkIcon
                  iconPath={networkIconPaths[network.id as ChainId]}
                  margin="0 4px 0 0"
                />
                <NetworkName variant="caption">
                  {network.chainAlias}
                </NetworkName>
              </>
            )}
            <Typography variant="caption">
              {account.substring(0, 6)}...
              {account.substring(account.length - 4, account.length)}
            </Typography>
          </>
        ) : (
          <>
            <img
              src={WalletIcon}
              width="16px"
              height="16px"
              alt="wallet_icon"
              style={{ marginRight: '4px' }}
            />
            <Typography variant="caption">Connect Wallet</Typography>
          </>
        )}
      </Container>
      {modalState.currentModalId === ModalId.CONNECT_TO_WALLET && (
        <ConnectToWalletModalContainer isOpen />
      )}
    </>
  )
}

export default ConnectToWalletButtonContainer
