import { ChainId } from '../networks'
import { merkleDistributorAdaptorABI } from './abis'
import { Contract } from './Contract'

export const merkleDistributorAdaptor = new Contract<
  typeof merkleDistributorAdaptorABI
>({
  abi: merkleDistributorAdaptorABI,
  addresses: {
    [ChainId.FUJI]: '0x06918741f72a360d33ce78Bf487d05cd9A781AD3',
    [ChainId.AVALANCHE]: '0xAAF0Ad7dc1AeaFae265Cd2Df0462020Cb82aeA6A',
    [ChainId.METIS_GOERLI_TESTNET]:
      '0x8FD0b615a3554E5054c13B5Bad435C7Beb27af81',
    [ChainId.METIS_ANDROMEDA_MAINNET]:
      '0x9dC3E1132A4e590Db5d81AF186353cb4bA098105',
  },
})
