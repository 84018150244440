import React, {
  createContext,
  ReactElement,
  useContext,
  useReducer,
} from 'react'
import { ModalAction, ModalState } from '../interfaces/Modal'
import { closeModal, openModal } from '../states/modal/actions'
import modalReducer from '../states/modal/reducer'
import { modalInitialState } from '../states/modal/state'

interface ContextType {
  modalState: ModalState
  modalDispatch: React.Dispatch<ModalAction>
  actions: {
    openModal: typeof openModal
    closeModal: typeof closeModal
  }
}

const initialContextValue = {
  modalState: modalInitialState,
  modalDispatch: () => undefined,
  actions: {
    openModal: openModal,
    closeModal: closeModal,
  },
}
export const ModalContext = createContext<ContextType>(initialContextValue)

export const useModal = (): ContextType => {
  return useContext(ModalContext)
}
interface Props {
  children: React.ReactNode
}

export const ModalProvider = ({ children }: Props): ReactElement => {
  const [modalState, modalDispatch] = useReducer(
    modalReducer,
    modalInitialState,
  )

  return (
    <ModalContext.Provider
      value={{
        ...initialContextValue,
        modalState,
        modalDispatch,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
