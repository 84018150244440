import { Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { ReactElement } from 'react'
import PlatypusLightLogoMobile from '../../assets/companyLogos/platypus-light-mobile.svg'
import PlatypusLightLogo from '../../assets/companyLogos/platypus-light.svg'
import ConnectToWalletButtonContainer from '../ConnectToWallet/ConnectToWalletButtonContainer/ConnectToWalletButtonContainer'
import {
  CompanyLogo,
  Container,
  NavbarItem,
  Trailing,
} from './NarbarContainer.elements'
function NarbarContainer(): ReactElement {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md - 1),
  )
  return (
    <Container $bgColor={theme.palette.primary[800]}>
      <Toolbar>
        <CompanyLogo>
          {isSmallerThanMd ? (
            <img
              src={PlatypusLightLogoMobile}
              alt="Platypus logo"
              width="40px"
              height="40px"
            />
          ) : (
            <img src={PlatypusLightLogo} alt="Platypus logo" width="200px" />
          )}
        </CompanyLogo>
        <Trailing display="flex" flexDirection="row" alignItems="center">
          <NavbarItem>
            <ConnectToWalletButtonContainer />
          </NavbarItem>
        </Trailing>
      </Toolbar>
    </Container>
  )
}

export default NarbarContainer
