import * as Sentry from '@sentry/react'
import { BigNumber, constants, ContractTransaction, ethers } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useContract } from 'wagmi'
import { merkleDistributorAdaptorABI } from '../config/contracts/abis'
import { merkleDistributorAdaptor } from '../config/contracts/merkleDistributorAdaptor'
import { ChainId } from '../config/networks'
import { ThirdPartyAirdropId } from '../config/thirdPartyAirdrops'
import { AirdropData, useAirdropData } from '../contexts/AirdropContext'
import { useModal } from '../contexts/ModalContext'
import { useMulticallData } from '../contexts/MulticallDataContext'
import { useSnackbar } from '../contexts/SnackbarContext'
import { useWeb3 } from '../contexts/Web3Context'
import { EtherError } from '../error'
import { AirdropType } from '../interfaces/Airdrop'
import { AirdropModalData, ModalId } from '../interfaces/Modal'
import { isUserDeniedTransaction } from '../utils/contract'

export default function useMultiClaim({
  chainId,
  airdropsData,
  airdropType,
  thirdPartyAirdropId,
}: {
  chainId: ChainId
  airdropsData: AirdropData[]
  airdropType: AirdropType
  thirdPartyAirdropId?: ThirdPartyAirdropId
}) {
  const {
    actions: { openModal, closeModal },
    modalDispatch,
  } = useModal()
  const { account, signer } = useWeb3()
  const merkleDistributorAdaptorContract = useContract({
    ...merkleDistributorAdaptor.get(chainId),
    signerOrProvider: signer,
  })
  const { showMessage } = useSnackbar()
  const isAllClaimed = useMemo(() => {
    return airdropsData.every((data) => data.isClaimed)
  }, [airdropsData])
  const [disabled, setDisabled] = useState(isAllClaimed)
  const { handleMultiClaimAirdropSuccess } = useAirdropData()
  const { refetchMultiCallData } = useMulticallData()

  useEffect(() => {
    setDisabled(isAllClaimed)
  }, [isAllClaimed])

  const handleError = (error: EtherError, encodedFunctionData?: string) => {
    modalDispatch(closeModal())
    showMessage(error.reason || 'Transaction reverted.', 'warning')
    setDisabled(isAllClaimed)
    if (!isUserDeniedTransaction(error)) {
      Sentry.setContext('contract_call', {
        name: 'multiClaim',
        address: account,
        inputHexData: encodedFunctionData,
        contractAddress: merkleDistributorAdaptor.addresses[chainId],
      })
    }
    Sentry.captureException(error)
  }

  const handleSuccess = async (tx: ContractTransaction) => {
    modalDispatch(
      openModal(ModalId.TRANSACTION_SUBMITTED, {
        transactionHashes: [tx.hash],
      }),
    )
    // update airdrop third party card ui
    if (thirdPartyAirdropId) {
      handleMultiClaimAirdropSuccess(airdropType, thirdPartyAirdropId)
    }
    showMessage('Successfully claimed')
  }
  const handleMultiClaim = async (airdropModalData?: AirdropModalData[]) => {
    modalDispatch(
      openModal(ModalId.AIRDROP_CONFIRMATION, {
        airdropData: airdropModalData,
      }),
    )

    if (merkleDistributorAdaptorContract && account) {
      setDisabled(true)

      const addresses = airdropsData.map((data) => data.address)
      const indices = airdropsData.map((data) =>
        data.index !== undefined ? BigNumber.from(data.index) : constants.Zero,
      )
      const amounts = airdropsData.map((data) =>
        data.airdropAmountHexStr !== undefined
          ? BigNumber.from(data.airdropAmountHexStr)
          : constants.Zero,
      )
      const proofs = airdropsData.map((data) => data.proof as `0x${string}`[])

      const multiClaimFuncArg = [
        account,
        addresses,
        indices,
        amounts,
        proofs,
      ] as const

      try {
        const tx = await merkleDistributorAdaptorContract.multiClaim(
          ...multiClaimFuncArg,
        )
        await tx.wait()
        handleSuccess(tx)
        await refetchMultiCallData()
      } catch (error) {
        const merkleDistributorAdaptorInterface = new ethers.utils.Interface(
          merkleDistributorAdaptorABI,
        )
        const encodedFunctionData =
          merkleDistributorAdaptorInterface.encodeFunctionData(
            'multiClaim',
            multiClaimFuncArg,
          )

        handleError(error as EtherError, encodedFunctionData)
      }
    }
  }
  return {
    handleMultiClaim,
    disabled,
  }
}
