import { ChainId } from '../../config/networks'
import fuji_icon from './fuji.svg'
import metis_icon from './metis.svg'

export const networkIconPaths: { [id in ChainId]: string } = {
  [ChainId.AVALANCHE]: fuji_icon,
  [ChainId.FUJI]: fuji_icon,
  [ChainId.METIS_ANDROMEDA_MAINNET]: metis_icon,
  [ChainId.METIS_GOERLI_TESTNET]: metis_icon,
}
