import { Box, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { useModal } from '../../contexts/ModalContext'
import { useWeb3 } from '../../contexts/Web3Context'
import CheckCircleIcon from '../icons/CheckCircleIcon'
import Modal from '../Modal/Modal'
import { StyledAppButton } from './TransactionSubmittedModal.elements'

interface Props {
  children?: React.ReactNode
  isOpen: boolean
  title?: string | ReactElement
  subtitle?: string | ReactElement
}

function TransactionSubmittedModal({
  children,
  isOpen,
  title,
  subtitle,
}: Props): ReactElement {
  const {
    modalState,
    modalDispatch,
    actions: { closeModal },
  } = useModal()
  const { network } = useWeb3()
  // NETWORKS[ChainId.AVALANCHE]?.blockExplorers?.default.url
  // explorer url from network hook e.g. https://cchain.explorer.avax-test.network/
  const explorerUrl = network ? network.blockExplorers?.default.url : undefined
  const transactionUrls = explorerUrl
    ? (modalState.transactionHashes || []).map(
        (hash) => `${explorerUrl}/tx/${hash}`,
      )
    : []

  return (
    <Modal
      isOpen={isOpen}
      disableCloseBtn
      onClose={() => modalDispatch(closeModal())}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CheckCircleIcon margin="0 0 12px 0" />
        <Typography variant="h5">
          {title ? title : 'Transaction submitted'}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            style={{
              marginTop: '12px',
              textAlign: 'center',
              padding: '0 16px',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column" mt={2}>
        {transactionUrls.map((transactionUrl) => {
          return (
            <StyledAppButton
              key={transactionUrl}
              customVariant="neutral"
              onClick={() => {
                window.open(transactionUrl, '_blank')
              }}
              $small
            >
              View On {network?.name} Explorer
            </StyledAppButton>
          )
        })}
        {children}
      </Box>
    </Modal>
  )
}
export default TransactionSubmittedModal

interface AdditionalButtonProps {
  children: React.ReactNode
  onClick: () => void
}
const AdditionalButton = ({
  onClick,
  children,
}: AdditionalButtonProps): ReactElement => {
  return (
    <StyledAppButton onClick={() => onClick()} fullWidth>
      {children}
    </StyledAppButton>
  )
}

TransactionSubmittedModal.AdditionalButton = AdditionalButton
