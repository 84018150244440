/**
 *
 * @param {string} willingWaitingSecond
 * @returns {string} timestamp(now + willingWaitingSecond) in second
 */
export const getDeadline = (willingWaitingSecond: string): string => {
  return (new Date().getTime() / 1000 + Number(willingWaitingSecond)).toFixed(0)
}

/**
 *
 * @param {unknown} err error related to user call contract transaction
 * @returns {boolean} user is denied transaction or not
 */
export const isUserDeniedTransaction = (err: unknown): boolean => {
  if (
    (err as { message: string }).message ===
    'MetaMask Tx Signature: User denied transaction signature.'
  ) {
    return true
  } else {
    return false
  }
}
