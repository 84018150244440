import { TypographyProps } from '@mui/material'
import React, { MouseEventHandler, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import useBreakpoints from '../../hooks/useBreakpoints'
import {
  Animation,
  Container,
  Label,
  ServiceContainer,
} from './TabBar.elements'

interface Props {
  children: React.ReactNode
  className?: string
  // only allows 3 tabs at max for now
  numOfTabs: 2 | 3
  width?: string
  height?: string
  margin?: string
}
function TabBar({
  children,
  className,
  numOfTabs,
  width,
  height,
  margin,
}: Props): ReactElement {
  return (
    <Container
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      numOfTabs={numOfTabs}
      className={className}
      style={{ width, height, margin }}
    >
      {children}
      <Animation className="animation" />
    </Container>
  )
}

export default TabBar

interface TabProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>
  IconComponent?: React.ReactNode
  iconPath?: string
  iconWidth?: number
  label?: string
  to?: string
  activeLink?: boolean
  labelProps?: TypographyProps
  buttonComponent?: React.ReactNode
}
function Tab({
  onClick,
  to,
  IconComponent,
  iconPath,
  iconWidth,
  label,
  activeLink = false,
  labelProps,
  buttonComponent,
}: TabProps) {
  const { isTabletSm } = useBreakpoints()
  return (
    <Link
      to={to ? to : ''}
      onClick={onClick}
      className={activeLink ? 'active' : ''}
      style={{ userSelect: 'none', zIndex: 1 }}
      onDragStart={(e) => e.preventDefault()}
    >
      <ServiceContainer
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        {IconComponent && IconComponent}
        {iconPath && <img src={iconPath} alt={label} width={iconWidth} />}
        <Label variant={isTabletSm ? 'subtitle2' : 'h6'} {...labelProps}>
          {label}
        </Label>
        {buttonComponent && buttonComponent}
      </ServiceContainer>
    </Link>
  )
}

TabBar.Tab = React.memo(Tab)
