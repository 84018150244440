import { ReactElement, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import UnsupportedNetwork from './components/errors/UnsupportedNetwork/UnsupportedNetwork'
import MockAccountInput from './components/Input/MockAccountInput/MockAccountInput'
import routes from './config/routes'
import { useModal } from './contexts/ModalContext'
import { useWeb3 } from './contexts/Web3Context'
import { BUILD_TYPE } from './interfaces/common'
import { ModalId } from './interfaces/Modal'
import MainLayout from './layouts/MainLayout/MainLayout'
function Routes(): ReactElement {
  const {
    modalDispatch,
    actions: { openModal, closeModal },
  } = useModal()
  const { isSupported, account } = useWeb3()
  useEffect(() => {
    if (!account) {
      modalDispatch(openModal(ModalId.CONNECT_TO_WALLET))
    } else {
      modalDispatch(closeModal())
    }
  }, [account, modalDispatch, openModal, closeModal])
  return (
    <Router>
      <MainLayout>
        {account &&
          (isSupported === false ? (
            <UnsupportedNetwork />
          ) : (
            isSupported === true && (
              <>
                <Switch>
                  <Route
                    path={routes.AIRDROP.path}
                    component={routes.AIRDROP.Component}
                    exact
                  />
                  <Route
                    path={routes.USP_COMPENSATION.path}
                    component={routes.USP_COMPENSATION.Component}
                  />
                  <Redirect to={routes.AIRDROP.path} />
                </Switch>
              </>
            )
          ))}
        {process.env.REACT_APP_ENVIRONMENT !== BUILD_TYPE.PROD && (
          <MockAccountInput />
        )}
      </MainLayout>
    </Router>
  )
}

export default Routes
