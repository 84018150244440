import React, { ReactElement } from 'react'
import TransactionSubmittedModal from '../../components/TransactionSubmittedModal/TransactionSubmittedModal'
import AboutPlatypusButtonContainer from '../../containers/AboutPlatypusButtonContainer/AboutPlatypusButtonContainer'
import NarbarContainer from '../../containers/NavbarContainer/NarbarContainer'
import { useModal } from '../../contexts/ModalContext'
import { ModalId } from '../../interfaces/Modal'
import AirdropConfirmationModal from '../../pages/AirdropPage/AirdropConfirmationModal'
import { ChildrenContainer, Container, Overlay } from './MainLayout.elements'

interface Props {
  children: React.ReactNode
}

function MainLayout({ children }: Props): ReactElement {
  const { modalState } = useModal()
  return (
    <Container>
      <Overlay>
        <NarbarContainer />
        <ChildrenContainer>{children}</ChildrenContainer>
        <AboutPlatypusButtonContainer />
      </Overlay>
      {modalState.currentModalId === ModalId.TRANSACTION_SUBMITTED && (
        <TransactionSubmittedModal isOpen />
      )}
      {modalState.currentModalId === ModalId.AIRDROP_CONFIRMATION && (
        <AirdropConfirmationModal isOpen />
      )}
    </Container>
  )
}

export default MainLayout
