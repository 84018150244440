import {
  avalanche,
  avalancheFuji,
  Chain,
  metis,
  metisGoerli,
} from '@wagmi/core/chains'

export enum ChainId {
  FUJI = 43113,
  AVALANCHE = 43114,
  METIS_ANDROMEDA_MAINNET = 1088,
  METIS_GOERLI_TESTNET = 599,
}

// Change SUPPORTED_CHAINS for interface
export const SUPPORTED_CHAINS =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? [
        ChainId.FUJI,
        ChainId.AVALANCHE,
        ChainId.METIS_ANDROMEDA_MAINNET,
        ChainId.METIS_GOERLI_TESTNET,
      ]
    : [ChainId.AVALANCHE, ChainId.METIS_ANDROMEDA_MAINNET]

export interface ExtendedChain extends Chain {
  chainAlias: string
}

export const NETWORKS: {
  [id in ChainId]: ExtendedChain
} = {
  [ChainId.FUJI]: { ...avalancheFuji, chainAlias: 'FUJI' },

  [ChainId.AVALANCHE]: { ...avalanche, chainAlias: 'Mainnet' },

  [ChainId.METIS_ANDROMEDA_MAINNET]: { ...metis, chainAlias: 'Andromeda' },

  [ChainId.METIS_GOERLI_TESTNET]: { ...metisGoerli, chainAlias: 'Goerli' },
}
