import { Contract } from '@ethersproject/contracts'
import { useContract as useWagmiContract, useProvider, useSigner } from 'wagmi'
import {
  MerkleDistributorV1,
  MerkleDistributorV2,
} from '../../types/ethers-contracts'
import { ChainId, NETWORKS } from '../config/networks'
import MERKLE_DISTRIBUTOR_ABI_V1 from '../constants/deployment/abis/MerkleDistributorV1.json'
import MERKLE_DISTRIBUTOR_ABI_V2 from '../constants/deployment/abis/MerkleDistributorV2.json'
import { AirdropType } from '../interfaces/Airdrop'

/**
 * @param {string} address
 * @param {any} ABI
 * @param {boolean} readOnly whether it needs to call the contract
 * @returns Contract or null on errors
 */
function useContract(
  address: string | undefined,
  ABI: any,
  readOnly: boolean,
  chainId: ChainId,
): Contract | null {
  const provider = useProvider({ chainId: NETWORKS[chainId].id })
  const { data: signer } = useSigner({
    chainId: NETWORKS[chainId].id,
  })
  const contract = useWagmiContract({
    address,
    abi: ABI,
    signerOrProvider: readOnly ? provider : signer,
  })
  return contract
}

export function useMerkleDistributorContract(
  readOnly = true,
  airdropChainId: ChainId,
  address?: string,
  airdropType = AirdropType.OFFICIAL,
): MerkleDistributorV1 | MerkleDistributorV2 | null {
  const abi =
    airdropType === AirdropType.OFFICIAL
      ? MERKLE_DISTRIBUTOR_ABI_V1
      : MERKLE_DISTRIBUTOR_ABI_V2

  return useContract(address, abi, readOnly, airdropChainId) as
    | MerkleDistributorV1
    | MerkleDistributorV2
}
