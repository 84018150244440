import { TokenSymbol } from '../../../../config/contracts/Token/TokenSymbol'
import { ChainId } from '../../../../config/networks'
import { AirdropCampaign } from '../../../../interfaces/Airdrop'
import ANKRAVAX_POOL_ANKRAVAX_BALANCES from './all_pools_refund/ankrAvax_pool_ankrAVAX.json'
import ANKRAVAX_POOL_AVAX_BALANCES from './all_pools_refund/ankrAvax_pool_AVAX.json'
import ANKRETH_POOL_ANKRETH_BALANCES from './all_pools_refund/ankrEth_pool_ankrETH.json'
import ANKRETH_POOL_WETHE_BALANCES from './all_pools_refund/ankrEth_pool_WETH.e.json'
import AXLUSDC_POOL_AXLUSDC_BALANCES from './all_pools_refund/axlUsdc_pool_axlUSDC.json'
import AXLUSDC_POOL_USDC_BALANCES from './all_pools_refund/axlUsdc_pool_USDC.json'
import BTC_POOL_BTCB_BALANCES from './all_pools_refund/btc_pool_BTC.b.json'
import BTC_POOL_WBTCE_BALANCES from './all_pools_refund/btc_pool_WBTC.e.json'
import FRAX_POOL_FRAX_BALANCES from './all_pools_refund/frax_pool_FRAX.json'
import FRAX_POOL_USDC_BALANCES from './all_pools_refund/frax_pool_USDC.json'
import MIM_POOL_MIM_BALANCES from './all_pools_refund/mim_pool_MIM.json'
import MIM_POOL_USDC_BALANCES from './all_pools_refund/mim_pool_USDC.json'
import MONEY_POOL_MONEY_BALANCES from './all_pools_refund/money_pool_MONEY.json'
import MONEY_POOL_USDC_BALANCES from './all_pools_refund/money_pool_USDC.json'
import NEW_MAIN_POOL_DAIE_BALANCES from './all_pools_refund/new_main_pool_DAI.e.json'
import NEW_MAIN_POOL_USDCE_BALANCES from './all_pools_refund/new_main_pool_USDC.e.json'
import NEW_MAIN_POOL_USDC_BALANCES from './all_pools_refund/new_main_pool_USDC.json'
import NEW_MAIN_POOL_USDTE_BALANCES from './all_pools_refund/new_main_pool_USDT.e.json'
import NEW_MAIN_POOL_USDT_BALANCES from './all_pools_refund/new_main_pool_USDT.json'
import SAVAX_POOL_AVAX_BALANCES from './all_pools_refund/sAvax_pool_AVAX.json'
import SAVAX_POOL_SAVAX_BALANCES from './all_pools_refund/sAvax_pool_sAVAX.json'
import SAVAX_UNMIGRATE_POOL_AVAX_BALANCES from './all_pools_refund/sAvax_unmigrate_pool_AVAX.json'
import SAVAX_UNMIGRATE_POOL_SAVAX_BALANCES from './all_pools_refund/sAvax_unmigrate_pool_sAVAX.json'
import YUSD_POOL_USDC_BALANCES from './all_pools_refund/yusd_pool_USDC.json'
import YUSD_POOL_YUSD_BALANCES from './all_pools_refund/yusd_pool_YUSD.json'
import YYAVAX_POOL_AVAX_BALANCES from './all_pools_refund/yyAvax_pool_AVAX.json'
import YYAVAX_POOL_YYAVAX_BALANCES from './all_pools_refund/yyAvax_pool_yyAVAX.json'
import MAINNET_ALPHA_LP_BALANCES from './mainnet_alpha_lp.json'
import MAINNET_ALPHA_SWAP_BALANCES from './mainnet_alpha_swap.json'
import MAINNET_AMA_BALANCES from './mainnet_ama.json'
import MAINNET_GLEAM_BALANCES from './mainnet_gleam.json'
import MAINNET_TESTNET_BUG_BALANCES from './mainnet_testnet_bug.json'
import MAINNET_USP_COMPENSATION_BUSD_BALANCES from './usp_compensation/BUSD.json'
import MAINNET_USP_COMPENSATION_DAIE_BALANCES from './usp_compensation/DAIe.json'
import MAINNET_USP_COMPENSATION_USDC_BALANCES from './usp_compensation/USDC.json'
import MAINNET_USP_COMPENSATION_USDCE_BALANCES from './usp_compensation/USDCe.json'
import MAINNET_USP_COMPENSATION_USDT_BALANCES from './usp_compensation/USDT.json'
import MAINNET_USP_COMPENSATION_USDTE_BALANCES from './usp_compensation/USDTe.json'
import MAINNET_USP_COMPENSATION_02_BTCb_BALANCES from './usp_compensation_02/BTC.b.json'
import MAINNET_USP_COMPENSATION_02_BUSD_BALANCES from './usp_compensation_02/BUSD.json'
import MAINNET_USP_COMPENSATION_02_DAIe_BALANCES from './usp_compensation_02/DAI.e.json'
import MAINNET_USP_COMPENSATION_02_sAVAX_BALANCES from './usp_compensation_02/sAVAX.json'
import MAINNET_USP_COMPENSATION_02_USDC_BALANCES from './usp_compensation_02/USDC.json'
import MAINNET_USP_COMPENSATION_02_USDTE_BALANCES from './usp_compensation_02/USDT.e.json'
import MAINNET_USP_COMPENSATION_02_USDT_BALANCES from './usp_compensation_02/USDT.json'
import MAINNET_USP_COMPENSATION_02_yyAVAX_BALANCES from './usp_compensation_02/yyAVAX.json'
import MAINNET_USP_COMPENSATION_03_DAIe_BALANCES from './usp_compensation_03_aave/DAIe.json'
import MAINNET_USP_COMPENSATION_03_USDC_BALANCES from './usp_compensation_03_aave/USDC.json'
import MAINNET_USP_COMPENSATION_03_USDCe_BALANCES from './usp_compensation_03_aave/USDCe.json'
import MAINNET_USP_COMPENSATION_03_USDT_BALANCES from './usp_compensation_03_aave/USDT.json'
import MAINNET_USP_COMPENSATION_03_USDTe_BALANCES from './usp_compensation_03_aave/USDTe.json'

export const MAINNET_USP_COMPENSATION_03_DAIe: AirdropCampaign = {
  name: 'USP Compensation DAI.e',
  description: '',
  address: '0xfbAb4DB844a19222150BDCF6d98D749812EFB46f',
  balances: MAINNET_USP_COMPENSATION_03_DAIe_BALANCES,
  startDate: new Date('2024-03-15T18:00:00+08:00'),
  tokenSymbol: TokenSymbol.DAIe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 3,
}

export const MAINNET_USP_COMPENSATION_03_USDC: AirdropCampaign = {
  name: 'USP Compensation USDC',
  description: '',
  address: '0xb044085c0088b47Ab0aa87d269F171367cBfBdC9',
  balances: MAINNET_USP_COMPENSATION_03_USDC_BALANCES,
  startDate: new Date('2024-03-15T18:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 3,
}

export const MAINNET_USP_COMPENSATION_03_USDCe: AirdropCampaign = {
  name: 'USP Compensation USDC.e',
  description: '',
  address: '0xc915A5e27d04A1d666F75f69846555bC7C3eD611',
  balances: MAINNET_USP_COMPENSATION_03_USDCe_BALANCES,
  startDate: new Date('2024-03-15T18:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDCe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 3,
}

export const MAINNET_USP_COMPENSATION_03_USDT: AirdropCampaign = {
  name: 'USP Compensation USDT',
  description: '',
  address: '0x5c1195a4389C7162d16bF0813B9368E02a4BAAE7',
  balances: MAINNET_USP_COMPENSATION_03_USDT_BALANCES,
  startDate: new Date('2024-03-15T18:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDT,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 3,
}

export const MAINNET_USP_COMPENSATION_03_USDTe: AirdropCampaign = {
  name: 'USP Compensation USDT.e',
  description: '',
  address: '0x6BD1055450807413DE36a351510F58f112948b4e',
  balances: MAINNET_USP_COMPENSATION_03_USDTe_BALANCES,
  startDate: new Date('2024-03-15T18:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDTe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 3,
}

export const MAINNET_USP_COMPENSATION_02_BTCb: AirdropCampaign = {
  name: 'USP Compensation BTC.b',
  description: '',
  address: '0x5580C9eEE4815594f6F67F7020EE55e81f65BeA8',
  balances: MAINNET_USP_COMPENSATION_02_BTCb_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.BTCb,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_BUSD: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0xa5B36330Fa1f340209D80378fF8f4C6B654A3Ba3',
  balances: MAINNET_USP_COMPENSATION_02_BUSD_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.BUSD,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_DAIe: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0x22fC4f8BD1D89047A9ffFF4687A3761a78edBbD2',
  balances: MAINNET_USP_COMPENSATION_02_DAIe_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.DAIe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_sAVAX: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0x97283B2Ee0d4c8DB5bBaEf9418d2D5D5bD6F9afa',
  balances: MAINNET_USP_COMPENSATION_02_sAVAX_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.sAVAX,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_USDC: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0xCDa5544D19b3c4391ECE44116CC06b857e8F8bbc',
  balances: MAINNET_USP_COMPENSATION_02_USDC_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_USDT: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0x23Bf82c6a8D8DA8eD25B50A12e6532A77401B4fA',
  balances: MAINNET_USP_COMPENSATION_02_USDT_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDT,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_USDTE: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0x572A62e5De7DD7413B0052De43e7b41470609EBa',
  balances: MAINNET_USP_COMPENSATION_02_USDTE_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDTe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}
export const MAINNET_USP_COMPENSATION_02_yyAVAX: AirdropCampaign = {
  name: 'USP Compensation',
  description: '',
  address: '0x7Ff962Fc5c9D45e697Da2350763857B7891D4FC9',
  balances: MAINNET_USP_COMPENSATION_02_yyAVAX_BALANCES,
  startDate: new Date('2023-09-26T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.yyAVAX,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 2,
}

export const MAINNET_USP_COMPENSATION_BUSD: AirdropCampaign = {
  name: 'USP Compensation BUSD',
  description: '',
  address: '0xD02Dfab3c7640460871928a363E20607a7EDE956',
  balances: MAINNET_USP_COMPENSATION_BUSD_BALANCES,
  startDate: new Date('2023-03-13T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.BUSD,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 1,
}

export const MAINNET_USP_COMPENSATION_DAIE: AirdropCampaign = {
  name: 'USP Compensation DAI.e',
  description: '',
  address: '0xf09A403CC17D87eC941e8792F1f9A7D8B354Af8d',
  balances: MAINNET_USP_COMPENSATION_DAIE_BALANCES,
  startDate: new Date('2023-03-13T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.DAIe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 1,
}

export const MAINNET_USP_COMPENSATION_USDC: AirdropCampaign = {
  name: 'USP Compensation USDC',
  description: '',
  address: '0x27E886771cF9fa3FeCB67949C3619c159C35Af0d',
  balances: MAINNET_USP_COMPENSATION_USDC_BALANCES,
  startDate: new Date('2023-03-13T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 1,
}

export const MAINNET_USP_COMPENSATION_USDCE: AirdropCampaign = {
  name: 'USP Compensation USDC.e',
  description: '',
  address: '0x0f760a102Ae5D8ECE0b863C640A585F081db571b',
  balances: MAINNET_USP_COMPENSATION_USDCE_BALANCES,
  startDate: new Date('2023-03-13T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDCe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 1,
}

export const MAINNET_USP_COMPENSATION_USDT: AirdropCampaign = {
  name: 'USP Compensation USDT',
  description: '',
  address: '0xd54576c292737D502Fd141B7Ab84809F0f00f4ba',
  balances: MAINNET_USP_COMPENSATION_USDT_BALANCES,
  startDate: new Date('2023-03-13T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDT,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 1,
}

export const MAINNET_USP_COMPENSATION_USDTE: AirdropCampaign = {
  name: 'USP Compensation USDT.e',
  description: '',
  address: '0x1866111F1054891d537da3e16Eb2C90b961728f0',
  balances: MAINNET_USP_COMPENSATION_USDTE_BALANCES,
  startDate: new Date('2023-03-13T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDTe,
  chainId: ChainId.AVALANCHE,
  isUspCompensation: true,
  uspCompensationId: 1,
}
const MAINNET_AMA: AirdropCampaign = {
  name: 'AMA Participants',
  description: '',
  address: '0xfE004b5c81be9fBD5408791AFa0162Df1C755D15',
  balances: MAINNET_AMA_BALANCES,
  startDate: new Date('2021-12-31T13:00:00+08:00'),
  tokenSymbol: TokenSymbol.PTP,
  chainId: ChainId.AVALANCHE,
  isMerkleDistributorV1: true,
}

const MAINNET_GLEAM: AirdropCampaign = {
  name: 'Gleam Winners',
  description: '',
  address: '0xCab2D81Bf308Dbe2BcF177F0aAaFeDb5Ff59d3f3',
  balances: MAINNET_GLEAM_BALANCES,
  startDate: new Date('2021-12-31T13:00:00+08:00'),
  tokenSymbol: TokenSymbol.PTP,
  chainId: ChainId.AVALANCHE,
  isMerkleDistributorV1: true,
}

const MAINNET_TESTNET_BUG: AirdropCampaign = {
  name: 'Testnet Bug Bounty',
  description: '',
  address: '0xd8200293B2fb6AB2a300419E5ce68AfFbB5fBdA4',
  balances: MAINNET_TESTNET_BUG_BALANCES,
  startDate: new Date('2021-12-31T13:00:00+08:00'),
  tokenSymbol: TokenSymbol.PTP,
  chainId: ChainId.AVALANCHE,
  isMerkleDistributorV1: true,
}

const MAINNET_ALPHA_LP: AirdropCampaign = {
  name: 'Alpha stage LP reward',
  description: '',
  address: '0x0C3B5bBB5609E9dA33accF0E054A34da608E549d',
  balances: MAINNET_ALPHA_LP_BALANCES,
  startDate: new Date('2022-01-21T15:00:00+08:00'),
  tokenSymbol: TokenSymbol.PTP,
  chainId: ChainId.AVALANCHE,
  isMerkleDistributorV1: true,
}

const MAINNET_ALPHA_SWAP: AirdropCampaign = {
  name: 'Alpha stage swapping reward',
  description: '',
  address: '0xB928F0d41422CcC246C617b1fF6AA1077E05bCC9',
  balances: MAINNET_ALPHA_SWAP_BALANCES,
  startDate: new Date('2022-01-21T15:00:00+08:00'),
  tokenSymbol: TokenSymbol.PTP,
  chainId: ChainId.AVALANCHE,
  isMerkleDistributorV1: true,
}

export const ANKRAVAX_POOL_AVAX: AirdropCampaign = {
  name: 'Refund ankrAVAX Pool AVAX',
  description: '',
  address: '0xF5fF25010B79f5deE8AfDfC18eb31ee95302895c',
  balances: ANKRAVAX_POOL_AVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.WAVAX,
  chainId: ChainId.AVALANCHE,
}
export const ANKRAVAX_POOL_ANKRAVAX: AirdropCampaign = {
  name: 'Refund ankrAVAX Pool ankrAVAX',
  description: '',
  address: '0xF1daa107882Be24b274C6654A701D272Eb2f3207',
  balances: ANKRAVAX_POOL_ANKRAVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.ANKRAVAX,
  chainId: ChainId.AVALANCHE,
}
export const ANKRETH_POOL_WETHE: AirdropCampaign = {
  name: 'Refund ankrETH Pool WETH.e',
  description: '',
  address: '0xb985D6A515468FC26DADe07a57432190Ac1de85d',
  balances: ANKRETH_POOL_WETHE_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.WETHe,
  chainId: ChainId.AVALANCHE,
}
export const ANKRETH_POOL_ANKRETH: AirdropCampaign = {
  name: 'Refund ankrETH Pool ankrETH',
  description: '',
  address: '0xAD502E66f4aFe2B8295fDCb9A9Aa2D7C04795942',
  balances: ANKRETH_POOL_ANKRETH_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.ANKRETH,
  chainId: ChainId.AVALANCHE,
}
export const AXLUSDC_POOL_USDC: AirdropCampaign = {
  name: 'Refund axlUSDC Pool USDC',
  description: '',
  address: '0x3B2Dd8D63F16e4A06272A421d46EB7C66221da76',
  balances: AXLUSDC_POOL_USDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
}
export const AXLUSDC_POOL_AXLUSDC: AirdropCampaign = {
  name: 'Refund axlUSDC Pool axlUSDC',
  description: '',
  address: '0x175D02eF1aEB72B728C21A4e52d7791535a3d399',
  balances: AXLUSDC_POOL_AXLUSDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.AXLUSDC,
  chainId: ChainId.AVALANCHE,
}
export const BTC_POOL_BTCB: AirdropCampaign = {
  name: 'Refund BTC Pool BTC.b',
  description: '',
  address: '0xC84EaBd9867dDBC4152cb0175Ef71F49A179Fa07',
  balances: BTC_POOL_BTCB_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.BTCb,
  chainId: ChainId.AVALANCHE,
}
export const BTC_POOL_WBTCE: AirdropCampaign = {
  name: 'Refund BTC Pool WBTC.e',
  description: '',
  address: '0x8746152BBcCCF3E8451Ea61FA5d6227097576b68',
  balances: BTC_POOL_WBTCE_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.WBTCe,
  chainId: ChainId.AVALANCHE,
}
export const FRAX_POOL_FRAX: AirdropCampaign = {
  name: 'Refund FRAX Pool FRAX',
  description: '',
  address: '0xa949Fe3Bc8996393a8D19fda6656E711d48b10cd',
  balances: FRAX_POOL_FRAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.FRAX,
  chainId: ChainId.AVALANCHE,
}
export const FRAX_POOL_USDC: AirdropCampaign = {
  name: 'Refund FRAX Pool USDC',
  description: '',
  address: '0x419d991DFc60c99b413B010808f07852e28ACE79',
  balances: FRAX_POOL_USDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
}
export const MIM_POOL_MIM: AirdropCampaign = {
  name: 'Refund MIM Pool MIM',
  description: '',
  address: '0x6FCa50D9A9c5Cbaf6156e606acC028D7256A4023',
  balances: MIM_POOL_MIM_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.MIM,
  chainId: ChainId.AVALANCHE,
}
export const MIM_POOL_USDC: AirdropCampaign = {
  name: 'Refund MIM Pool USDC',
  description: '',
  address: '0x0bbC88A83D0EE0eE9B35a64E1C0de574c81e230E',
  balances: MIM_POOL_USDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
}
export const MONEY_POOL_MONEY: AirdropCampaign = {
  name: 'Refund MONEY Pool MONEY',
  description: '',
  address: '0xB03217cCfB1D60918cF744ccA3CB02D67c6a5C2e',
  balances: MONEY_POOL_MONEY_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.MONEY,
  chainId: ChainId.AVALANCHE,
}
export const MONEY_POOL_USDC: AirdropCampaign = {
  name: 'Refund MONEY Pool USDC',
  description: '',
  address: '0x341606F35bF4b2d3666AD1B12CdAA0829CF9aD1A',
  balances: MONEY_POOL_USDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
}
export const NEW_MAIN_POOL_DAIE: AirdropCampaign = {
  name: 'Refund Main Pool DAI.e',
  description: '',
  address: '0x0EBc5914424050f7ffb9676E3a4d5a9280672994',
  balances: NEW_MAIN_POOL_DAIE_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.DAIe,
  chainId: ChainId.AVALANCHE,
}
export const NEW_MAIN_POOL_USDCE: AirdropCampaign = {
  name: 'Refund Main Pool USDC.e',
  description: '',
  address: '0x825E78E8d40d1C00828af37932f430B5D7cBA905',
  balances: NEW_MAIN_POOL_USDCE_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDCe,
  chainId: ChainId.AVALANCHE,
}
export const NEW_MAIN_POOL_USDC: AirdropCampaign = {
  name: 'Refund Main Pool USDC',
  description: '',
  address: '0xAfb5D5E74f413B41DABB34440e8E5869Ed0C7f07',
  balances: NEW_MAIN_POOL_USDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
}
export const NEW_MAIN_POOL_USDTE: AirdropCampaign = {
  name: 'Refund Main Pool USDT.e',
  description: '',
  address: '0x482eDdC39113ef2954a5a56Dc85B9E3D25784242',
  balances: NEW_MAIN_POOL_USDTE_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDTe,
  chainId: ChainId.AVALANCHE,
}
export const NEW_MAIN_POOL_USDT: AirdropCampaign = {
  name: 'Refund Main Pool USDT',
  description: '',
  address: '0x339CB5Ab0Dd7c7E0a9d4aF768742105669a144E1',
  balances: NEW_MAIN_POOL_USDT_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDT,
  chainId: ChainId.AVALANCHE,
}
export const SAVAX_POOL_AVAX: AirdropCampaign = {
  name: 'Refund sAVAX Pool AVAX',
  description: '',
  address: '0x8d08F4aE06d15b14f2682Bd7a692f3e46E4E8Ed8',
  balances: SAVAX_POOL_AVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.WAVAX,
  chainId: ChainId.AVALANCHE,
}
export const SAVAX_POOL_SAVAX: AirdropCampaign = {
  name: 'Refund sAVAX Pool sAVAX',
  description: '',
  address: '0x615400906B42E0eD9b8A75B758A191D08C485437',
  balances: SAVAX_POOL_SAVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.sAVAX,
  chainId: ChainId.AVALANCHE,
}
export const SAVAX_UNMIGRATE_POOL_AVAX: AirdropCampaign = {
  name: 'Refund sAVAX Pool AVAX (unmigrated)',
  description: '',
  address: '0xd18eF70eA92c13Da0Bc7DaB294D5E11DB6E7528a',
  balances: SAVAX_UNMIGRATE_POOL_AVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.WAVAX,
  chainId: ChainId.AVALANCHE,
}
export const SAVAX_UNMIGRATE_POOL_SAVAX: AirdropCampaign = {
  name: 'Refund sAVAX Pool sAVAX (unmigrated)',
  description: '',
  address: '0x5fEc4551B39D40Ca60E2930F904dE2BD6ca5F86d',
  balances: SAVAX_UNMIGRATE_POOL_SAVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.sAVAX,
  chainId: ChainId.AVALANCHE,
}
export const YUSD_POOL_YUSD: AirdropCampaign = {
  name: 'Refund YUSD Pool YUSD',
  description: '',
  address: '0x562F11eD8b8eb0D6C7D714B2E06012205B20be40',
  balances: YUSD_POOL_YUSD_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.YUSD,
  chainId: ChainId.AVALANCHE,
}
export const YUSD_POOL_USDC: AirdropCampaign = {
  name: 'Refund YUSD Pool USDC',
  description: '',
  address: '0x87F5517015C4541542DbE3fF69d8adcCB71173d8',
  balances: YUSD_POOL_USDC_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.USDC,
  chainId: ChainId.AVALANCHE,
}
export const YYAVAX_POOL_AVAX: AirdropCampaign = {
  name: 'Refund yyAVAX Pool AVAX',
  description: '',
  address: '0xd8200123DB2aa4065FE39d7E60c70a212FC66A35',
  balances: YYAVAX_POOL_AVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.WAVAX,
  chainId: ChainId.AVALANCHE,
}
export const YYAVAX_POOL_YYAVAX: AirdropCampaign = {
  name: 'Refund yyAVAX Pool yyAVAX',
  description: '',
  address: '0xd2612791be3f2E9374FD2aB583445d50F1fA4b9e',
  balances: YYAVAX_POOL_YYAVAX_BALANCES,
  startDate: new Date('2023-11-06T17:00:00+08:00'),
  tokenSymbol: TokenSymbol.yyAVAX,
  chainId: ChainId.AVALANCHE,
}
export const MAINNET_AIRDROP_CAMPAIGNS = [
  ANKRAVAX_POOL_ANKRAVAX,
  ANKRAVAX_POOL_AVAX,
  ANKRETH_POOL_ANKRETH,
  ANKRETH_POOL_WETHE,
  AXLUSDC_POOL_AXLUSDC,
  AXLUSDC_POOL_USDC,
  BTC_POOL_BTCB,
  BTC_POOL_WBTCE,
  FRAX_POOL_FRAX,
  FRAX_POOL_USDC,
  MIM_POOL_MIM,
  MIM_POOL_USDC,
  MONEY_POOL_MONEY,
  MONEY_POOL_USDC,
  NEW_MAIN_POOL_DAIE,
  NEW_MAIN_POOL_USDCE,
  NEW_MAIN_POOL_USDC,
  NEW_MAIN_POOL_USDTE,
  NEW_MAIN_POOL_USDT,
  SAVAX_POOL_AVAX,
  SAVAX_POOL_SAVAX,
  SAVAX_UNMIGRATE_POOL_AVAX,
  SAVAX_UNMIGRATE_POOL_SAVAX,
  YUSD_POOL_USDC,
  YUSD_POOL_YUSD,
  YYAVAX_POOL_AVAX,
  YYAVAX_POOL_YYAVAX,
  MAINNET_ALPHA_LP,
  MAINNET_ALPHA_SWAP,
  MAINNET_GLEAM,
  MAINNET_TESTNET_BUG,
  MAINNET_AMA,
  MAINNET_USP_COMPENSATION_03_DAIe,
  MAINNET_USP_COMPENSATION_03_USDC,
  MAINNET_USP_COMPENSATION_03_USDCe,
  MAINNET_USP_COMPENSATION_03_USDT,
  MAINNET_USP_COMPENSATION_03_USDTe,
  MAINNET_USP_COMPENSATION_02_BTCb,
  MAINNET_USP_COMPENSATION_02_BUSD,
  MAINNET_USP_COMPENSATION_02_DAIe,
  MAINNET_USP_COMPENSATION_02_sAVAX,
  MAINNET_USP_COMPENSATION_02_USDC,
  MAINNET_USP_COMPENSATION_02_USDT,
  MAINNET_USP_COMPENSATION_02_USDTE,
  MAINNET_USP_COMPENSATION_02_yyAVAX,
  MAINNET_USP_COMPENSATION_BUSD,
  MAINNET_USP_COMPENSATION_DAIE,
  MAINNET_USP_COMPENSATION_USDC,
  MAINNET_USP_COMPENSATION_USDCE,
  MAINNET_USP_COMPENSATION_USDT,
  MAINNET_USP_COMPENSATION_USDTE,
]
