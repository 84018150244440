import { Box, Tooltip } from '@mui/material'
import styled, { css } from 'styled-components'

export const Container = styled(Box)<{ pad?: string; padBottom?: string }>`
  padding: ${(props) =>
    props.pad
      ? props.pad
      : `8px 0 ${props.padBottom ? props.padBottom : '16px'}`};
`
interface ItemContainerProps {
  $fullWidth?: boolean
}
export const ItemContainer = styled(Box)<ItemContainerProps>`
  ${({ $fullWidth }: ItemContainerProps) => css`
    ${$fullWidth &&
    css`
      justify-content: flex-start;
      width: 100%;
    `}
  `}
`

export const ToolTipContainer = styled(Tooltip)<{ iconsize: string }>`
  font-size: ${(props) => props.iconsize};
  margin: 0 5px;
`

export const NonSelectedSpan = styled.span`
  all: unset;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`
