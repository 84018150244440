import React from 'react'
import { AirdropPage, UspCompensationPage } from '.././pages'
enum Routes {
  AIRDROP = 'AIRDROP',
  USP_COMPENSATION = 'USP_COMPENSATION',
}

type RouteType = {
  [key in Routes]: {
    path: string
    name: string
    Component: React.FC
  }
}
const routes: RouteType = {
  [Routes.AIRDROP]: {
    path: '/',
    name: 'Airdrop',
    Component: AirdropPage,
  },
  [Routes.USP_COMPENSATION]: {
    path: '/usp-compensation',
    name: 'USP compensation',
    Component: UspCompensationPage,
  },
}

export default routes
