import { useEffect, useRef } from 'react'
import { BLOCK_TIME } from '../constants/index'

/** Source: https://github.com/austintgriffith/eth-hooks/blob/master/src/Poller.ts */

export const usePoller = (fn: () => void, delay = BLOCK_TIME): void => {
  const savedCallback = useRef<() => void>()

  // Remember the latest fn.
  useEffect((): void => {
    savedCallback.current = fn
  }, [fn])

  // Set up the interval.
  useEffect((): void | (() => void) => {
    function tick() {
      if (savedCallback.current) savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])

  // run at start too
  useEffect(() => {
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
