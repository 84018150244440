export enum TokenSymbol {
  USDT = 'USDT',
  USDC = 'USDC',
  PTP = 'PTP',
  MORE = 'MORE',
  ANKRAVAX = 'ankrAVAX',
  ANKRETH = 'ankrETH',
  WAVAX = 'WAVAX',
  sAVAX = 'sAVAX',
  yyAVAX = 'yyAVAX',
  QI = 'QI',
  YETI = 'YETI',
  HUM = 'HUM',
  BUSD = 'BUSD',
  USDTe = 'USDT.e',
  USDCe = 'USDC.e',
  DAIe = 'DAI.e',
  BTCb = 'BTC.b',
  YUSD = 'YUSD',
  MONEY = 'MONEY',
  MIM = 'MIM',
  FRAX = 'FRAX',
  WBTCe = 'WBTC.e',
  WETHe = 'WETH.e',
  AXLUSDC = 'axlUSDC',
}
