import { TokenSymbol } from '../contracts/Token/TokenSymbol'
import { ChainId } from '../networks'

import { ThirdPartyAirdrops } from './thirdPartyAirdrops'

export enum ThirdPartyAirdropId {
  HUMMUS_MAINNET = 'hummus_mainnet',
  HUMMUS_TESTNET = 'hummus_testnet',
}

export const THIRD_PARTY_AIRDROPS: {
  [id in ThirdPartyAirdropId]: ThirdPartyAirdrops
} = {
  [ThirdPartyAirdropId.HUMMUS_MAINNET]: new ThirdPartyAirdrops(
    ThirdPartyAirdropId.HUMMUS_MAINNET,
    'HUMMUS Airdrop',
    '',
    ChainId.METIS_ANDROMEDA_MAINNET,
    TokenSymbol.HUM,
  ),
  [ThirdPartyAirdropId.HUMMUS_TESTNET]: new ThirdPartyAirdrops(
    ThirdPartyAirdropId.HUMMUS_TESTNET,
    'HUMMUS Testnet Airdrop',
    '',
    ChainId.METIS_GOERLI_TESTNET,
    TokenSymbol.HUM,
  ),
}
