import CloseIcon from '@mui/icons-material/Close'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import React, { ReactElement } from 'react'
import {
  CloseButton,
  Header,
  ModalBody,
  ModalContainer,
  TopbarConatiner,
} from './Modal.elements'
interface Props {
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode | undefined
  disableTopBar?: boolean | undefined
  disableCloseBtn?: boolean | undefined
  topBarLabel?: string | ReactElement
  width?: string | undefined
  maxWidth?: string | undefined
  className?: string | undefined
}

function Modal({
  isOpen = false,
  onClose,
  children,
  disableTopBar,
  topBarLabel = '',
  width = '100%',
  maxWidth = '500px',
  className,
  disableCloseBtn,
}: Props): ReactElement {
  const handleClose = () => {
    onClose()
  }
  return (
    <ModalContainer
      className={className}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableEnforceFocus
      disableAutoFocus
      BackdropProps={{
        timeout: 200,
      }}
    >
      <Fade in={isOpen}>
        <ModalBody width={width} maxWidth={maxWidth} className="modal__body">
          {!disableTopBar && (
            <Topbar
              onClose={onClose}
              leadingLabel={topBarLabel}
              disableCloseBtn={disableCloseBtn}
            />
          )}
          {children}
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

export default Modal
interface TopbarProps {
  leadingLabel?: string | ReactElement
  onClose: () => void
  disableCloseBtn?: boolean | undefined
}
const Topbar = ({
  leadingLabel,
  onClose,
  disableCloseBtn,
}: TopbarProps): ReactElement => {
  return (
    <TopbarConatiner
      display="flex"
      flexDirection="row"
      justifyContent={disableCloseBtn ? 'center' : 'space-between'}
      alignItems="center"
    >
      <Header variant="h6">{leadingLabel}</Header>
      {!disableCloseBtn && (
        <CloseButton size="small" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
    </TopbarConatiner>
  )
}
