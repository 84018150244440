import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import { ReactElement } from 'react'
import { TokenSymbol } from '../../config/contracts/Token/TokenSymbol'
import { WAD_DECIMALS } from '../../constants'
import { AirdropModalData } from '../../interfaces/Modal'
import AppTypography from '../AppTypography/AppTypography'
import ToolTipNumberBox from '../InfoBox/ToolTipNumberBox'
import TokenIcon from '../TokenIcon/TokenIcon'
import AppTokenInput from './AppTokenInput'
import { TokenItem, TokenItemList } from './DisabledAppTokenInput.elements'

interface Props {
  tokenItems: AirdropModalData[]
  disableBgColor?: boolean
}

function DisabledAppTokenInput({
  tokenItems,
  disableBgColor,
}: Props): ReactElement {
  return (
    <AppTokenInput value={''}>
      <TokenItemList disableBgColor={disableBgColor}>
        {tokenItems.map((tokenItem) => {
          if (!tokenItem.value) return <></>
          const formattedValueStr = Number(tokenItem.value)
            .toFixed(WAD_DECIMALS)
            .toString()
          return (
            <TokenItem key={tokenItem.tokenSymbol}>
              <AppTypography
                variant="body1"
                centerContent
                justifyContent="flex-start"
              >
                <TokenIcon
                  tokenSymbol={tokenItem.tokenSymbol as TokenSymbol}
                  iconPath={tokenItem.iconPath}
                  margin="0 8px 0 0"
                />
                {tokenItem.tokenSymbol}
              </AppTypography>
              <AppTypography variant="h6">
                <ToolTipNumberBox
                  displayZeroValue={'0.0'}
                  displayValue={getCommifiedFormat(formattedValueStr)}
                  decimal={18}
                  lessThan={false}
                  actualValue={utils.parseEther(formattedValueStr)}
                />
              </AppTypography>
            </TokenItem>
          )
        })}
      </TokenItemList>
    </AppTokenInput>
  )
}

export default DisabledAppTokenInput
