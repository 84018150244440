import Pagination from '@mui/material/Pagination'
import styled from 'styled-components'

export const StyledPagination = styled(Pagination)`
  margin: 16px 0;

  * {
    display: flex;
    justify-content: center;
  }
`
