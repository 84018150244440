import * as TokenIcon from '../../../assets/tokens'
import { TokenSymbol } from './TokenSymbol'
export class Token {
  readonly decimals: number
  readonly name: string
  readonly symbol: string
  readonly geckoId: string
  readonly icon: string
  constructor(
    decimals: number,
    name: string,
    symbol: TokenSymbol,
    geckoId: string,
    icon: string,
  ) {
    this.symbol = symbol
    this.decimals = decimals
    this.name = name
    this.geckoId = geckoId
    this.icon = icon
  }
}

export const TOKENS: {
  [id in TokenSymbol]: Token
} = {
  [TokenSymbol.MIM]: new Token(
    18,
    'Magic Internet Money',
    TokenSymbol.MIM,
    'magic-internet-money',
    TokenIcon.MIM,
  ),
  [TokenSymbol.FRAX]: new Token(
    18,
    'Frax Stablecoin',
    TokenSymbol.FRAX,
    'frax',
    TokenIcon.FRAX,
  ),
  [TokenSymbol.WBTCe]: new Token(
    8,
    'Wrapped BTC',
    TokenSymbol.WBTCe,
    'wrapped-bitcoin',
    TokenIcon.WBTCE,
  ),
  [TokenSymbol.WETHe]: new Token(
    18,
    'WETH',
    TokenSymbol.WETHe,
    'weth',
    TokenIcon.WETHE,
  ),
  [TokenSymbol.AXLUSDC]: new Token(
    6,
    'Axelar USDC',
    TokenSymbol.AXLUSDC,
    'axlusdc',
    TokenIcon.AXLUSDC,
  ),
  [TokenSymbol.ANKRAVAX]: new Token(
    18,
    'Ankr Staked AVAX',
    TokenSymbol.ANKRAVAX,
    'ankr-staked-avax',
    TokenIcon.ANKRAVAX,
  ),
  [TokenSymbol.ANKRETH]: new Token(
    18,
    'Ankr Staked ETH',
    TokenSymbol.ANKRETH,
    'ankreth',
    TokenIcon.ANKRETH,
  ),
  [TokenSymbol.YUSD]: new Token(
    18,
    'YUSD Stablecoin',
    TokenSymbol.YUSD,
    'yusd-stablecoin',
    TokenIcon.YUSD,
  ),
  [TokenSymbol.MONEY]: new Token(
    18,
    'Moremoney USD',
    TokenSymbol.MONEY,
    'moremoney-usd',
    TokenIcon.MONEY,
  ),
  [TokenSymbol.USDT]: new Token(
    6,
    'Tether USD',
    TokenSymbol.USDT,
    'tether',
    TokenIcon.USDT,
  ),
  [TokenSymbol.USDC]: new Token(
    6,
    'USDC',
    TokenSymbol.USDC,
    'USDC',
    TokenIcon.USDC,
  ),
  [TokenSymbol.PTP]: new Token(
    18,
    'PTP',
    TokenSymbol.PTP,
    'platypus-finance',
    TokenIcon.PTP,
  ),
  [TokenSymbol.MORE]: new Token(
    18,
    'More Token',
    TokenSymbol.MORE,
    'more-token',
    TokenIcon.MORE,
  ),
  [TokenSymbol.WAVAX]: new Token(
    18,
    'Wrapped AVAX',
    TokenSymbol.WAVAX,
    'wrapped-avax',
    TokenIcon.WAVAX,
  ),
  [TokenSymbol.YETI]: new Token(
    18,
    'Yeti Finance',
    TokenSymbol.YETI,
    'yeti-finance',
    TokenIcon.YETI,
  ),
  [TokenSymbol.QI]: new Token(
    18,
    'BENQI',
    TokenSymbol.QI,
    'benqi',
    TokenIcon.QI,
  ),
  [TokenSymbol.HUM]: new Token(
    18,
    'Hummus',
    TokenSymbol.HUM,
    'hummus',
    TokenIcon.HUM,
  ),
  [TokenSymbol.BUSD]: new Token(
    18,
    'BUSD',
    TokenSymbol.BUSD,
    'BUSD',
    TokenIcon.BUSD,
  ),
  [TokenSymbol.USDTe]: new Token(
    6,
    'USDTe',
    TokenSymbol.USDTe,
    'USDTe',
    TokenIcon.USDTe,
  ),
  [TokenSymbol.USDCe]: new Token(
    6,
    'USDCe',
    TokenSymbol.USDCe,
    'USDCe',
    TokenIcon.USDCe,
  ),
  [TokenSymbol.DAIe]: new Token(
    18,
    'DAIe',
    TokenSymbol.DAIe,
    'DAIe',
    TokenIcon.DAIe,
  ),
  [TokenSymbol.sAVAX]: new Token(
    18,
    'sAVAX',
    TokenSymbol.sAVAX,
    'benqi-liquid-staked-avax',
    TokenIcon.SAVAX,
  ),
  [TokenSymbol.yyAVAX]: new Token(
    18,
    'yyAVAX',
    TokenSymbol.yyAVAX,
    'yield-yak-avax',
    TokenIcon.YYAVAX,
  ),
  [TokenSymbol.BTCb]: new Token(
    8,
    'BTCb',
    TokenSymbol.BTCb,
    'bitcoin-avalanche-bridged-btc-b',
    TokenIcon.BTC,
  ),
}

export const SUPPORTED_TOKENS = Object.keys(TOKENS) as TokenSymbol[]
