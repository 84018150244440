import { Snackbar } from '@mui/material'
import styled from 'styled-components'
import { SeverityType } from '../../contexts/SnackbarContext'

export const StyledSnackbar = styled(Snackbar)<{ severity: SeverityType }>`
  .MuiSnackbarContent-root {
    background-color: ${(props) =>
      props.severity === 'success' ? '#5EB954' : '#EB3F32'};
  }
`
