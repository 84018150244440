import { ReactElement } from 'react'
import { networkIconPaths } from '../../../assets/networks'
import { ChainId } from '../../../config/networks'

interface Props {
  chainId?: ChainId
  className?: string
  margin?: string
  iconPath?: string
  size?: number
}

/**
 *
 * Either @param {ChainId} chainId
 * or @param {string} iconPath should be specified to get an icon path
 *
 */

function NetworkIcon({
  className,
  margin,
  chainId,
  iconPath,
  size = 24,
}: Props): ReactElement {
  let src
  if (chainId) {
    src = networkIconPaths[chainId]
  } else if (iconPath) {
    src = iconPath
  }

  return (
    <img
      className={className}
      width={size}
      height={size}
      style={{ margin: margin }}
      src={src}
      alt="network_icon"
    />
  )
}

export default NetworkIcon
