import { Box } from '@mui/material'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import NetworkIcon from '../../../components/icons/NetworkIcon/NetworkIcon'
import { TokenSymbol } from '../../../config/contracts/Token/TokenSymbol'
import { ChainId, NETWORKS } from '../../../config/networks'
import { AirdropData } from '../../../contexts/AirdropContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { AirdropModalData } from '../../../interfaces/Modal'
import { getTokenIconPath } from '../../UspCompensationPage'

interface ThirdPartyAirdropHeaderInfoProps {
  name: string
  airdropChainId: ChainId
  description: string
  handleMultiClaim: (
    airdropModalData?: AirdropModalData[] | undefined,
  ) => Promise<void>
  isMultiClaimDisabled: boolean
  unclaimedThirdPartyAirdropData: AirdropData[]
  tokenSymbol: TokenSymbol
}

const ThirdPartyAirdropHeaderInfo = ({
  name,
  airdropChainId,
  description,
  handleMultiClaim,
  isMultiClaimDisabled,
  unclaimedThirdPartyAirdropData,
  tokenSymbol,
}: ThirdPartyAirdropHeaderInfoProps) => {
  const { isTabletSm } = useBreakpoints()
  const { network, switchNetwork } = useWeb3()

  return (
    <Box
      display="flex"
      width="100%"
      flex="1"
      justifyContent="space-between"
      alignItems="center"
      padding="24px 24px 12px 24px"
      flexDirection={isTabletSm ? 'column' : 'row'}
      textAlign={isTabletSm ? 'center' : 'left'}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={isTabletSm ? 'column' : 'row'}
      >
        <Box display="flex" flexDirection="column" marginRight="5px">
          <AppTypography variant="h5">{name}</AppTypography>
          <AppTypography variant="body2">{description}</AppTypography>
        </Box>
        {/** firstly, check whether there is unclaimed airdrop*/}
        {unclaimedThirdPartyAirdropData.length ? (
          <>
            {/** secondly, check whether airdrop is in the current network*/}
            {airdropChainId === network?.id ? (
              <AppButton
                style={{ padding: '4px 16px', marginLeft: '8px' }}
                customVariant="primary"
                disabled={isMultiClaimDisabled}
                onClick={() =>
                  handleMultiClaim(
                    unclaimedThirdPartyAirdropData.map((airdrop) => ({
                      value: airdrop.airdropAmount,
                      iconPath: getTokenIconPath(tokenSymbol),
                      tokenSymbol: tokenSymbol,
                      isClaimed: airdrop.isClaimed,
                    })),
                  )
                }
              >
                Claim all
              </AppButton>
            ) : (
              <AppButton onClick={() => switchNetwork(airdropChainId)}>
                Switch Network
              </AppButton>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        marginRight={!isTabletSm ? '40px' : '0'}
      >
        {!isTabletSm && (
          <>
            <NetworkIcon
              chainId={airdropChainId}
              size={40}
              margin="0 5px 0 0"
            ></NetworkIcon>
            <Box display="flex" flexDirection="column">
              <AppTypography variant="h6">
                {NETWORKS[airdropChainId].name}
              </AppTypography>
              <AppTypography variant="caption2">Network</AppTypography>
            </Box>
          </>
        )}
        {isTabletSm && (
          <Box display="flex" alignItems="center">
            <AppTypography variant="caption2">Network:</AppTypography>
            &nbsp;&nbsp;
            <NetworkIcon
              chainId={airdropChainId}
              size={16}
              margin="0 5px 0 0"
            ></NetworkIcon>
            <AppTypography variant="caption2">
              {NETWORKS[airdropChainId].name}
            </AppTypography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ThirdPartyAirdropHeaderInfo
