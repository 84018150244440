import cloneDeep from 'lodash.clonedeep'
import { createContext, useContext, useEffect, useState } from 'react'
import { ThirdPartyAirdropId } from '../config/thirdPartyAirdrops'
import {
  OFFICIAL_AIRDROP_CAMPAIGNS,
  THIRD_PARTY_AIRDROP_CAMPAIGNS,
} from '../constants'
import { AirdropType } from '../interfaces/Airdrop'
import { transformAirdropData } from '../utils/airdrop'
import { useBalance } from './BalanceContext'
import { useMulticallData } from './MulticallDataContext'
import { useWeb3 } from './Web3Context'

export type AirdropData = {
  address: `0x${string}`
  isClaimed: boolean
  claimableUntil: number
  isEligible: boolean
  airdropUsdValue: string
  airdropAmount: string
  isEnded: boolean
  isEligibleCampaignEndedAndNotClaimed: boolean
  index: number | undefined
  airdropAmountHexStr: string | undefined
  proof: string[] | undefined
  isUspCompensation?: boolean
  thirdPartyAirdropId?: ThirdPartyAirdropId
}

type INewAirdropData = {
  [id in AirdropType]: {
    [id in string]: AirdropData
  }
}

type IReturn = {
  newAirdropData: INewAirdropData
  handleSingleClaimAirdropSuccess: (
    airdropType: AirdropType,
    address: `0x${string}`,
  ) => void
  handleMultiClaimAirdropSuccess: (
    airdropType: AirdropType,
    thirdPartyAirdropId: ThirdPartyAirdropId,
  ) => void
}

const initAirdropData = {
  [AirdropType.OFFICIAL]: {},
  [AirdropType.THIRD_PARTY]: {},
}

const initReturn = {
  newAirdropData: initAirdropData,
  handleSingleClaimAirdropSuccess: () => undefined,
  handleMultiClaimAirdropSuccess: () => undefined,
}

const AirdropContext = createContext<IReturn>(initReturn)
AirdropContext.displayName = 'AirdropContext'
export const useAirdropData = () => useContext(AirdropContext)
export function AirdropContextProvider({
  children,
}: {
  children: React.ReactElement
}) {
  const { tokenPrice } = useBalance()
  const { account } = useWeb3()
  const { airdropData } = useMulticallData()
  const [newAirdropData, setNewAirdropData] =
    useState<INewAirdropData>(initAirdropData)

  useEffect(() => {
    if (!airdropData) return
    const newData = cloneDeep(initAirdropData)
    OFFICIAL_AIRDROP_CAMPAIGNS.forEach(
      ({ address, tokenSymbol, isUspCompensation }) => {
        const campaignData = airdropData[AirdropType.OFFICIAL][address]
        if (tokenSymbol && account && campaignData) {
          newData[AirdropType.OFFICIAL] = {
            ...newData[AirdropType.OFFICIAL],
            [address]: {
              ...transformAirdropData(
                campaignData.isClaimed,
                campaignData.claimableUntil,
                campaignData.airdropAmount,
                tokenPrice[tokenSymbol],
              ),
              index: campaignData.index,
              airdropAmountHexStr: campaignData.airdropAmountHexStr,
              proof: campaignData.proof,
              address,
              isUspCompensation,
            },
          }
        }
      },
    )
    THIRD_PARTY_AIRDROP_CAMPAIGNS.forEach(
      ({ address, tokenSymbol, thirdPartyAirdropId }) => {
        const campaignData = airdropData[AirdropType.THIRD_PARTY][address]
        if (tokenSymbol && campaignData) {
          newData[AirdropType.THIRD_PARTY] = {
            ...newData[AirdropType.THIRD_PARTY],
            [address]: {
              ...transformAirdropData(
                campaignData.isClaimed,
                campaignData.claimableUntil,
                campaignData.airdropAmount,
                tokenPrice[tokenSymbol],
              ),
              index: campaignData.index,
              airdropAmountHexStr: campaignData.airdropAmountHexStr,
              proof: campaignData.proof,
              address,
              thirdPartyAirdropId,
            },
          }
        }
      },
    )
    setNewAirdropData(newData)
  }, [account, airdropData, tokenPrice])

  // update offical / third party card ui (i.e. claim status) after single claim
  const handleSingleClaimAirdropSuccess = (
    airdropType: AirdropType,
    address: `0x${string}`,
  ) => {
    if (!airdropType || !address || !newAirdropData) return
    const clonedAirdropData = cloneDeep(newAirdropData)
    if (!clonedAirdropData[airdropType][address]) return
    clonedAirdropData[airdropType][address].isClaimed = true
    setNewAirdropData(clonedAirdropData)
  }

  // update third party card ui (i.e. claim status) after multi claim
  const handleMultiClaimAirdropSuccess = (
    airdropType: AirdropType,
    thirdPartyAirdropId?: ThirdPartyAirdropId,
  ) => {
    if (airdropType !== AirdropType.THIRD_PARTY) return
    const thirdPartyAirdropsData =
      cloneDeep(newAirdropData)[AirdropType.THIRD_PARTY]
    for (const airdopData of Object.values(thirdPartyAirdropsData)) {
      if (airdopData.thirdPartyAirdropId === thirdPartyAirdropId) {
        airdopData.isClaimed = true
      }
    }
    setNewAirdropData({
      ...newAirdropData,
      [AirdropType.THIRD_PARTY]: thirdPartyAirdropsData,
    })
  }

  return (
    <AirdropContext.Provider
      value={{
        newAirdropData,
        handleSingleClaimAirdropSuccess,
        handleMultiClaimAirdropSuccess,
      }}
    >
      {children}
    </AirdropContext.Provider>
  )
}

export default AirdropContext
