import { StyledPagination } from './AppPagination.element'

interface AppPaginationProps {
  count: number
  page: number
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void
  style?: React.CSSProperties
}

function AppPagination({ count, page, onChange, style }: AppPaginationProps) {
  return (
    <StyledPagination
      count={count}
      page={page}
      onChange={onChange}
      style={style}
      color="primary"
    />
  )
}

export default AppPagination
