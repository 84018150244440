import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { ThemeProvider } from 'styled-components'
import { WagmiConfig } from 'wagmi'
import theme from './config/themes'
import { wagmiClient } from './config/wagmi'
import { AirdropContextProvider } from './contexts/AirdropContext'
import { BalanceProvider } from './contexts/BalanceContext'
import ErrorModalProvider from './contexts/ErrorModalContext'
import { ModalProvider } from './contexts/ModalContext'
import { MulticallDataProvider } from './contexts/MulticallDataContext'
import { SnackbarProvider } from './contexts/SnackbarContext'
import Web3Provider from './contexts/Web3Context'
import Routes from './Routes'

// import UnsupportedNetwork from "./components/errors/UnsupportedNetwork/UnsupportedNetwork";
// import { ModalId } from "./interfaces/Modal";

function App(): React.ReactElement {
  /** @todo uncomment when we have light theme */
  // const [darkMode, setDarkMode] = useState(
  //   useMediaQuery('(prefers-color-scheme: dark)'),
  // )

  /** @todo add back toggleDarkMode when needed */
  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode)
  // }

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALTICS_ID as string, {
      testMode: process.env.REACT_APP_ENVIRONMENT !== 'production',
    })
  }, [])
  return (
    <WagmiConfig client={wagmiClient}>
      <SnackbarProvider>
        <Web3Provider>
          <MulticallDataProvider>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              {/* use ThemeProvider from styled-components to access theme as style components from Material-ui are not working properly  */}
              <ThemeProvider theme={theme}>
                <ErrorModalProvider>
                  <BalanceProvider>
                    <AirdropContextProvider>
                      <ModalProvider>
                        <Routes />
                      </ModalProvider>
                    </AirdropContextProvider>
                  </BalanceProvider>
                </ErrorModalProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MulticallDataProvider>
        </Web3Provider>
      </SnackbarProvider>
    </WagmiConfig>
  )
}

export default App
