import { TokenSymbol } from '../config/contracts/Token/TokenSymbol'

export enum ModalId {
  UNSET = 'UNSET',
  CONNECT_TO_WALLET = 'CONNECT_TO_WALLET',
  SELECT_FROM_TOKEN = 'SELECT_FROM_TOKEN',
  SELECT_TO_TOKEN = 'SELECT_TO_TOKEN',
  CONFIRM_SWAP = 'CONFIRM_SWAP',
  SWAP_WAIT_FOR_CONFIRMATION = 'WAIT_FOR_CONFIRMATION',
  TRANSACTION_SUBMITTED = 'TRANSACTION_SUBMITTED',
  POOL_SELECT_ASSET = 'POOL_SELECT_ASSET',
  POOL_DEPOSIT_LIQUIDITY = 'POOL_DEPOSIT_LIQUIDITY',
  POOL_DEPOSIT_WAIT_FOR_CONFIRMATION = 'POOL_DEPOSIT_WAIT_FOR_CONFIRMATION',
  POOL_WITHDRAW_LIQUIDITY = 'POOL_WITHDRAW_LIQUIDITY',
  POOL_WITHDRAW_WAIT_FOR_CONFIRMATION = 'POOL_WITHDRAW_WAIT_FOR_CONFIRMATION',
  USER_PREFERENCE = 'USER_PREFERENCE',
  STAKE_PTP = 'STAKE_PTP',
  STAKE_WAIT_FOR_CONFIRMATION = 'STAKE_WAIT_FOR_CONFIRMATION',
  UNSTAKE_PTP = 'UNSTAKE_PTP',
  CONFIRM_UNSTAKE = 'CONFIRM_UNSTAKE',
  UNSTAKE_WAIT_FOR_CONFIRMATION = 'UNSTAKE_WAIT_FOR_CONFIRMATION',
  AIRDROP_CONFIRMATION = 'AIRDROP_CONFIRMATION',
}
export interface ModalState {
  currentModalId: ModalId
  transactionHashes?: string[]
  airdropData?: AirdropModalData[]
}

export type AirdropModalData = {
  value: string | undefined
  tokenSymbol?: TokenSymbol | null
  iconPath?: string
  isClaimed?: boolean
}

export enum ModalActionType {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface ModalAction {
  type: ModalActionType
  payload: ModalState
}
