import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { Box } from '@mui/material'
import { ReactElement } from 'react'
import AppTypography from '../../../components/AppTypography/AppTypography'
import AppButton from '../../../components/buttons/AppButton/AppButton'
import TokenIcon from '../../../components/TokenIcon/TokenIcon'
import { TOKENS } from '../../../config/contracts/Token'
import { TokenSymbol } from '../../../config/contracts/Token/TokenSymbol'
import { ChainId } from '../../../config/networks'
import { useAirdropData } from '../../../contexts/AirdropContext'
import { useWeb3 } from '../../../contexts/Web3Context'
import { Colorized } from '../../../gloabalStyles'
import useClaim from '../../../hooks/useClaim'
import { AirdropType } from '../../../interfaces/Airdrop'
import {
  CardContainer,
  LogoPriceBox,
  OverlayContainer,
} from './AirdropOfficialCard.elements'
import { AirdropOfficialCardInfo } from './AirdropOfficialCardInfo'

interface Props {
  startDate: Date
  title: string
  description: string
  contractAddress: `0x${string}`
  balances: { [account: string]: string } | undefined
  tokenSymbol: TokenSymbol
  chainId: ChainId
}

const AirdropOfficialCard = ({
  startDate,
  title,
  description,
  contractAddress,
  balances,
  tokenSymbol,
  chainId: airdropChainId,
}: Props): ReactElement => {
  const airdropType = AirdropType.OFFICIAL
  const { newAirdropData: data } = useAirdropData()
  const {
    isClaimed,
    claimableUntil,
    isEligible,
    airdropUsdValue,
    airdropAmount: airdropAmountStr,
  } = data[airdropType][contractAddress] || {
    isClaimed: false,
    claimableUntil: 0,
    isEligible: false,
    airdropUsdValue: '0',
    airdropAmount: '0',
    isEnded: false,
    isEligibleCampaignEndedAndNotClaimed: false,
  }
  const { network, switchNetwork } = useWeb3()

  const { handleClaimClick } = useClaim(
    tokenSymbol,
    contractAddress,
    airdropChainId,
    AirdropType.OFFICIAL,
  )

  const claimBtnText = isClaimed ? 'Claimed' : 'Claim'

  const handleSwitchNetwork = (chainId: ChainId) => {
    switchNetwork(chainId)
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      flexDirection="column"
      margin="20px 10px"
    >
      {!isEligible || isClaimed ? <OverlayContainer /> : null}
      <CardContainer>
        <AppTypography variant="h5" textAlign="center">
          {title}
        </AppTypography>
        <AppTypography variant="body2" textAlign="center">
          {description}
        </AppTypography>
        <AirdropOfficialCardInfo
          startDate={startDate}
          endDate={claimableUntil}
          chainId={airdropChainId}
        />
        <LogoPriceBox>
          {isEligible ? (
            <>
              <AppTypography variant="subtitle1">
                You are eligible for
              </AppTypography>
              <TokenIcon tokenSymbol={tokenSymbol} size={60} />
              <AppTypography variant="h6" textAlign="center">
                {getCommifiedFormat(airdropAmountStr)}{' '}
                {TOKENS[tokenSymbol].symbol}
              </AppTypography>
              <AppTypography variant="caption2">
                <Colorized variant="blue">
                  (=$ {getCommifiedFormat(airdropUsdValue)})
                </Colorized>
              </AppTypography>
            </>
          ) : (
            <AppTypography variant="subtitle1" textAlign="center">
              Your account is not eligible for this airdrop
            </AppTypography>
          )}
        </LogoPriceBox>

        {network?.id === airdropChainId ? (
          <>
            {isEligible && (
              <AppButton
                onClick={() => handleClaimClick()}
                data-testid="claim-button"
                disabled={isClaimed}
                fullWidth
              >
                {claimBtnText}
              </AppButton>
            )}
          </>
        ) : (
          <>
            {isEligible && (
              <AppButton
                onClick={() => handleSwitchNetwork(airdropChainId)}
                data-testid="switch-network-button"
                fullWidth
              >
                Switch Network
              </AppButton>
            )}
          </>
        )}
      </CardContainer>
    </Box>
  )
}

export default AirdropOfficialCard
