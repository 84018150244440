import { TypographyProps } from '@mui/material'
import { ReactElement } from 'react'
import { StyledTypography } from './AppTypography.elements'

export interface AppTypographyProps extends TypographyProps {
  component?: string
  /** set opacity to 0.5 */
  transparent?: boolean
  /** center children which are composed of texts and images */
  centerContent?: boolean
  flexWrap?: 'wrap' | 'nowrap' | 'unset'
  enableTextGlow?: boolean
}

const AppTypography = ({
  children,
  component,
  transparent,
  justifyContent,
  flexWrap,
  centerContent,
  enableTextGlow,
  ...otherProps
}: AppTypographyProps): ReactElement => {
  return (
    <StyledTypography
      component={component}
      transparent={transparent}
      justifyContent={justifyContent}
      flexWrap={flexWrap}
      centerContent={centerContent}
      enableTextGlow={enableTextGlow}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  )
}

export default AppTypography
