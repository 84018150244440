import { Box } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: inherit;
  background: linear-gradient(
    180deg,
    rgba(0, 118, 231, 0) 0%,
    rgba(0, 118, 231, 0.7) 100%
  );
`
export const Overlay = styled(Container)`
  background: radial-gradient(
    54.1% 29.11% at 50% 103.96%,
    rgba(0, 255, 22, 0.5) 0%,
    rgba(12, 88, 255, 0) 100%
  );
`

export const ChildrenContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`
