import { isAddress } from 'ethers/lib/utils'
import { ReactElement, useEffect, useState } from 'react'
import { useWeb3 } from '../../../contexts/Web3Context'
import {
  Container,
  StyledCheckbox,
  StyledInput,
} from './MockAccountInput.elements'

function MockAccountInput(): ReactElement {
  const [mockAccountInput, setMockAccountInput] = useState('')
  const [enableMockAccount, setEnableMockAccount] = useState(false)
  const { setMockAccount } = useWeb3()

  useEffect(() => {
    if (isAddress(mockAccountInput) && enableMockAccount) {
      setMockAccount(mockAccountInput)
    } else {
      setMockAccount(null)
    }
  }, [enableMockAccount, mockAccountInput, setMockAccount])

  return (
    <Container>
      <StyledInput
        placeholder="Enter Address"
        value={mockAccountInput}
        bgColor="unset"
        onChange={setMockAccountInput}
      />
      <StyledCheckbox
        onChange={() => setEnableMockAccount((prev) => !prev)}
        disableRipple
      />
    </Container>
  )
}

export default MockAccountInput
