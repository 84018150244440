import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { BoxProps, Theme, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Container, ItemContainer, ToolTipContainer } from './InfoBox.element'
interface Props extends BoxProps {
  className?: string
  children?: React.ReactNode
  padding?: string
  paddingBottom?: string
}

function InfoBox({
  className,
  children,
  padding,
  paddingBottom,
  ...otherProps
}: Props): ReactElement {
  return (
    <Container
      className={clsx('info-box', className && className)}
      display="flex"
      flexDirection="column"
      pad={padding}
      padBottom={paddingBottom}
      {...otherProps}
    >
      {children}
    </Container>
  )
}

export default InfoBox

interface ItemProps extends BoxProps {
  fullWidth?: boolean
}
InfoBox.Item = function Item({
  className,
  children,
  fullWidth,
  ...otherProps
}: ItemProps): ReactElement {
  return (
    <ItemContainer
      className={className}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      $fullWidth={fullWidth}
      {...otherProps}
    >
      {children}
    </ItemContainer>
  )
}
interface SwapInfoBoxProps extends Props {
  text: React.ReactNode
  iconSize?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}
InfoBox.ToolTip = function Item({
  className,
  text = 'tool tip',
  iconSize = '15px',
  placement,
}: SwapInfoBoxProps): ReactElement {
  const isSmallerThanMdDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(theme.breakpoints.values.md - 1),
  )
  return (
    <ToolTipContainer
      className={className}
      arrow
      placement={
        placement ? placement : isSmallerThanMdDevice ? 'bottom' : 'right'
      }
      title={text}
      iconsize={iconSize}
      enterTouchDelay={0}
      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
        ],
      }}
    >
      <HelpOutlineOutlinedIcon data-testid="tooltip-icon" />
    </ToolTipContainer>
  )
}
