import ANKRAVAX from '../../assets/tokens/ankravax.png'
import ANKRETH from '../../assets/tokens/ankreth.svg'
import AXLUSDC from '../../assets/tokens/axlusdc.svg'
import BTC from '../../assets/tokens/btc.svg'
import BUSD from '../../assets/tokens/busd.png'
import DAI from '../../assets/tokens/dai.svg'
import ETH from '../../assets/tokens/eth.svg'
import FRAX from '../../assets/tokens/frax.png'
import HUM from '../../assets/tokens/hum.svg'
import MIM from '../../assets/tokens/mim.png'
import MONEY from '../../assets/tokens/money.png'
import MORE from '../../assets/tokens/more.png'
import PTP from '../../assets/tokens/ptp.svg'
import QI from '../../assets/tokens/qi.svg'
import RENBTC from '../../assets/tokens/renbtc.svg'
import SAVAX from '../../assets/tokens/savax.png'
import TUSD from '../../assets/tokens/tusd.svg'
import Undefined from '../../assets/tokens/undefined.svg'
import USD from '../../assets/tokens/usd.svg'
import USDC from '../../assets/tokens/usdc.svg'
import USDT from '../../assets/tokens/usdt.svg'
import VEPTP from '../../assets/tokens/veptp.png'
import WAVAX from '../../assets/tokens/wavax.png'
import WBTCE from '../../assets/tokens/wbtc.svg'
import WETHE from '../../assets/tokens/weth.png'
import YETI from '../../assets/tokens/yeti.png'
import YUSD from '../../assets/tokens/yusd.png'
import YYAVAX from '../../assets/tokens/yyavax.png'
import ZETH from '../../assets/tokens/zeth.png'

const USDTe = USDT
const USDCe = USDC
const DAIe = DAI
export {
  Undefined,
  MIM,
  BTC,
  DAI,
  WETHE,
  TUSD,
  FRAX,
  RENBTC,
  USD,
  USDC,
  USDT,
  WBTCE,
  ZETH,
  ETH,
  PTP,
  VEPTP,
  MORE,
  YETI,
  QI,
  WAVAX,
  HUM,
  BUSD,
  USDTe,
  USDCe,
  DAIe,
  YYAVAX,
  SAVAX,
  AXLUSDC,
  ANKRAVAX,
  ANKRETH,
  YUSD,
  MONEY,
}
