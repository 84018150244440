import { strToWad, wmul } from '@hailstonelabs/big-number-utils/dist/src'
import { utils } from 'ethers'

export function transformAirdropData(
  isClaimed: boolean,
  claimableUntil: number,
  airdropAmount: string,
  airdropTokenPrice: string,
) {
  const airdropAmountWad = strToWad(airdropAmount)
  const isEligible = !airdropAmountWad.isZero()
  const airdropTokenPriceWad = strToWad(airdropTokenPrice)
  const currentUtcTimestamp = new Date().getTime() / 1000
  const isEnded = currentUtcTimestamp > claimableUntil
  const isEligibleCampaignEndedAndNotClaimed =
    isEnded && isEligible && !isClaimed
  const airdropUsdValue = utils.formatEther(
    wmul(airdropAmountWad, airdropTokenPriceWad),
  )

  return {
    isClaimed,
    claimableUntil,
    isEligible,
    airdropUsdValue,
    airdropAmount,
    isEnded,
    isEligibleCampaignEndedAndNotClaimed,
  }
}
