import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const CardContainer = styled(Box)`
  ${({ theme }) => css`
    width: 348px;
    height: 404px;
    padding: 24px 24px 32px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${theme.palette.primary['A200']};
    border-radius: 20px;
    background: ${alpha(theme.palette.secondary.main, 0.2)};
  `}
`

export const TokenImage = styled.img`
  width: 60px;
  height: 60px;
  padding: 10px;
`

export const LogoPriceBox = styled(Box)`
  ${({ theme }) => css`
    padding: 8px;
    margin: 6px 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${alpha(theme.palette.secondary.main, 0.2)};
    border-radius: 10px;
  `}
`

export const OverlayContainer = styled(Box)`
  background-color: rgba(10, 25, 65, 0.7);
  opacity: 0.5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
`
