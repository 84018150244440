import { getCommifiedFormat } from '@hailstonelabs/big-number-utils/dist/src'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import { getTokenIconPath } from '.'
import Slidable from '../../components/animations/Slidable/Slidable'
import AppTypography from '../../components/AppTypography/AppTypography'
import AccordionButton from '../../components/buttons/AccordionButton/AccordionButton'
import AppButton from '../../components/buttons/AppButton/AppButton'
import InfoBox from '../../components/InfoBox/InfoBox'
import TokenIcon from '../../components/TokenIcon/TokenIcon'
import { TokenSymbol } from '../../config/contracts/Token/TokenSymbol'
import { ChainId } from '../../config/networks'
import { OFFICIAL_AIRDROP_CAMPAIGNS } from '../../constants'
import { AirdropData, useAirdropData } from '../../contexts/AirdropContext'
import { useBalance } from '../../contexts/BalanceContext'
import { useWeb3 } from '../../contexts/Web3Context'
import useMultiClaim from '../../hooks/useMultiClaim'
import { AirdropType } from '../../interfaces/Airdrop'
import { CompensationType } from '../../interfaces/uspCompensation'
import { isNotStableCoin } from '../../utils/common'

type CompensationCardProps = {
  id: string
  recoveredFunds: { amount: number; asOf?: string; breakdown: React.ReactNode }
  data: CompensationType
}
export default function CompensationCard({
  id,
  recoveredFunds,
  data,
}: CompensationCardProps) {
  const [isBreakdownShown, setIsBreakdownShown] = useState(false)
  const { tokenPrice } = useBalance()
  const { network } = useWeb3()
  const { newAirdropData: airdropData } = useAirdropData()
  const activeChainId =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? network?.id
      : ChainId.AVALANCHE
  const airdropsData = useMemo(() => {
    const uspCompensationCampaigns = {
      [ChainId.AVALANCHE]: OFFICIAL_AIRDROP_CAMPAIGNS.filter(
        (campaign) =>
          campaign.isUspCompensation &&
          campaign.chainId === ChainId.AVALANCHE &&
          campaign.uspCompensationId === Number(id),
      ),
      [ChainId.FUJI]: OFFICIAL_AIRDROP_CAMPAIGNS.filter(
        (campaign) =>
          campaign.isUspCompensation &&
          campaign.chainId === ChainId.FUJI &&
          campaign.uspCompensationId === Number(id),
      ),
    }
    return (
      activeChainId === ChainId.AVALANCHE || activeChainId === ChainId.FUJI
        ? uspCompensationCampaigns[activeChainId]
        : []
    )
      .map((campaign) => airdropData.Official[campaign.address] as AirdropData)
      .filter((data) => data)
  }, [activeChainId, airdropData.Official, id])
  const theme = useTheme()
  const { handleMultiClaim, disabled } = useMultiClaim({
    chainId: activeChainId || ChainId.AVALANCHE,
    airdropsData,
    airdropType: AirdropType.THIRD_PARTY,
  })
  let totalUsdValue = 0
  const dataBreakdown: {
    [id in string]: {
      usd: string
      unit: string
    }
  } = {}
  for (let index = 0; index < Object.entries(data.breakdown).length; index++) {
    const [tokenSymbol, value] = Object.entries(data.breakdown)[index]
    const formattedAmount = getCommifiedFormat(value.toFixed(18), 2)
    const usdValue = isNotStableCoin(tokenSymbol as TokenSymbol)
      ? Number(tokenPrice[tokenSymbol as TokenSymbol]) * value
      : 1 * value
    totalUsdValue += usdValue
    dataBreakdown[tokenSymbol] = {
      unit: formattedAmount,
      usd: getCommifiedFormat(usdValue.toFixed(18), 2),
    }
  }
  return (
    <Box
      border={`1px solid ${theme.palette.primary[500]}`}
      p="12px"
      borderRadius={`${theme.borderRadius.md}`}
      minWidth="350px"
      display="flex"
      flexDirection="column"
      gap="8px"
    >
      <AppTypography transparent>Compensation #{id}</AppTypography>
      <AppTypography variant="subtitle2">
        Recovered funds
        <InfoBox.ToolTip text={recoveredFunds.breakdown} />: $
        {getCommifiedFormat(recoveredFunds.amount.toString(), 2)}{' '}
      </AppTypography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <AppTypography variant="subtitle2" component="span" centerContent>
            My compensation
            <InfoBox.ToolTip text="The compensation amount is calculated as my deposits * recovered funds / protocol liability." />
            : ${getCommifiedFormat(totalUsdValue.toFixed(18), 2)}
          </AppTypography>
          <AppButton
            size="small"
            customVariant="neutral"
            style={{ padding: '1px 10px', margin: '0 16px 0 16px' }}
            onClick={() =>
              handleMultiClaim(
                Object.entries(data.breakdown).map(([tokenSymbol, value]) => ({
                  value: value.toString(),
                  iconPath: getTokenIconPath(tokenSymbol),
                  tokenSymbol: tokenSymbol as TokenSymbol,
                })),
              )
            }
            disabled={disabled}
          >
            <AppTypography variant="caption2">
              {disabled ? 'Claimed' : 'Claim'}
            </AppTypography>
          </AppButton>
        </Box>
        <AccordionButton
          active={isBreakdownShown}
          onClick={() => setIsBreakdownShown((prev) => !prev)}
        />
      </Box>
      {isBreakdownShown && (
        <Slidable active={isBreakdownShown}>
          {Object.entries(dataBreakdown).map(([tokenSymbol, value]) => {
            return (
              <div key={tokenSymbol}>
                <AppTypography centerContent variant="caption">
                  <TokenIcon
                    iconPath={getTokenIconPath(tokenSymbol)}
                    margin="0 4px 0 0"
                  />
                  {value.unit} {tokenSymbol} ($
                  {value.usd})
                </AppTypography>
              </div>
            )
          })}
        </Slidable>
      )}
    </Box>
  )
}
