import {
  getCommifiedFormat,
  getDpFormat,
} from '@hailstonelabs/big-number-utils/dist/src'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'
import { useMemo } from 'react'
import AppTypography from '../../components/AppTypography/AppTypography'
import InfoBox from '../../components/InfoBox/InfoBox'
import TooltipText from '../../components/TooltipText/TooltipText'
import { TokenSymbol } from '../../config/contracts/Token/TokenSymbol'
import { ChainId, NETWORKS } from '../../config/networks'
import { RECOVERED_FUNDS_BREAKDOWN } from '../../constants'
import CompensationJsonFuji from '../../constants/airdrops/official/fuji/usp_compensation/users.json'
import CompensationJsonMainnet from '../../constants/airdrops/official/mainnet/usp_compensation_02/users.json'
import { useBalance } from '../../contexts/BalanceContext'
import { useWeb3 } from '../../contexts/Web3Context'
import { ExternalLink, StyledDivider } from '../../gloabalStyles'
import { UserData } from '../../interfaces/uspCompensation'
import { isNotStableCoin } from '../../utils/common'
import AfterAttackTable from './AfterAttackTable'
import CompensationCard from './CompensationCard'
import PreAttackTable from './PreAttackTable'

// a Hacky way to get token icons
export const getTokenIconPath = (tokenSymbol: string) => {
  tokenSymbol = tokenSymbol.toLowerCase()

  return `https://app.platypus.finance/tokens/${tokenSymbol.replace(
    /\..+/,
    '',
  )}.${['busd', 'yyavax', 'savax'].includes(tokenSymbol) ? 'png' : 'svg'}`
}

export default function UspCompensationPage() {
  const { account, network } = useWeb3()
  const { tokenPrice } = useBalance()
  /**@todo open claim all function */
  // const data = useAirdropData()
  const activeChainId =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? network?.id
      : ChainId.AVALANCHE
  const userData = useMemo(() => {
    const CompensationJson =
      (activeChainId === ChainId.AVALANCHE && CompensationJsonMainnet) ||
      (activeChainId === ChainId.FUJI && CompensationJsonFuji)
    if (account && CompensationJson) {
      const _userData = (
        CompensationJson['users'] as {
          [id in string]: Omit<UserData, 'market'>
        }
      )[account.toLowerCase()]
      return {
        market: {
          ...CompensationJson['market'],
        },
        ..._userData,
      } as UserData
    }
    return null
  }, [account, activeChainId])
  const isAdjustmentProcessing = userData?.adjustedBalance
    ? userData?.adjustedBalance < 0
    : false

  const hasLoss = !!userData?.adjustedBalance

  /**@todo open claim all function */
  // const isAllCompensationClaimed = Object.values(data[AirdropType.OFFICIAL])
  //   .filter((item) => item?.isUspCompensation)
  //   .every((item) => item?.isClaimed)

  return (
    <Box
      padding="40px"
      position="relative"
      maxWidth="1400px"
      width="100%"
      margin="0 auto"
    >
      {!userData && (
        <AppTypography variant="h6">
          To access the details about your USP compensation, kindly switch
          to&nbsp;
          {process.env.REACT_APP_ENVIRONMENT === 'production'
            ? NETWORKS[ChainId.AVALANCHE].name
            : `${NETWORKS[ChainId.AVALANCHE].name} or ${
                NETWORKS[ChainId.FUJI].name
              }`}
          &nbsp;network.
        </AppTypography>
      )}
      {userData && (
        <Box
          display="flex"
          flexDirection="column"
          gap="30px"
          width="100%"
          alignItems="flex-start"
        >
          <AppTypography variant="h4">USP Compensation</AppTypography>
          <AppTypography variant="h6">Overview</AppTypography>
          <Box display="flex" flexDirection="column" gap="12px">
            <AppTypography variant="subtitle2">
              <AppTypography component="span" transparent>
                Protocol total liability
                <InfoBox.ToolTip text="Total liability in the main pool, plus USP total supply, minus USP total debt." />
                :{' '}
              </AppTypography>
              ${getCommifiedFormat(userData.market.totalLiability.toString())}
            </AppTypography>
            <AppTypography variant="subtitle2">
              <AppTypography component="span" centerContent transparent>
                My adjusted balance
                <InfoBox.ToolTip
                  text={`My deposits in the main pool, plus my USP balance, minus my USP debt, check “Pre-attack Net Value” and “Post-attack Adjustment” below.`}
                />
                :
              </AppTypography>{' '}
              {isAdjustmentProcessing ? (
                'Work in progress'
              ) : (
                <>
                  $
                  {getCommifiedFormat(
                    userData.adjustedBalance?.toString() || '0',
                  )}{' '}
                  ({getDpFormat(userData.share?.toString() || '0', 2)}% of the
                  protocol total liability)
                </>
              )}
            </AppTypography>
            {hasLoss && (
              <Message message="Please note that additional compensation may be provided in the future if further funds are recovered." />
            )}
            {/**@todo implement onClick function */}
            {/* {hasLoss && !isAllCompensationClaimed && (
              <AppButton style={{ width: 'fit-content', padding: '6px 20px' }}>
                Claim all compensation
              </AppButton>
            )} */}
            <Box
              display="flex"
              flexDirection="row"
              gap="12px"
              flexWrap="wrap"
              alignItems="flex-start"
            >
              {userData.compensation?.map((data, index) => {
                let totalAmountInUsd = 0
                const recoveredFunds = RECOVERED_FUNDS_BREAKDOWN[index]
                for (let index = 0; index < recoveredFunds.length; index++) {
                  const recoveredFund = recoveredFunds[index]
                  for (let j = 0; j < recoveredFund.data.length; j++) {
                    const recoveredFundData = recoveredFund.data[j]
                    const usdValue = isNotStableCoin(
                      recoveredFundData.token as TokenSymbol,
                    )
                      ? Number(
                          tokenPrice[recoveredFundData.token as TokenSymbol],
                        ) * Number(recoveredFundData.amount)
                      : 1 * Number(recoveredFundData.amount)
                    totalAmountInUsd += usdValue
                  }
                }
                return (
                  <CompensationCard
                    key={index}
                    id={(index + 1).toString()}
                    data={data}
                    recoveredFunds={{
                      amount: totalAmountInUsd,
                      breakdown: (
                        <TooltipText>
                          {recoveredFunds?.map(({ groupLabel, data }) => {
                            return (
                              <TooltipText.Group
                                key={groupLabel}
                                header={groupLabel}
                              >
                                {data.map((d) => {
                                  return (
                                    <TooltipText.RewardItem
                                      key={d.token}
                                      iconPath={getTokenIconPath(d.token)}
                                      title={d.token}
                                      value={getCommifiedFormat(d.amount, 2)}
                                    />
                                  )
                                })}
                              </TooltipText.Group>
                            )
                          })}
                        </TooltipText>
                      ),
                    }}
                  />
                )
              })}
            </Box>
          </Box>

          <StyledDivider $colored style={{ opacity: 0.3 }} />
          <AppTypography variant="h6">Calculation Breakdown</AppTypography>

          <Message message="Kindly be advised that the adjustment may be subject to change at a later time." />
          <Message
            message={
              <>
                In case you have any inquiries about the calculation, kindly
                fill in the provided Google form by clicking&nbsp;
                <ExternalLink href="https://forms.gle/jrtXtU4JSTdiu21p9">
                  here
                </ExternalLink>
                .
              </>
            }
          />

          <PreAttackTable data={userData.preAttack} />
          <AfterAttackTable
            data={userData.afterAttack}
            isProcessing={isAdjustmentProcessing}
          />
        </Box>
      )}
    </Box>
  )
}

/**
 * Children
 */
function Message({ message }: { message: React.ReactNode }) {
  return (
    <AppTypography
      variant="body1"
      bgcolor="rgb(36, 88, 246)"
      p="12px"
      borderRadius="8px"
      centerContent
      display="inline"
    >
      <InfoOutlinedIcon style={{ marginRight: '8px' }} />
      {message}
    </AppTypography>
  )
}
