import { strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import { ReactElement, useMemo } from 'react'
import WaitForComfirmationModal from '../../components/Modal/WaitForComfirmationModal/WaitForConfirmationModal'
import DisabledAppTokenInput from '../../components/TokenInput/DisabledAppTokenInput'
import { TokenSymbol } from '../../config/contracts/Token/TokenSymbol'
import { useModal } from '../../contexts/ModalContext'
import { AirdropModalData } from '../../interfaces/Modal'

interface Props {
  isOpen: boolean
}

type IAccumulatedAirdropData = {
  [key in TokenSymbol]: AirdropModalData
}

function AirdropConfirmationModal({ isOpen }: Props): ReactElement {
  const {
    modalDispatch,
    actions: { closeModal },
    modalState,
  } = useModal()

  const handleCloseModal = () => {
    modalDispatch(closeModal())
  }

  const { airdropData } = modalState

  const accumulatedAirdropData = useMemo(() => {
    return (
      airdropData &&
      airdropData
        // filter all airdrop that is claimed
        .filter((airdrop) => {
          return !airdrop.isClaimed
        })
        // sum the amount with same TokenSymbol
        .reduce((acc, cur) => {
          if (cur.tokenSymbol && cur.value) {
            if (!acc[cur.tokenSymbol]) {
              acc[cur.tokenSymbol] = {
                tokenSymbol: cur.tokenSymbol,
                value: cur.value,
                iconPath: cur.iconPath,
                isClaimed: cur.isClaimed,
              }
            } else {
              acc[cur.tokenSymbol].value = utils.formatEther(
                strToWad(cur.value).add(strToWad(acc[cur.tokenSymbol].value)),
              )
            }
          }

          return acc
        }, {} as IAccumulatedAirdropData)
    )
  }, [airdropData])

  return (
    <WaitForComfirmationModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      width="600px"
      subtitle="Claim"
    >
      {accumulatedAirdropData && (
        <DisabledAppTokenInput
          tokenItems={Object.values(accumulatedAirdropData)}
        />
      )}
    </WaitForComfirmationModal>
  )
}

export default AirdropConfirmationModal
