import cloneDeep from 'lodash.clonedeep'
import { useEffect, useMemo } from 'react'
import {
  AIRDROP_CAMPAIGNS,
  MERKLE_INFO_DB_VERSION,
  MERKLE_INFO_URI,
} from '../constants'
import { useWeb3 } from '../contexts/Web3Context'
import {
  AirdropType,
  IMerkleInfos,
  IUsersMerkleInfos,
} from '../interfaces/Airdrop'
// import custom hooks for fetching data and local storage
import useFetch from './useFetch'
import useLocalStorage from './useLocalStorage'

// custom hook to get merkle infos of an account
function useMerkleInfos() {
  // get account address from web3 context
  const { account } = useWeb3()
  const lowerCaseAccount = useMemo(
    () => (account ? account.toLowerCase() : null),
    [account],
  )
  const options = useMemo(() => {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        [
          ...AIRDROP_CAMPAIGNS[AirdropType.OFFICIAL],
          ...AIRDROP_CAMPAIGNS[AirdropType.THIRD_PARTY],
        ].map((campaign) => `${campaign.address}_${account}`.toLowerCase()),
      ),
    }
  }, [account])
  // useLocalStorage hook to store users merkle infos in local storage
  const [storedMerkleInfoDBVersion, setStoredMerkleInfoDBVersion] =
    useLocalStorage<string>({
      key: 'MERKLE_INFO_DB_VERSION',
      initialValue: '',
    })
  // useLocalStorage hook to store users merkle infos in local storage
  const [storedUsersMerkleInfos, setStoredUsersMerkleInfos] =
    useLocalStorage<IUsersMerkleInfos>({
      key: 'USERS_MERKLE_INFOS',
      initialValue: {},
    })
  const requiredFetchDB = useMemo(() => {
    if (!lowerCaseAccount) {
      return false
    }
    // no data is found in local storage
    if (storedUsersMerkleInfos[lowerCaseAccount] === undefined) {
      return true
    }
    const isDbVersionOutdated =
      storedMerkleInfoDBVersion === '' ||
      storedMerkleInfoDBVersion !== MERKLE_INFO_DB_VERSION

    // outdated data
    if (isDbVersionOutdated) {
      setStoredUsersMerkleInfos({})
      return true
    }

    return false
  }, [
    lowerCaseAccount,
    setStoredUsersMerkleInfos,
    storedMerkleInfoDBVersion,
    storedUsersMerkleInfos,
  ])
  // useFetch hook to get merkle infos of this account
  const { data } = useFetch<{
    result: IMerkleInfos
    userAddress: string
  }>(requiredFetchDB ? MERKLE_INFO_URI : undefined, options)
  // useEffect to update users merkle infos in local storage
  useEffect(() => {
    if (!account || !data) return
    const {
      result: fetchedMerkleInfosOfThisAccount,
      userAddress: fetchedUserAddress,
    } = data

    const newUserMerkleInfos: IUsersMerkleInfos = {
      ...cloneDeep(storedUsersMerkleInfos),
      [fetchedUserAddress]: cloneDeep(fetchedMerkleInfosOfThisAccount),
    }
    if (
      JSON.stringify(newUserMerkleInfos) ===
      JSON.stringify(storedMerkleInfoDBVersion)
    ) {
      return
    }
    setStoredUsersMerkleInfos(newUserMerkleInfos)
    setStoredMerkleInfoDBVersion(MERKLE_INFO_DB_VERSION)
  }, [
    account,
    setStoredMerkleInfoDBVersion,
    storedMerkleInfoDBVersion,
    storedUsersMerkleInfos,
    setStoredUsersMerkleInfos,
    data,
  ])
  // useMemo to memoize merkle infos of this account
  const merkleInfosOfThisAccount = useMemo(() => {
    if (lowerCaseAccount) {
      return storedUsersMerkleInfos[lowerCaseAccount]
    }
    return undefined
  }, [lowerCaseAccount, storedUsersMerkleInfos])
  // return merkle infos of this account
  return merkleInfosOfThisAccount
}

// export useMerkleInfos hook
export default useMerkleInfos
