import { ReactElement } from 'react'
import { ChainId, NETWORKS, SUPPORTED_CHAINS } from '../../../config/networks'
import { useWeb3 } from '../../../contexts/Web3Context'
import AppButton from '../../buttons/AppButton/AppButton'
import { Alert } from '../../Snackbar/Snackbar'
import {
  ButtonsContainer,
  Container,
  StyledTypography,
} from './UnsupportedNetwork.elements'
function UnsupportedNetworkPage(): ReactElement {
  const { switchNetwork } = useWeb3()
  const handleSwitchNetwork = (chainId: ChainId) => {
    switchNetwork(chainId)
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt="46px"
      data-testid="unsupported-network"
    >
      <Alert message="Unsupported Network" severity="warning" enableBgColor />
      <StyledTypography variant="caption">
        Please choose a network below
      </StyledTypography>
      <ButtonsContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {SUPPORTED_CHAINS.map((chainId) => (
          <AppButton
            key={chainId}
            fullWidth
            onClick={() => handleSwitchNetwork(chainId)}
            customVariant="secondary"
            data-testid="switch-network-button"
          >
            Switch to {NETWORKS[chainId].name}
          </AppButton>
        ))}
      </ButtonsContainer>
    </Container>
  )
}

export default UnsupportedNetworkPage
