import * as Sentry from '@sentry/react'
import { constants, ethers } from 'ethers'
import { merkleDistributorV1ABI } from '../config/contracts/abis'
import { TokenSymbol } from '../config/contracts/Token/TokenSymbol'
import { ChainId } from '../config/networks'
import { useAirdropData } from '../contexts/AirdropContext'
import { useModal } from '../contexts/ModalContext'
import { useMulticallData } from '../contexts/MulticallDataContext'
import { useSnackbar } from '../contexts/SnackbarContext'
import { useWeb3 } from '../contexts/Web3Context'
import { AirdropType } from '../interfaces/Airdrop'
import { ModalId } from '../interfaces/Modal'
import { isUserDeniedTransaction } from '../utils/contract'
import { useMerkleDistributorContract } from './useContract'

function useClaim(
  tokenSymbol: TokenSymbol,
  contractAddress: `0x${string}`,
  airdropChainId: ChainId,
  airdropType: AirdropType,
) {
  const writableAirdropContract = useMerkleDistributorContract(
    false,
    airdropChainId,
    contractAddress,
    AirdropType.OFFICIAL,
  )
  const {
    modalDispatch,
    actions: { openModal, closeModal },
  } = useModal()
  const { showMessage } = useSnackbar()
  const { account } = useWeb3()
  const { newAirdropData: data, handleSingleClaimAirdropSuccess } =
    useAirdropData()
  const { index, airdropAmountHexStr, proof } = data[airdropType][
    contractAddress
  ] || {
    index: undefined,
    airdropAmountHexStr: undefined,
    proof: undefined,
  }
  const { refetchMultiCallData } = useMulticallData()

  const merkleInfoClaimIndex = index
  const merkleInfoClaimAmount = airdropAmountHexStr
  const merkleInfoClaimProof = proof
  const isMerkleInfoClaimMissing =
    merkleInfoClaimIndex === undefined ||
    !merkleInfoClaimAmount ||
    !merkleInfoClaimProof
  const airdropAmountStr = data[airdropType][contractAddress]?.airdropAmount
  const handleClaimClick = async () => {
    modalDispatch(
      openModal(ModalId.AIRDROP_CONFIRMATION, {
        airdropData: [{ value: airdropAmountStr, tokenSymbol: tokenSymbol }],
      }),
    )
    if (isMerkleInfoClaimMissing) {
      modalDispatch(closeModal())
      return
    }

    const claimFuncArg = [
      merkleInfoClaimIndex,
      account || constants.AddressZero,
      merkleInfoClaimAmount,
      merkleInfoClaimProof,
    ] as const

    try {
      const transaction = await writableAirdropContract?.claim(...claimFuncArg)
      if (transaction) {
        await transaction.wait()
        modalDispatch(
          openModal(ModalId.TRANSACTION_SUBMITTED, {
            transactionHashes: [transaction.hash],
          }),
        )
        handleSingleClaimAirdropSuccess(airdropType, contractAddress)
        await refetchMultiCallData()
        showMessage('Successfully claimed')
      }
    } catch (err) {
      if (!isUserDeniedTransaction(err)) {
        const merkleDistributorInterface = new ethers.utils.Interface(
          merkleDistributorV1ABI,
        )

        const encodedFunctionData =
          merkleDistributorInterface.encodeFunctionData('claim', claimFuncArg)

        Sentry.setContext('contract_call', {
          name: 'claim',
          address: account,
          inputHexData: encodedFunctionData,
          contractAddress,
        })
        Sentry.captureException(err)
      }

      modalDispatch(closeModal())
      showMessage('Transaction failed', 'warning')
    }
  }
  return {
    handleClaimClick,
  }
}

export default useClaim
