import { Box } from '@mui/material'
import styled, { css } from 'styled-components'
import { AirdropType } from '../../interfaces/Airdrop'

interface AirdropPageContentContainerProps {
  $AirdropType: AirdropType
}

export const AirdropPageContentContainer = styled(
  Box,
)<AirdropPageContentContainerProps>`
  ${({ theme, $AirdropType }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;

    ${$AirdropType === AirdropType.THIRD_PARTY &&
    css`
      max-width: 1400px;
      width: 100%;
      padding: 20px;
    `}

    ${$AirdropType === AirdropType.OFFICIAL &&
    css`
      width: 50%;
      @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
        width: 100%;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      flex-direction: column;
    }
  `}
`
