import { Typography, TypographyProps } from '@mui/material'
import styled, { css } from 'styled-components'
import { AppTypographyProps } from './AppTypography'
interface StyledTypographyProps
  extends Omit<AppTypographyProps, keyof TypographyProps> {
  flexWrap?: 'wrap' | 'nowrap' | 'unset'
  enableTextGlow?: boolean
}
export const StyledTypography = styled(Typography).withConfig({
  shouldForwardProp: (prop) =>
    !['centerContent', 'transparent', 'enableTextGlow'].includes(prop),
})<StyledTypographyProps>`
  ${({
    transparent,
    flexWrap = 'wrap',
    centerContent,
    enableTextGlow,
    theme,
  }) => css`
    ${transparent &&
    css`
      opacity: 0.5;
    `}
    ${centerContent &&
    css`
      display: inline-flex;
      align-items: center;
      flex-wrap: ${flexWrap};
    `}
    
    ${enableTextGlow &&
    css`
      font-weight: 600;
      color: ${theme.palette.common.white};
      text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5), 0px 30px 80px #2458f6,
        0px 100px 180px #00f642;
    `}
  `}
`
