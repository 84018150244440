import { TokenSymbol } from '../config/contracts/Token/TokenSymbol'

export type DateFormat = Date | string | number

export function ordinal(n: number | string): string {
  return `${+n}${
    ['st', 'nd', 'rd'][((((+n + 90) % 100) - 10) % 10) - 1] || 'th'
  }`
}

/**
 * @dev alternatively use day.js library, if necessary
 * @param {Date | string | number} date the locale date. Browsers may
 * handle string date formats differently, best advise not to use them.
 * @returns {string} formated string output
 */
export function localeDateFormat(date: DateFormat): string {
  const getTime = date instanceof Date ? date : new Date(date)
  const formatTime = `${getTime.toLocaleDateString('en-US', {
    day: '2-digit',
    year: 'numeric',
    month: 'long',
  })} ${getTime.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })}`

  // @dev split formatTime to get day, and remove comma
  const getDay = ordinal(formatTime.split(' ')[1].split(',')[0])
  const tempArr: string[] = formatTime.split(' ')
  tempArr[1] = getDay
  tempArr[2] += ','
  return tempArr.join(' ')
}

export function paginator(
  items: Array<{} | string | number>,
  currentPage: number,
  itemsPerPage: number,
) {
  let page = currentPage || 1,
    perPage = itemsPerPage || 2,
    offset = (page - 1) * perPage,
    paginatedItems = items.slice(offset).slice(0, itemsPerPage),
    totalpage = Math.ceil(items.length / perPage)

  return {
    page: page,
    perPage: perPage,
    prePage: page - 1 ? page - 1 : null,
    nextPage: totalpage > page ? page + 1 : null,
    total: items.length,
    totalPage: totalpage,
    data: paginatedItems,
  }
}

export const isNotStableCoin = (tokenSymbol: TokenSymbol) =>
  ['BTC.b', 'sAVAX', 'yyAVAX'].includes(tokenSymbol)
