import { alpha, Box } from '@mui/material'
import styled, { css } from 'styled-components'

export const WrapperContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${theme.palette.primary['A200']};
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;

    .swiper-wrapper {
      display: flex;
    }

    .mySwiper {
      width: 100%;
      height: 100%;
      background: ${alpha(theme.palette.primary[900], 0.7)};
      padding-bottom: 20px;
    }

    .swiper-button-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}
`

export const CardWrapper = styled(Box)`
  width: inherit;
`

export const CardContainer = styled(Box)`
  ${({ theme }) => css`
    margin: 0 10px;
    padding: 10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;

    height: 180px;
    background: ${alpha(theme.palette.primary[500], 0.2)};
  `}
`

export const DividerContainer = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
`

interface ArrowButtonProps {
  $direction: 'right' | 'left'
}

export const ArrowButton = styled(Box)<ArrowButtonProps>`
  ${({ theme, $direction }) => css`
    position: absolute;
    border-radius: 999px;
    border: 1px solid ${theme.palette.primary['A200']};
    background: ${theme.palette.primary['A300']};
    top: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 999;

    ${$direction === 'right' &&
    css`
      right: 10px;
    `}

    ${$direction === 'left' &&
    css`
      left: 10px;
    `}
  `}
`

export const NotEligibleBox = styled(Box)`
  ${({ theme }) => css`
    padding: 8px;
    margin: 6px 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${alpha(theme.palette.secondary.main, 0.2)};
    border-radius: 10px;
    text-align: center;
  `}
`
