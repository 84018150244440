import { Box } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import AppPagination from '../../components/AppPagination/AppPagination'
import AppTypography from '../../components/AppTypography/AppTypography'
import { TokenSymbol } from '../../config/contracts/Token/TokenSymbol'
import { ChainId } from '../../config/networks'
import { THIRD_PARTY_AIRDROPS } from '../../config/thirdPartyAirdrops'
import {
  OFFICIAL_AIRDROP_CAMPAIGNS,
  OFFICIAL_AIRDROP_PER_PAGE_SIZE,
} from '../../constants'
import { AirdropCampaign, AirdropType } from '../../interfaces/Airdrop'
import { paginator } from '../../utils/common'
import AirdropOfficialCard from './AirdropOfficialCard/AirdropOfficialCard'
import { AirdropPageContentContainer } from './AirdropPage.element'
import { AirdropTabBar } from './AirdropTabBar'
import AirdropThirdPartyCardWrapper from './AirdropThirdPartyCard/AirdropThirdPartyCardWrapper'

function AirdropPage(): React.ReactElement {
  useEffect(() => {
    ReactGA.pageview('/airdrop')
  }, [])

  const airdropCampaigns = OFFICIAL_AIRDROP_CAMPAIGNS.filter(
    (campaign) => !campaign.isUspCompensation,
  )

  const [currentTabId, setCurrentTabId] = useState<AirdropType>(
    AirdropType.OFFICIAL,
  )

  const handleTabChange = (tabId: AirdropType) => {
    setCurrentTabId(tabId)
  }

  const [officalAirdropCurrentPage, setOfficalAirdropCurrentPage] =
    useState<number>(1)

  const handleOfficalAirdropPageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setOfficalAirdropCurrentPage(paginator(airdropCampaigns, page, 1).page)
  }

  const paginatedOfficialCards = useMemo(() => {
    return paginator(
      airdropCampaigns,
      officalAirdropCurrentPage,
      OFFICIAL_AIRDROP_PER_PAGE_SIZE,
    ).data as AirdropCampaign[]
  }, [airdropCampaigns, officalAirdropCurrentPage])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      flexDirection="column"
      paddingTop="40px"
    >
      <AppTypography variant="h3" enableTextGlow>
        Airdrops
      </AppTypography>
      <AirdropTabBar
        currentTabId={currentTabId}
        onTabChange={handleTabChange}
      />
      <AirdropPageContentContainer $AirdropType={currentTabId}>
        {currentTabId === AirdropType.THIRD_PARTY &&
          Object.values(THIRD_PARTY_AIRDROPS)
            .filter((campaign) => {
              const thirdPartyAirdropsId = campaign.id
              return process.env.REACT_APP_ENVIRONMENT === 'production'
                ? thirdPartyAirdropsId.includes('mainnet')
                : true
            })
            .map((campaign) => {
              return (
                <AirdropThirdPartyCardWrapper
                  key={campaign.id}
                  name={campaign.name}
                  description={campaign.description}
                  chainId={campaign.chainId}
                  thirdPartyAirdropsId={campaign.id}
                  tokenSymbol={campaign.tokenSymbol}
                />
              )
            })}
        {currentTabId === AirdropType.OFFICIAL && (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {paginatedOfficialCards.map((campaign) => (
                <AirdropOfficialCard
                  title={campaign.name as string}
                  description={campaign.description as string}
                  startDate={campaign.startDate}
                  balances={campaign.balances}
                  contractAddress={campaign.address}
                  tokenSymbol={campaign.tokenSymbol as TokenSymbol}
                  key={campaign.address}
                  chainId={campaign.chainId as ChainId}
                />
              ))}
            </Box>
            {airdropCampaigns.length > 0 && (
              <AppPagination
                count={
                  paginator(
                    airdropCampaigns,
                    officalAirdropCurrentPage,
                    OFFICIAL_AIRDROP_PER_PAGE_SIZE,
                  ).totalPage
                }
                page={
                  paginator(
                    airdropCampaigns,
                    officalAirdropCurrentPage,
                    OFFICIAL_AIRDROP_PER_PAGE_SIZE,
                  ).page
                }
                onChange={handleOfficalAirdropPageChange}
              />
            )}
          </Box>
        )}
      </AirdropPageContentContainer>
    </Box>
  )
}

export default AirdropPage
