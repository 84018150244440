import { alpha, Box, IconButton, Modal, Theme, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

const styleVariables = {
  '--modal-body-padding': '24px',
  '--modal-body-padding-lg': '32px',
}

interface ModalBodyProps {
  theme: Theme
  maxWidth?: string
}
export const ModalBody = styled(Box)<ModalBodyProps>`
  ${({ theme, maxWidth }: ModalBodyProps) => css`
    ${styleVariables}
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    padding: var(--modal-body-padding) var(--modal-body-padding)
      var(--modal-body-padding-lg) var(--modal-body-padding);
    box-shadow: 0px 0px 20px ${alpha(theme.palette.primary['A200'], 0.2)};
    z-index: 9999;
    background: ${theme.palette.primary[900]};
    border: 1px solid ${theme.palette.primary['A200']};
    max-width: ${maxWidth};

    @media only screen and (max-width: ${theme.breakpoints.values.md - 1}px) {
      margin: 16px;
    }
    @media only screen and (max-width: ${theme.breakpoints.values.sm}px) {
      margin: 12px;
      padding: 16px 16px 24px 16px;
    }
  `}
`

export const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

// Modal Topbar
export const Header = styled(Typography)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

export const CloseButton = styled(IconButton)``
export const TopbarConatiner = styled(Box)`
  margin: 0 0 8px;
`
