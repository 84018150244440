import { Box, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

const maxWidth_lg = '500px'
const maxWidth_md = '300px'
const minWidth_md = '176px'
const maxWidth_xs = '300px'
const numOfButtons = 3

export const Label = styled(Typography)``

export const ServiceContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 48px;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 999px;
    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 40px;
    }
  `}
`

export const Animation = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  /* width: calc(100% / ${numOfButtons}); */
  background: linear-gradient(
    90.13deg,
    rgba(12, 88, 255, 0.5) 0.15%,
    rgba(51, 57, 104, 0.5) 99.93%
  );
  height: 100%;
  border-radius: inherit;
  transition: left 0.4s ease 0s;
  user-select: none;
`
interface ContainerProps {
  numOfTabs: number
}
export const Container = styled(Box)<ContainerProps>`
  ${({ theme, numOfTabs }) => css`
    border: 1px solid ${theme.palette.primary[500]};
    border-radius: 999px;
    position: relative;
    width: 100%;
    max-width: ${maxWidth_lg};
    a {
      flex: 1;

      &:nth-of-type(1):active ~ .animation {
        left: 0;
      }

      &:nth-of-type(2).active ~ .animation {
        left: calc(100% / ${numOfTabs});
      }

      &:nth-of-type(3).active ~ .animation {
        left: calc(100% / ${numOfTabs} * 2);
      }
    }
    .animation {
      width: calc(100% / ${numOfTabs});
    }
    @media only screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: ${maxWidth_md};
      min-width: ${minWidth_md};
    }
    @media only screen and (max-width: ${theme.breakpoints.values.xs}px) {
      max-width: ${maxWidth_xs};
    }
  `}
`

export const SettingContainer = styled(Box)`
  position: absolute;
  right: 0;
`
