import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { useLayoutEffect, useMemo, useState } from 'react'

import SwiperClass, { Navigation } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import { TokenSymbol } from '../../../config/contracts/Token/TokenSymbol'

import { ChainId } from '../../../config/networks'

import { ThirdPartyAirdropId } from '../../../config/thirdPartyAirdrops'

import { THIRD_PARTY_AIRDROP_CAMPAIGNS } from '../../../constants'

import { AirdropData, useAirdropData } from '../../../contexts/AirdropContext'

import { useWeb3 } from '../../../contexts/Web3Context'

import useMultiClaim from '../../../hooks/useMultiClaim'

import { AirdropType } from '../../../interfaces/Airdrop'

import ThirdPartyAirdropHeaderInfo from '../ThirdPartyAirdropHeaderInfo/ThirdPartyAirdropHeaderInfo'

import AirdropThirdPartyCard from './AirdropThirdPartyCard'

import { ArrowButton, WrapperContainer } from './AirdropThirdPartyCard.elements'

interface AirdropThirdPartyCardWrapperProps {
  name: string
  chainId: ChainId
  description: string
  thirdPartyAirdropsId: ThirdPartyAirdropId
  tokenSymbol: TokenSymbol
}

const AirdropThirdPartyCardWrapper = ({
  name,
  chainId: airdropChainId,
  description,
  thirdPartyAirdropsId,
  tokenSymbol,
}: AirdropThirdPartyCardWrapperProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const { newAirdropData: airdropData } = useAirdropData()
  const thirdPartyData = airdropData[AirdropType.THIRD_PARTY]
  const [isSlided, setIsSlided] = useState<boolean>(false)
  const { network } = useWeb3()

  const activeChainId =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? network?.id
      : ChainId.AVALANCHE

  const filteredThirdPartyAirdrop = useMemo(() => {
    return THIRD_PARTY_AIRDROP_CAMPAIGNS.filter((airdrop) => {
      return (
        airdrop.thirdPartyAirdropId === thirdPartyAirdropsId &&
        !airdrop.isUspCompensation
      )
    })
  }, [thirdPartyAirdropsId])

  const unclaimedThirdPartyAirdropData = useMemo(() => {
    return (
      filteredThirdPartyAirdrop
        .map(
          (campaign) =>
            airdropData[AirdropType.THIRD_PARTY][
              campaign.address
            ] as AirdropData,
        )
        // filter all airdrops that are already claimed
        .filter((data) => {
          const isClaimed = data && data.isClaimed
          return data && data.isClaimed !== undefined && !isClaimed
        })
    )
  }, [airdropData, filteredThirdPartyAirdrop])

  useLayoutEffect(() => {
    if (Object.values(thirdPartyData).length > 0 && !isSlided) {
      // directed to the first unclaimed airdrop
      const filteredThirdPartyAirdropData = filteredThirdPartyAirdrop.map(
        (item) => {
          return thirdPartyData[item?.address]
        },
      )
      const firstUnclaimedIndex = filteredThirdPartyAirdropData.findIndex(
        (data) => {
          return !data?.isClaimed && data?.isEligible
        },
      )
      swiper?.slideTo(firstUnclaimedIndex)
      setIsSlided(true)
    }
  }, [filteredThirdPartyAirdrop, isSlided, swiper, thirdPartyData])

  const { handleMultiClaim, disabled } = useMultiClaim({
    chainId: activeChainId || ChainId.AVALANCHE,
    airdropsData: unclaimedThirdPartyAirdropData,
    airdropType: AirdropType.THIRD_PARTY,
    thirdPartyAirdropId: thirdPartyAirdropsId,
  })

  return (
    <WrapperContainer>
      <ThirdPartyAirdropHeaderInfo
        name={name}
        description={description}
        airdropChainId={airdropChainId}
        handleMultiClaim={handleMultiClaim}
        isMultiClaimDisabled={disabled}
        unclaimedThirdPartyAirdropData={unclaimedThirdPartyAirdropData}
        tokenSymbol={tokenSymbol}
      />
      <Swiper
        style={{ padding: '0 10px 20px 10px' }}
        className="mySwiper"
        onSwiper={setSwiper}
        modules={[Navigation]}
        navigation={{
          prevEl: '.swiper-left-btn',
          nextEl: '.swiper-right-btn',
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          300: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        <ArrowButton $direction="left" className="swiper-left-btn">
          <KeyboardArrowLeftIcon />
        </ArrowButton>
        <ArrowButton $direction="right" className="swiper-right-btn">
          <KeyboardArrowRightIcon />
        </ArrowButton>
        {filteredThirdPartyAirdrop.map((airdrop, index) => {
          return (
            <SwiperSlide key={index}>
              <AirdropThirdPartyCard
                index={index}
                startDate={airdrop.startDate}
                snapshotDate={airdrop.snapshotDate as Date}
                tokenSymbol={tokenSymbol}
                balances={airdrop.balances}
                contractAddress={airdrop.address}
                chainId={airdropChainId}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </WrapperContainer>
  )
}

export default AirdropThirdPartyCardWrapper
