import { Typography } from '@mui/material'
import { ReactElement } from 'react'
import PlatypusIcon from '../../assets/icons/platypus-white.svg'
import { Container, StyledLink } from './AboutPlatypusButtonContainer.elements'

function AboutPlatypusButtonContainer(): ReactElement {
  return (
    <StyledLink
      href="https://platypus.finance/"
      target="_blank"
      rel="noreferrer"
    >
      <Container display="flex" flexDirection="row" alignItems="center">
        <img
          src={PlatypusIcon}
          width={23}
          style={{ marginRight: '10px' }}
          alt="platypus_icon"
        />

        <Typography
          variant="button"
          style={{ textTransform: 'unset', fontWeight: 'bold' }}
        >
          About Platypus
        </Typography>
      </Container>
    </StyledLink>
  )
}

export default AboutPlatypusButtonContainer
