import React, { createContext, ReactElement, useContext, useState } from 'react'
import ErrorModalContainer from '../containers/ErrorModalContainer/ErrorModalContainer'

interface ErrorMessageType {
  title?: string
  message: string
}
interface ContextType {
  openErrorModal: ({ title, message }: ErrorMessageType) => void
}

const ErrorModalContext = createContext<ContextType>({} as ContextType)

export const useErrorModal = (): ContextType => {
  return useContext(ErrorModalContext)
}

const initialError: ErrorMessageType = { title: '', message: '' }

interface Props {
  children: React.ReactNode
}

function ErrorModalProvider({ children }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(initialError)
  const openErrorModal = ({ title, message }: ErrorMessageType) => {
    setError({
      title,
      message,
    })
    setIsOpen(true)
  }
  const handleCloseErrorModal = () => {
    setIsOpen(false)
    setError(initialError)
  }
  return (
    <ErrorModalContext.Provider value={{ openErrorModal }}>
      {children}
      {isOpen && (
        <ErrorModalContainer
          isOpen
          onCloseErrorModal={handleCloseErrorModal}
          title={error.title}
          message={error.message}
        />
      )}
    </ErrorModalContext.Provider>
  )
}

export default ErrorModalProvider
