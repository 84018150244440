import { ThirdPartyAirdropId } from '.'
import { TokenSymbol } from '../contracts/Token/TokenSymbol'
import { ChainId } from '../networks'

export class ThirdPartyAirdrops {
  readonly id: ThirdPartyAirdropId
  readonly name: string
  readonly description: string
  readonly chainId: ChainId
  readonly tokenSymbol: TokenSymbol

  constructor(
    id: ThirdPartyAirdropId,
    name: string,
    description: string,
    chainId: ChainId,
    tokenSymbol: TokenSymbol,
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.chainId = chainId
    this.tokenSymbol = tokenSymbol
  }
}
