import React, {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useContractReads } from 'wagmi'
import useMerkleInfos from '../../hooks/useMerkleInfos'
import { executeCallBacks } from '../../interfaces/Multicall'
import {
  fetchAirdropData,
  IAirdropData,
} from './helpers/fetchAirdropDataHelper'

export interface ContextType {
  airdropData?: IAirdropData
  refetchMultiCallData: () => Promise<any>
}
interface Props {
  children: React.ReactNode
}

export const MulticallDataContext = createContext<ContextType>({
  airdropData: undefined,
  refetchMultiCallData: () => Promise.resolve(undefined),
})
MulticallDataContext.displayName = 'MulticallDataContext'

export const useMulticallData = (): ContextType => {
  return useContext(MulticallDataContext)
}

export const MulticallDataProvider = ({ children }: Props): ReactElement => {
  const [airdropData, setAirdropData] = useState<IAirdropData | undefined>(
    undefined,
  )
  const merkleInfos = useMerkleInfos()
  const { contractCalls, callbacks, states } = useMemo(() => {
    /**
     * C. Pushing call and callback
     */
    const {
      contractCalls: airdropDataContractCalls,
      callbacks: airdropDataCallbacks,
      states: airdropDataStates,
    } = fetchAirdropData(merkleInfos || {})
    return {
      contractCalls: [...airdropDataContractCalls],
      callbacks: [...airdropDataCallbacks],
      states: {
        airdropData: airdropDataStates,
      },
    }
  }, [merkleInfos])
  const { refetch: refetchMultiCallData } = useContractReads({
    contracts: contractCalls,
    onSettled(data, error) {
      if (data) executeCallBacks(data, callbacks)
      if (error) console.warn(error)
      setAirdropData(states.airdropData)
    },
  })

  return (
    <MulticallDataContext.Provider
      value={{
        airdropData,
        refetchMultiCallData,
      }}
    >
      {children}
    </MulticallDataContext.Provider>
  )
}
