import {
  Box,
  Slide,
  SlideProps,
  SnackbarCloseReason,
  Typography,
} from '@mui/material'
import React, { ReactElement } from 'react'
import WarningCircleIcon from '../../assets/icons/warning-circle-icon.svg'
import { SeverityType, useSnackbar } from '../../contexts/SnackbarContext'
import CheckCircleIcon from '../icons/CheckCircleIcon'
import { StyledSnackbar } from './Snackbar.elements'

interface Props {
  autoHideDuration?: number
}
function Snackbar({ autoHideDuration = 3000 }: Props): ReactElement {
  const { open, setOpen, clearMessageInfo, messageInfo } = useSnackbar()
  const handleClose = (
    _: Event | React.SyntheticEvent<HTMLInputElement, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <>
      <StyledSnackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        TransitionProps={{ onExited: clearMessageInfo }}
        message={
          messageInfo ? (
            <Alert
              message={messageInfo.message}
              severity={messageInfo.severity}
            />
          ) : undefined
        }
        TransitionComponent={SlideTransition}
        severity={messageInfo ? messageInfo.severity : 'success'}
      ></StyledSnackbar>
    </>
  )
}

export default Snackbar

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="left" />
}

interface AlertProps {
  message: string
  severity?: SeverityType
  enableBgColor?: boolean
}
const AlertIconWidth = 20
const iconMargin = '0 10px 0 0'
export const Alert = ({
  message,
  severity = 'success',
  enableBgColor,
}: AlertProps): ReactElement => {
  const isSuccess = severity === 'success'
  const isWarning = severity === 'warning'
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      bgcolor={
        enableBgColor
          ? (isWarning && '#F44336') || (isSuccess && '#5EB954') || ''
          : ''
      }
      p={enableBgColor ? '10px 20px' : ''}
      style={{ borderRadius: '4px' }}
    >
      {isSuccess && (
        <CheckCircleIcon width={AlertIconWidth} margin={iconMargin} />
      )}
      {isWarning && (
        <img
          src={WarningCircleIcon}
          width={AlertIconWidth}
          style={{ margin: iconMargin }}
          alt="warning_circle"
        />
      )}
      <Typography variant="subtitle2">{message}</Typography>
    </Box>
  )
}
